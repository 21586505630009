import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import { TagSelect } from './TagSelect';

/**
 * props.selection []
 * props.onChange(preferenceOption data)
 * props.profileFieldId
 */
export const AddTag = props => {
  const [visible, setVisible] = useState(false);
  let data = [...props.selection];

  function onAdd() {
    props.onChange(data);
    setVisible(false);
  }

  function onChange(d) {
    data = d;
  }

  return (
    <>
      <CButton color="primary" size="sm" onClick={() => setVisible(!visible)}>
        Change tags
      </CButton>
      <CModal show={visible} onClose={() => setVisible(false)} aria-labelledby="AddRoomType">
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">Change tags</CModalTitle>
        </CModalHeader>

        <CModalBody style={{ maxHeight: 600, overflow: 'scroll' }}>
          <TagSelect
            selection={props.selection}
            onChange={onChange}
            profileFieldId={props.profileFieldId}
          />
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={onAdd}>
            Save
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
