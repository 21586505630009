import React from 'react';
import { Link } from 'react-router-dom';
import { CDataTable, CButton } from '@coreui/react';
import { BanButton, UnbanButton } from '../../styles/components/DataTable.module.css';
import PropTypes from 'prop-types';

const FlagsTable = props => {
  const fields = [
    {
      key: 'userId',
      label: 'ID Number',
      _style: { width: '8%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'name',
      _style: { width: '15%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'reportReason',
      label: 'Flag Code',
      _style: { width: '7%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'reportDatetime',
      label: 'Flag Date/Time',
      _style: { width: '8%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'reporterName',
      label: 'Flagged By User',
      _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'repeatedFlags',
      label: 'Previous Flags',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'uniqueFlags',
      label: 'Unique Flags',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'about',
      label: 'About Me',
      _style: { width: '20%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'status',
      label: 'User Status',
      _style: { width: '5%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'actions',
      _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
  ];

  return (
    <CDataTable
      size="sm"
      items={props.flaggedUsers}
      fields={fields}
      hover
      sorter
      scopedSlots={{
        userId: user => (
          <td>
            <div style={{ wordBreak: 'break-all' }}>
              <p>{user.userId}</p>
            </div>
          </td>
        ),
        name: user => (
          <td>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between">
                <div className="c-avatar mr-1" style={{ backgroundColor: '#c7cbd1' }}>
                  <img
                    src={user.profilePhoto}
                    className="c-avatar-img"
                    alt="Not found"
                    style={{
                      maxHeight: '100%',
                      objectFit: 'cover',
                      minHeight: '36px',
                      minWidth: '36px',
                    }}
                  />
                </div>
                <p className="mb-0 align-self-center">
                  <Link
                    to={{
                      pathname: `/main/profile/${user.userId}`,
                    }}
                    target="_blank"
                    className="link"
                    style={{ color: '#4A90E2' }}
                  >
                    {user.name}
                  </Link>
                </p>
              </div>
            </div>
          </td>
        ),
        reportDatetime: user => <td>{user.getReportDatetime}</td>,
        reporterName: user => (
          <td>
            <Link
              to={{
                pathname: `/main/profile/${user.reporterId}`,
              }}
              target="_blank"
              className="link"
              style={{ color: '#4A90E2' }}
            >
              {user.reporterName}
            </Link>
          </td>
        ),
        about: user => (
          <td>
            <div className="d-flex justify-content-between">
              <div style={{ wordBreak: 'break-word' }}>
                <p>{user.about}</p>
              </div>
            </div>
          </td>
        ),
        actions: user => {
          return (
            <td className="py-2">
              <div className="d-flex">
                <CButton
                  className={props.bannedUsers.includes(user.userId) ? UnbanButton : BanButton}
                  shape="square"
                  size="sm"
                  onClick={() => {
                    props.openBanUnbanModal(user.userId);
                  }}
                >
                  {props.bannedUsers.includes(user.userId) ? 'Unban' : 'Ban'}
                </CButton>
              </div>
            </td>
          );
        },
      }}
    />
  );
};

FlagsTable.propTypes = {
  flaggedUsers: PropTypes.array.isRequired,
  openBanUnbanModal: PropTypes.func.isRequired,
};

export default FlagsTable;
