import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CRow,
  CCol,
  CCard,
  CCardFooter,
  CSpinner,
  CButton,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CCardHeader,
} from '@coreui/react';
import {
  Tabs,
  TabItem,
  TabItemActive,
  SectionTitle,
} from '../../../styles/views/TextQueueView.module.css';
import TextTable from '../../../components/tables/TextTable';
import * as textActions from '../../../store/actions/textActions';
import * as errorModalActions from '../../../store/actions/errorModalActions';
import TextPopUp from '../../../components/TextPopUp';
import BanUnbanPopUp from '../../../components/BanUnbanPopUp';
import { IMAGE_REJECTION_REASONS } from '../../../utils/statusEnum';

const TextQueueView = props => {
  const dispatch = useDispatch();
  const pendingText = useSelector(state => state.text.pendingText);
  const [isFetching, SetIsFetching] = useState(true);
  const [active, setActive] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [text, setText] = useState({});
  const [reasons, setReasons] = useState([]);

  const [banUnbanParameters, setBanUnbanParameters] = useState(initialBanUnbanState);
  const [isBanUnbanModalOpen, setIsBanUnbanModalOpen] = useState(false);
  const [bannedUsers, setBannedUsers] = useState([]);

  const fetchPendingTextHandler = async () => {
    try {
      await dispatch(textActions.getPendingText());
    } catch (err) {
      dispatch(errorModalActions.showModal(err.message));
    }
    SetIsFetching(false);
  };

  useEffect(() => {
    fetchPendingTextHandler();
    setReasons(IMAGE_REJECTION_REASONS);
    // eslint-disable-next-line
  }, []);

  const toggleBan = async () => {
    try {
      const userId = banUnbanParameters.userId;
      const position = bannedUsers.indexOf(userId);
      let newBannedUsers = bannedUsers.slice();
      if (position !== -1) {
        newBannedUsers.splice(position, 1);
        await dispatch(textActions.unbanUser(userId));
      } else {
        newBannedUsers = [...bannedUsers, userId];
        await dispatch(textActions.banUser(userId));
      }
      setBannedUsers(newBannedUsers);
      setBanUnbanParameters(initialBanUnbanState);
      setIsBanUnbanModalOpen(!isBanUnbanModalOpen);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const closeBanUnbanModal = () => {
    setIsBanUnbanModalOpen(false);
  };

  const openBanUnbanModal = userId => {
    setBanUnbanParameters({
      userId: userId,
      isBanned: bannedUsers.includes(userId),
    });
    setIsBanUnbanModalOpen(true);
  };

  const submitHandler = async () => {
    // if (moreUsersLeft) {
    //   try {
    //     await dispatch(textActions.getPendingText());
    //   } catch (err) {
    //     dispatch(errorModalActions.showModal(err.message));
    //   }
    // }
  };

  const openTextPopUpModal = text => {
    setText(text);
    setSelectedReasons([]);
    setIsModalOpen(true);
  };

  const closeTextModal = () => {
    setSelectedReasons([]);
    reasons.forEach(reason => {
      reason.isChecked = false;
    });
    setIsModalOpen(false);
  };

  const onReasonSelected = reasonId => {
    const newReasons = [...reasons];
    const newSelectedReasons = [...selectedReasons];
    if (newSelectedReasons.includes(reasonId)) {
      newSelectedReasons.splice(
        newSelectedReasons.findIndex(r => r.id === reasonId),
        1
      );
      newReasons[reasons.findIndex(r => r.id === reasonId)].isChecked = false;
    } else {
      newSelectedReasons.push(reasonId);
      newReasons[reasons.findIndex(r => r.id === reasonId)].isChecked = true;
    }
    setSelectedReasons(newSelectedReasons);
    setReasons(newReasons);
  };

  const onRejectText = async () => {
    text.rejected = true;
    await dispatch(textActions.rejectText(text, selectedReasons));
    setIsModalOpen(false);
    reasons.forEach(reason => {
      reason.isChecked = false;
    });
    setSelectedReasons([]);
  };

  const onUnrejectText = async text => {
    text.rejected = false;
    await dispatch(textActions.unrejectText(text));
    setSelectedReasons([]);
    setIsModalOpen(false);
  };

  return (
    <>
      <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
        <CNav className={Tabs} variant="tabs">
          <CNavItem>
            <CNavLink className={active === 0 ? TabItemActive : TabItem}>
              <div className="font-weight-bold">Pending Text Queue</div>
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink className={active === 1 ? TabItemActive : TabItem}>
              <div className="font-weight-bold">Rejected Text Queue</div>
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane className="mt-4">
            {isFetching ? (
              <div className="d-flex flex-row justify-content-center w-100">
                <CSpinner color="info" />
              </div>
            ) : (
              <>
                <CCol md="12">
                  <CCard className="mb-0 border-bottom-0">
                    <CCardHeader className="ml-3 border-bottom-0">
                      <CRow className="mb-2">
                        <div className={SectionTitle}>Text Queue</div>
                      </CRow>
                    </CCardHeader>
                  </CCard>
                </CCol>
                <CCol md="12">
                  <CCard className="border-top-0 rounded-0 pl-3">
                    <TextTable
                      {...props}
                      pendingText={pendingText}
                      openTextPopUpModal={openTextPopUpModal}
                      onUnrejectText={onUnrejectText}
                      openBanUnbanModal={openBanUnbanModal}
                      bannedUsers={bannedUsers}
                    />
                    <CCardFooter>
                      <div className="d-flex flex-row justify-content-end">
                        <CButton
                          className="px-5"
                          color="info"
                          size="lg"
                          onClick={() => submitHandler()}
                        >
                          Submit
                        </CButton>
                      </div>
                    </CCardFooter>
                  </CCard>
                </CCol>
              </>
            )}
          </CTabPane>
          <CTabPane>{`Rejected Text Queue View`}</CTabPane>
        </CTabContent>
      </CTabs>
      <TextPopUp
        isModalOpen={isModalOpen}
        onReasonSelected={onReasonSelected}
        onRejectText={onRejectText}
        reasons={reasons}
        closeTextModal={closeTextModal}
        text={text}
      />
      <BanUnbanPopUp
        isBanned={banUnbanParameters.isBanned}
        isBanUnbanModalOpen={isBanUnbanModalOpen}
        closeBanUnbanModal={closeBanUnbanModal}
        toggleBan={toggleBan}
      />
    </>
  );
};

const initialBanUnbanState = {
  userId: '',
  isBanned: false,
};

export default TextQueueView;
