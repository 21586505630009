import { PROFILE_IMAGE_STATUS } from '../utils/statusEnum';
import moment from 'moment';
import { formatDistanceStrict } from 'date-fns';
import * as locales from 'date-fns/locale';

class Image {
  constructor(image) {
    this.url = image.url;
    this.userId = image.userId;
    this.userName = image.profile ? image.profile.name : '';
    this.userPhoto =
      image.profile && image.profile.images.length > 0
        ? image.profile.images[0].url
        : 'https://cdn4.iconfinder.com/data/icons/jetflat-2-faces-2/60/005b_042_user_profile_avatar_man_boy_round-512.png';
    this.createdAt = image.createdAt;
    this.rejected = image.status === PROFILE_IMAGE_STATUS.REJECTED;
    this.rejectReasons = image.reasons;
    this.hiveResults = MapHiveResults(image.comment);
    this.status = image.status;
    this.mediaId = image.mediaId;
    this.albumId = image.albumId;
    this.moderatorId = image.moderatorId;
    this.takenAt = parseTakenAt(image.takenAt);
  }

  get getCreatedAt() {
    return DateFormatter(this.createdAt);
  }
}

const parseTakenAt = takenAt => {
  if (moment(takenAt).isValid()) {
    const locale = locales['enUS'];
    const timeDiff = formatDistanceStrict(new Date(takenAt), new Date(), {
      addSuffix: false,
      locale,
    });
    return `Taken ${timeDiff} ago`;
  }
  return 'Metadata not available';
};

const DateFormatter = date => {
  if (!date) {
    return '';
  }

  let formattedDate = new Date(date);

  let day = ('0' + formattedDate.getUTCDate()).slice(-2);
  let month = ('0' + (formattedDate.getUTCMonth() + 1)).slice(-2);
  let year = formattedDate.getUTCFullYear();
  let hour = formattedDate.getUTCHours();
  let minutes = ('0' + formattedDate.getUTCMinutes()).slice(-2);
  let AMPM = 'AM';

  if (parseInt(hour) > 12) {
    AMPM = 'PM';
    const newHour = hour - 12;
    hour = newHour;
  } else if (hour === 0) {
    hour = '12';
  }

  // returns date in MM-DD-YYYY HH:MM AM/PM format
  return month + '/' + day + '/' + year + ' ' + hour + ':' + minutes + ' ' + AMPM;
};

const MapHiveResults = comment => {
  if (!comment) {
    return [];
  }

  const commentJSON = tryToParse(tryToParse(comment).comment || '{}');

  return Object.keys(commentJSON).map(hiveCategory => ({
    description: hiveCategory,
    score: commentJSON[hiveCategory],
  }));
};

function tryToParse(s, defaultValue = {}) {
  try {
    return JSON.parse(s || '{}');
  } catch (error) {
    return defaultValue;
  }
}

export default Image;
