import React from 'react';
import { CButton, CBadge } from '@coreui/react';
import { ClearBadge } from '../styles/views/UserProfileView.module.css';

export const ProfileActionButton = props => {
  let badgeStyle = {};
  if (props.type === 'save') {
    badgeStyle = {
      backgroundColor: 'rgb(229, 83, 83)',
      color: 'white',
      border: '1px solid rgb(229, 83, 83)',
    };
  } else if (props.type === 'cancel') {
    badgeStyle = {
      backgroundColor: 'white',
      border: '1px solid rgb(99, 111, 131)',
      color: 'black',
    };
  }
  return (
    <CButton
      className="p-0 border-0 align-self-center"
      size="sm"
      color="transparent"
      style={{
        height: '10%',
        ...props.buttonStyle,
      }}
      onClick={props.onClick}
    >
      <CBadge className={ClearBadge} style={{ ...badgeStyle, ...props.labelStyle }}>
        {props.label}
      </CBadge>
    </CButton>
  );
};
