import {
  GET_FLAGGED_USERS,
  BAN_FLAGGED_USER,
  UNBAN_FLAGGED_USER,
  CLOSE_FLAGS,
} from '../actions/flagsActions';
import statusEnum from '../../utils/statusEnum';

const initialState = {
  flaggedUsers: [],
};

export default (state = initialState, action) => {
  let updatedUsers;
  let index;
  switch (action.type) {
    case GET_FLAGGED_USERS:
      return {
        ...state,
        ...{
          flaggedUsers: action.flaggedUsers,
        },
      };
    case BAN_FLAGGED_USER:
      updatedUsers = state.flaggedUsers;
      index = findUserIndexById(updatedUsers, action.userId);
      updatedUsers[index].status = statusEnum[1];
      return { ...state, ...{ flaggedUsers: updatedUsers } };
    case UNBAN_FLAGGED_USER:
      updatedUsers = state.flaggedUsers;
      index = findUserIndexById(updatedUsers, action.userId);
      updatedUsers[index].status = updatedUsers[index].previousState;
      return { ...state, ...{ flaggedUsers: updatedUsers } };
    case CLOSE_FLAGS:
      return initialState;
    default:
      return state;
  }
};

const findUserIndexById = (users, id) => {
  return users.findIndex(user => user.userId === id);
};
