import {
  GET_IMAGES,
  MODERATE_IMAGES,
  REJECT_PHOTO,
  UNREJECT_PHOTO,
  SWITCH_FETCHING,
} from '../actions/photoModerationActions';

const initialState = {
  photos: [],
  pendingPhotos: 0,
  isFetching: true,
  photosToApprove: 0,
};

export default (state = initialState, action) => {
  let updatedPhotos = [];
  let indexPhoto;
  switch (action.type) {
    case GET_IMAGES:
      return {
        ...state,
        ...{
          photos: action.photos,
          pendingPhotos: action.pendingPhotos,
          isFetching: false,
          photosToApprove: action.photos.length,
        },
      };
    case MODERATE_IMAGES:
      return {
        ...state,
        ...{
          photos: [],
          pendingPhotos: 0,
          isFetching: true,
          photosToApprove: 0,
        },
      };
    case REJECT_PHOTO:
      updatedPhotos = state.photos;
      indexPhoto = updatedPhotos.findIndex(p => p.id === action.id);
      updatedPhotos[indexPhoto].rejected = true;
      updatedPhotos[indexPhoto].rejectReasons = action.reasons;
      return {
        ...state,
        ...{
          photos: updatedPhotos,
          photosToApprove: updatedPhotos.filter(photo => !photo.rejected).length,
        },
      };
    case UNREJECT_PHOTO:
      updatedPhotos = state.photos;
      indexPhoto = updatedPhotos.findIndex(p => p.id === action.id);
      updatedPhotos[indexPhoto].rejected = false;
      updatedPhotos[indexPhoto].rejectReasons = [];
      return {
        ...state,
        ...{
          photos: updatedPhotos,
          photosToApprove: updatedPhotos.filter(photo => !photo.rejected).length,
        },
      };
    case SWITCH_FETCHING:
      return { ...state, ...{ isFetching: !state.isFetching } };
    default:
      return state;
  }
};
