import { CButton } from '@coreui/react';
import * as moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  deleteAllBatchSuggestions,
  getBatchSuggestions,
  importBatchSuggestions,
} from '../../services/batchSuggestionsService';
import * as errorModalActions from '../../store/actions/errorModalActions';
import { downloadFile, humanFileSize } from '../../utils/utils';

function template() {
  downloadFile(
    `
userId,suggestionUserId,reason,suggestionNote
4a809e24-a954-4a67-9918-cd4f2fb0df07,0b669417-86f0-46d7-830f-7d7bc6a02fa6,party,lorem ipsum 👍
4a809e24-a954-4a67-9918-cd4f2fb0df07,ed4f3725-7a5a-4e2b-8a71-7e0b8ec54c6b,party,foo 👍
fooid1,fooid2,party,lorem ipsum 👍
fooid1,fooid3,party,foo 👍
  `.trim(),
    'motto-batch-suggestions-template.csv',
    'text/csv'
  );
}

const BatchSuggestions = props => {
  const dispatch = useDispatch();
  const baseButton = { maxWidth: '200px' };
  const [file, setFile] = useState();
  const [tab, setTab] = useState('import');
  const [report, setReport] = useState(null);
  const [list, setList] = useState(null);

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setFile(file);
      setReport(null);
    }
  };

  const handleUploadClick = async () => {
    if (file.size > 3000000) {
      alert('Cannot upload files bigger than 3 MB. Aborting');
      return;
    }
    try {
      const results = await importBatchSuggestions(file);
      setReport(results);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h3>Batch manual suggestions</h3>

      <nav
        className="navbar navbar-expand navbar-light bg-light"
        style={{ paddingLeft: 0, backgroundColor: '#e3f2fd', fontSize: '1.4em' }}
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto" style={{ paddingLeft: 0 }}>
            <li className={`nav-item ${tab === 'import' ? 'active' : ''}`}>
              <a
                className="nav-link"
                href="#"
                onClick={() => setTab('import')}
                style={{ paddingLeft: 0 }}
              >
                Import
              </a>
            </li>
            <li className={`nav-item ${tab === 'list' ? 'active' : ''}`}>
              <a className="nav-link" href="#" onClick={() => setTab('list')}>
                List
              </a>
            </li>
            <li className={`nav-item ${tab === 'delete' ? 'active' : ''}`}>
              <a className="nav-link" href="#" onClick={() => setTab('delete')}>
                Delete
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {tab === 'import' && (
        <>
          <p>
            Provide a CSV file like{' '}
            <a href="" onClick={template}>
              this template
            </a>{' '}
            in order to create batch manual suggestions
          </p>
          <div className="form-group">
            <input
              type="file"
              className="form-control-file"
              id="exampleFormControlFile1"
              onChange={handleFileChange}
            />
            <div>{file && `${humanFileSize(file.size)} ${file.type}`}</div>
          </div>
          {file && (
            <CButton color="primary" style={{ ...baseButton }} onClick={handleUploadClick}>
              Import
            </CButton>
          )}

          {report && (
            <div>
              <h4 style={{ marginTop: '20px' }}>Import results</h4>

              <h5>Failures ({(report.failure || []).length})</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">userId</th>
                    <th scope="col">suggestedUserId</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Note</th>
                    <th scope="col">Error</th>
                  </tr>
                </thead>
                <tbody>
                  {(report.failure || []).map(({ suggestion, reason }, i) => (
                    <tr key={i}>
                      <th scope="row">{i}</th>
                      <td>{suggestion.userId}</td>
                      <td>{suggestion.suggestedUserId}</td>
                      <td>{suggestion.reason}</td>
                      <td>{suggestion.suggestionNote}</td>
                      <td>{reason}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <h5>Success ({((report.success && report.success.suggestions) || []).length})</h5>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">userId</th>
                    <th scope="col">suggestedUserId</th>
                    <th scope="col">reason</th>
                    <th scope="col">expirationDate</th>
                  </tr>
                </thead>
                <tbody>
                  {((report.success && report.success.suggestions) || []).map((suggestion, i) => (
                    <tr key={i}>
                      <th scope="row">{i}</th>
                      <td>{suggestion.userId}</td>
                      <td>{suggestion.suggestedUserId}</td>
                      <td>{suggestion.reason}</td>
                      <td>{moment(suggestion.expirationDate).format()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      {tab === 'list' && (
        <>
          <p>See all batch manual suggestions of every user:</p>
          <CButton
            color="primary"
            style={{ ...baseButton }}
            onClick={async () => {
              const result = await getBatchSuggestions();
              setList(result);
            }}
          >
            List all
          </CButton>

          {list && (
            <>
              <h4 style={{ marginTop: '20px' }}>Records ({list.length})</h4>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Record id</th>
                    <th scope="col">userId</th>
                    <th scope="col">suggestedUserId</th>
                    <th scope="col">reason</th>
                    <th scope="col">suggestionNote</th>
                    <th scope="col">expirationDate</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((record, i) => (
                    <tr key={i}>
                      <th scope="row">{record.id}</th>
                      <td>{record.userId}</td>
                      <td>{record.suggestedUserId}</td>
                      <td>{record.reason}</td>
                      <td>{record.suggestionNote}</td>
                      <td>{moment(record.expirationDate).format()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </>
      )}

      {tab === 'delete' && (
        <>
          <p>Delete all manual batch suggestions</p>
          <CButton
            color="primary"
            style={{ ...baseButton }}
            onClick={async () => {
              if (
                window.confirm('Are you sure you want to delete ALL Batch Manual Suggestions ? ')
              ) {
                try {
                  await deleteAllBatchSuggestions();
                } catch (err) {
                  await dispatch(errorModalActions.showModal(err.message));
                }
              }
            }}
          >
            Delete All
          </CButton>
        </>
      )}
    </div>
  );
};

export default withRouter(BatchSuggestions);
