import { CButton, CCol, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { BackToRoomTypesList } from '../rooms/RoomCommonComponents';

export const PreferenceOption = props => {
  const dispatch = useDispatch();
  const preferenceOptionId = props.match.params.preferenceOptionId;
  const preferenceOptions = useSelector(state => state.rooms.roomPreferenceOptions);
  let preferenceOption = (preferenceOptions || []).find(po => po.id == preferenceOptionId);
  // const [loading, setLoading] = useState(!preferenceOption);

  // const [changed, setChanged] = useState(false);

  // const [name, setName] = useState(preferenceOption ? preferenceOption.name : null);
  // const [description, setDescription] = useState(preferenceOption ? preferenceOption.description : null);

  // if(preferenceOption) {
  //   setName(preferenceOption.name)
  //   setDescription(preferenceOption.description)
  // }

  const getRoomPreferenceOptions = async () => {
    // setLoading(true);
    try {
      await dispatch(roomsActions.getRoomPreferenceOptions());
      // console.log({preferenceOptions, preferenceOption, name, description, name2: preferenceOption ? preferenceOption.name : null, description23: preferenceOption ? preferenceOption.description : null});
      // setName(preferenceOption.name)
      // setDescription(preferenceOption.description)
      // preferenceOption = (preferenceOptions || []).find(po => po.id == preferenceOptionId)
      // setName('pepe')
    } catch (err) {
      console.error('err', err);
      dispatch(errorModalActions.showModal(err.message));
    }
    // setLoading(false);
  };

  useEffect(() => {
    getRoomPreferenceOptions();
  }, []);

  if (!preferenceOption) {
    return <CSpinner />;
  } else {
    return <PreferenceOptionComponent preferenceOption={preferenceOption} />;
  }

  // useEffect(() => {
  //   dispatch(roomsActions.getRoomPreferenceOptions())
  //   preferenceOption = (preferenceOptions || []).find(po => po.id == preferenceOptionId)
  //   setName(preferenceOption ? preferenceOption.name : null)
  // }, [])

  // if (preferenceOptionId && preferenceOptions && preferenceOptions.length && !preferenceOption) {
  //   dispatch(errorModalActions.showModal(`preferenceOption id ${preferenceOptionId} not found`));
  // }
};

export const PreferenceOptionComponent = ({ preferenceOption }) => {
  const [changed, setChanged] = useState(false);
  const [name, setName] = useState(preferenceOption ? preferenceOption.name : null);
  const [description, setDescription] = useState(
    preferenceOption ? preferenceOption.description : null
  );

  return (
    <CCol md="12">
      <BackToRoomTypesList />

      <h3>
        <strong>"{name}"</strong> preference option
      </h3>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              <strong>Name</strong>
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="name@example.com"
              style={{ width: 300 }}
              value={name}
              onChange={e => {
                setChanged(true);
                setName(e.target.value);
              }}
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput2" class="form-label">
              <strong>Id</strong>
            </label>
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput2"
              placeholder="name@example.com"
              style={{ width: 300 }}
              value={preferenceOption.id}
              disabled
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              <strong>Description</strong>
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              value={description}
              style={{ width: 300 }}
              onChange={e => {
                setChanged(true);
                setDescription(e.target.value);
              }}
            ></textarea>
          </div>
        </div>

        <div>
          <CButton
            color="primary"
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={() => {
              alert('NOT IMPLEMENTED YET');
            }}
            disabled={!changed}
          >
            save
          </CButton>
          <CButton
            color="danger"
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={() => {
              alert('NOT IMPLEMENTED YET');
            }}
          >
            Delete
          </CButton>
        </div>
      </div>
    </CCol>
  );
};
