import {
  CButton,
  CCol,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';
import ApiService from '../../services/ApiService';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { createCloneRoom } from '../../store/actions/roomsActions';
import { camelCaseToCapitalized, dateToDb } from '../../utils/utils';
import { BackToRoomsList, Field } from './RoomCommonComponents';
import { RoomPropertiesType, RoomStatus, RoomTypeCategory, RoomUserType } from './RoomTypes';
import { RoomUsers } from './RoomUsers';

export const RoomView = props => {
  const roomId = props.match.params.roomId;
  const dispatch = useDispatch();
  const room = useSelector(state => state.rooms.room);
  const roomTypes = useSelector(state => state.rooms.roomTypes) || [];
  const roomProperties = useSelector(state => state.rooms.roomProperties) || [];
  const roomType = room ? roomTypes.find(rt => rt.id === room.type) : null;
  const selectedTimezone = useSelector(state => state.timezone.timezone);
  const activePlaces = useSelector(state => state.rooms.activePlaces) || [];
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [tab, setTab] = useState('details');
  const [currentUsers, setCurrentUsers] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState(room ? room.users : [] || []);
  const [hostUsers, setHostUsers] = useState(room ? room.hostUsers : [] || []);
  const [showAllowedList, setShowAllowedList] = useState(false);
  const [includeAllowedList, setIncludeAllowedList] = useState(false);

  const locationTypeProps = roomProperties.filter(p => p.type === RoomPropertiesType.locationType);
  const groupTypeProps = roomProperties.filter(p => p.type === RoomPropertiesType.groupType);

  const handleCheckboxChange = e => {
    setIncludeAllowedList(e.target.checked);
  };

  const toggleAllowedList = () => {
    setShowAllowedList(prevState => !prevState);
  };

  // a state with all fields modification to be used on save action
  const [editorFields, setEditorFields] = useState({});

  useEffect(() => {
    setEditorFields({});
    setInvitedUsers(room ? room.users : [] || []);
    setHostUsers(room ? room.hostUsers : [] || []);
  }, [room]);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleChange = event => {
    setDeleteMessage(event.target.value);
  };

  function updateEditorField(id, value) {
    // if it doesn't have seconds we add it so date-format doesn't fail
    if (id.toLowerCase().endsWith('date') && value.match(/T\d\d:\d\d$/)) {
      value = `${value}:00`;
    }
    const data = { ...editorFields, ...{ [id]: value } };
    setEditorFields(data);
  }

  const fetchRoomHandler = async () => {
    setLoading(true);
    try {
      await dispatch(roomsActions.getRoom(roomId));
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred getting room ${roomId}: ${err.message}`)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRoomHandler();
    dispatch(roomsActions.getRoomTypes());
    dispatch(roomsActions.getRoomProperties());
    dispatch(roomsActions.getAllActivePlaces());
    return () => {};
  }, []);

  const onInputChangeBuilder = name => e => {
    updateEditorField(name, e.target.value);
    setChanged(true);
  };

  function fieldProps(room, key, value = null) {
    return {
      name: key,
      value: value || room[key],
      editable: canEditRoomProp(key, room) || room.clone,
      onChange: onInputChangeBuilder(key),
      timezone: selectedTimezone,
    };
  }

  const onActivePlaceChange = activePlaceId => {
    editorFields.activePlaceId = activePlaceId;
    setChanged(true);
  };

  function canEditRoomProp(name, room) {
    if (room.editable) {
      if (room.status === RoomStatus.active && name === 'startDate') {
        return false;
      }
    }
    return room.editable;
  }

  const onTypeChange = roomTypeId => {
    editorFields.type = roomTypeId;
    setChanged(true);
  };

  const onRoomPropertyChange = roomPropertyTypeName => roomPropertyTypeId => {
    editorFields[roomPropertyTypeName] = roomPropertyTypeId;
    setChanged(true);
  };

  async function onSave() {
    const roomBody = { ...room, ...editorFields };
    Object.keys(roomBody).forEach(key => {
      if (roomBody[key] && key.toLowerCase().endsWith('date')) {
        if (!roomBody[key].endsWith('.000Z')) {
          roomBody[key] = roomBody[key] + '.000Z';
        }
        const formattedDate = dateToDb(roomBody[key], true);
        roomBody[key] = formattedDate;
      }
    });
    const toastId = toast.loading('Saving...', toastDefaultConfig);
    try {
      await dispatch(roomsActions.updateRoom(roomBody));
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Saved!',
        type: 'success',
        isLoading: false,
      });
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred saving room ${roomId}: ${err.message}`)
      );
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Error updating room',
        type: 'error',
        isLoading: false,
      });
    }
  }

  function onClone() {
    try {
      dispatch(roomsActions.cloneRoom(room));
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred cloning room ${roomId}: ${err.message}`)
      );
    }
  }

  async function onCloneCreate() {
    const roomBody = { ...room, ...editorFields };
    const toastId = toast.info(`Saving room ${roomBody.name}`, toastDefaultConfig);
    try {
      if (!includeAllowedList) {
        delete roomBody.users;
      }
      const result = await createCloneRoom(roomBody);
      if (result.status === 200) {
        toast.update(toastId, {
          ...toastDefaultConfig,
          render: `Room ${room.name} created successfully`,
          type: 'success',
          isLoading: false,
        });
        window.location.replace('/main/rooms/' + result.data.roomId);
      }
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred cloning room ${roomId}: ${err.message}`)
      );
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: `Error creating room ${room.name}`,
        type: 'error',
        isLoading: false,
      });
    }
  }

  async function onDelete() {
    try {
      await dispatch(roomsActions.deleteRoom(room.id));
      window.location.replace('/main/rooms');
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred deleting room ${roomId}: ${err.message}`)
      );
    }
  }

  function onDeleteUser(type) {
    // if(type===RoomUserType.current) {
    return async function onDeleteCurrentUser(userId) {
      if (
        type === RoomUserType.current &&
        !window.confirm('Are you sure you want to to remove user from room?')
      ) {
        return;
      }
      const toastId = toast.info(`Removing room user`, toastDefaultConfig);
      try {
        await ApiService.delete(
          `rooms/${roomId}/users/${userId}?type=${type}`,
          { withCredentials: true },
          {}
        );

        if (type === RoomUserType.current) {
          setCurrentUsers(currentUsers.filter(c => c.userId != userId));
        } else if (type === RoomUserType.host) {
          setHostUsers(hostUsers.filter(c => c.userId != userId));
        } else if (type === RoomUserType.invited) {
          setInvitedUsers(invitedUsers.filter(c => c.userId != userId));
        }
        toast.update(toastId, {
          ...toastDefaultConfig,
          render: `Room user removed successfully`,
          type: 'success',
          isLoading: false,
        });
      } catch (err) {
        dispatch(
          errorModalActions.showModal(
            `An error occurred removing ${type} room,user=${room.id},${userId} ${err.message}`
          )
        );
      }
    };
  }

  if (!room || loading) {
    return (
      <div>
        <BackToRoomsList />
        <CSpinner />
      </div>
    );
  }

  function isDeleteAllowed(roomId) {
    return deleteMessage !== `I want to delete room ${roomId}`;
  }

  const loadUsers = async () => {
    try {
      const results = await roomsActions.loadUsers(room.id);
      setCurrentUsers(results.users);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const shouldShowRoomType =
    room.clone || (room.editable && [RoomStatus.future].includes(room.status));

  return (
    <div>
      <CCol md="12">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <BackToRoomsList />
            <h3>
              {room.name} ({room.status} -{' '}
              {(room.users || []).length && !room.roomRule ? 'private' : 'public'})
            </h3>
          </div>
          <div>
            {!room.clone && (
              <CButton
                color="secondary"
                style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                onClick={onClone}
              >
                Clone
              </CButton>
            )}
            {changed && (
              <>
                <CButton
                  color="primary"
                  style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                  onClick={room.clone ? onCloneCreate : onSave}
                >
                  Save
                </CButton>

                <CButton
                  color="secondary"
                  style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                  to={{
                    pathname: `/main/rooms`,
                  }}
                >
                  Cancel
                </CButton>
              </>
            )}
            {!room.clone && (
              <CButton
                color="danger"
                style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                onClick={toggleModal}
              >
                Delete
              </CButton>
            )}
            <CModal show={modalOpen} onClose={toggleModal}>
              <CModalHeader closeButton>Confirm Delete</CModalHeader>
              <CModalBody>
                Are you sure you want to delete this room?
                <br /> Write <b>I want to delete room + ID</b>. Example "I want to delete room 45".
                <CInput
                  type="text"
                  placeholder="I want to delete room"
                  value={deleteMessage}
                  onChange={handleChange}
                />
              </CModalBody>
              <CModalFooter>
                <CButton color="danger" onClick={onDelete} disabled={isDeleteAllowed(roomId)}>
                  Delete
                </CButton>
                <CButton color="secondary" onClick={toggleModal}>
                  Cancel
                </CButton>
              </CModalFooter>
            </CModal>
          </div>
        </div>

        <nav
          className="navbar navbar-expand navbar-light bg-light"
          style={{ paddingLeft: 0, backgroundColor: '#e3f2fd', fontSize: '1.4em' }}
        >
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ paddingLeft: 0 }}>
              <li className={`nav-item ${tab === 'details' ? 'active' : ''}`}>
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => setTab('details')}
                  style={{ paddingLeft: 0 }}
                >
                  Room details
                </a>
              </li>
              {!room.clone && (
                <li className={`nav-item ${tab === 'users' ? 'active' : ''}`}>
                  <a className="nav-link" href="#" onClick={() => setTab('users')}>
                    Invited
                  </a>
                </li>
              )}
              {!room.clone && roomType && roomType.category === RoomTypeCategory.group && (
                <li className={`nav-item ${tab === 'hosts' ? 'active' : ''}`}>
                  <a className="nav-link" href="#" onClick={() => setTab('hosts')}>
                    Hosts
                  </a>
                </li>
              )}

              {room.status === RoomStatus.active && (
                <li className={`nav-item ${tab === 'current-users' ? 'active' : ''}`}>
                  <a className="nav-link" href="#" onClick={() => setTab('current-users')}>
                    Current users
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>

        {tab === 'details' && (
          <div>
            {!room.clone && <Field name="id" value={room.id} />}

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
              <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
                Room Type
              </label>
              {!room.clone &&
                [RoomStatus.active, RoomStatus.ended, RoomStatus.shownNotActive].includes(
                  room.status
                ) && (
                  <Link
                    to={{
                      pathname: `/main/roomTypes/${room.type}`,
                    }}
                    className="link"
                    style={{ color: '#4A90E2', marginLeft: 10 }}
                  >
                    {room.type}
                  </Link>
                )}
              {shouldShowRoomType && (
                <select
                  className="form-select form-control"
                  style={{ width: 290, marginLeft: 9 }}
                  onChange={e => onTypeChange(parseInt(e.target.value))}
                >
                  {roomTypes
                    .filter(rt => rt.category === room.roomTypeCategory)
                    .map(rt => (
                      <option value={rt.id} selected={room.type === rt.id}>
                        {rt.name}
                      </option>
                    ))}
                </select>
              )}
              {[RoomStatus.active, RoomStatus.ended].includes(room.status) && (
                <Link
                  to={{
                    pathname: `/main/roomTypes/${room.type}`,
                  }}
                  className="link"
                  style={{ color: '#4A90E2', marginLeft: 10 }}
                >
                  {(roomTypes.find(rt => rt.id === room.type) || { name: '' }).name}
                </Link>
              )}
            </div>

            <Field {...fieldProps(room, 'name')} />
            <Field {...fieldProps(room, 'description')} />
            {!room.clone && <Field name="status" value={room.status} />}

            <hr />

            {!room.clone && <Field name="createdAt" value={room.createdAt} type="datetime-local" />}
            <Field type="datetime-local" {...fieldProps(room, 'showDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'startDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'endDate')} />

            <hr />
            <Field type="datetime-local" {...fieldProps(room, 'meetupStartDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'meetupEndDate')} />
            <Field {...fieldProps(room, 'meetupAddress')} />
            <Field {...fieldProps(room, 'meetupLocationDetails')} />
            <Field
              name="meetupLatitude"
              {...fieldProps(
                room,
                'meetupLatitude',
                room.meetupLocation ? room.meetupLocation.latitude : null
              )}
            />
            <Field
              name="meetupLongitude"
              {...fieldProps(
                room,
                'meetupLongitude',
                room.meetupLocation ? room.meetupLocation.longitude : null
              )}
            />
            <Field type="datetime-local" {...fieldProps(room, 'meetupLocationVisibleDate')} />

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
              <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
                {camelCaseToCapitalized(RoomPropertiesType.groupType)}
              </label>
              <select
                className="form-select form-control"
                style={{ width: 290, marginLeft: 9 }}
                onChange={e => onRoomPropertyChange('groupTypeId')(parseInt(e.target.value))}
                defaultValue={room.groupType ? room.groupType.id : 'NONE'}
              >
                <option value="NONE" selected={!room.groupType}>
                  None
                </option>
                {groupTypeProps.map(prop => (
                  <option
                    value={prop.id}
                    selected={room.groupType && room.groupType.id === prop.id}
                  >
                    {prop.name}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
              <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
                {camelCaseToCapitalized(RoomPropertiesType.locationType)}
              </label>
              <select
                className="form-select form-control"
                style={{ width: 290, marginLeft: 9 }}
                onChange={e => onRoomPropertyChange('locationTypeId')(parseInt(e.target.value))}
                defaultValue={room.locationType ? room.locationType.id : 'NONE'}
              >
                <option value="NONE" selected={!room.locationType}>
                  None
                </option>
                {locationTypeProps.map(prop => (
                  <option
                    value={prop.id}
                    selected={room.locationType && room.locationType.id === prop.id}
                  >
                    {prop.name}
                  </option>
                ))}
              </select>
            </div>

            <hr />

            {room.clone ? (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
                  Active place
                </label>
                <select
                  className="form-select form-control"
                  style={{ width: 290, marginLeft: 9 }}
                  onChange={e => onActivePlaceChange(parseInt(e.target.value))}
                  defaultValue={room.activePlaceId}
                >
                  {activePlaces.map(ap => (
                    <option value={ap.id} selected={room.activePlaceId === ap.id}>
                      {ap.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <Field
                name="activePlaceName"
                value={room.activePlaceName}
                description="default active place"
              />
            )}

            <Field
              name="latitude"
              {...fieldProps(room, 'latitude', room.center ? room.center.latitude : null)}
            />
            <Field
              name="longitude"
              {...fieldProps(room, 'longitude', room.center ? room.center.longitude : null)}
            />
            <Field name="radius" {...fieldProps(room, 'radius')} />
            {room.editable && (
              <p>
                Note: Dates must be provided in UTC format. You can use a{' '}
                <a href="https://dateful.com/convert/utc">tool like this</a> to calculate dates in
                your local zone.
              </p>
            )}
            {room.clone && room.users && (
              <div>
                <label>
                  <input
                    type="checkbox"
                    style={{ marginRight: '4px' }}
                    checked={includeAllowedList}
                    onChange={handleCheckboxChange}
                  />
                  Include allowed-list.
                </label>
                <br />
                <Link onClick={toggleAllowedList}>Click here to toggle the allowed list</Link>
                {showAllowedList && (
                  <div>
                    <h4>Allowed list</h4>
                    <ul>
                      {room.users.map(user => (
                        <li>{user.userId}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {tab === 'users' && (
          <RoomUsers
            room={room}
            type={RoomUserType.invited}
            users={invitedUsers}
            onDeleteUser={onDeleteUser(RoomUserType.invited)}
            deleteUserDisabled={invitedUsers && invitedUsers.length <= 1}
          />
        )}

        {tab === 'hosts' && (
          <RoomUsers
            room={room}
            type={RoomUserType.host}
            users={hostUsers}
            onDeleteUser={onDeleteUser(RoomUserType.host)}
            deleteUserDisabled={hostUsers && hostUsers.length <= 1}
          />
        )}

        {tab === 'current-users' && (
          <div>
            <CButton color="primary" onClick={loadUsers}>
              {currentUsers ? 'Refresh' : 'List'}
            </CButton>

            {currentUsers && (
              <RoomUsers
                room={room}
                users={currentUsers}
                type={RoomUserType.current}
                onDeleteUser={onDeleteUser(RoomUserType.current)}
                deleteUserDisabled={currentUsers && currentUsers.length <= 1}
              />
            )}
          </div>
        )}
      </CCol>
    </div>
  );
};
