import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CLabel,
  CFormGroup,
} from '@coreui/react';
import { Card } from '../../styles/views/UserProfileView.module.css';
import FormGroupComponent from '../FormGroupComponent';
import ProfileBirthDate from '../ProfileBirthDate';
import PropTypes from 'prop-types';
import Profile from '../../models/profile';
import { changeEthnicity, changeScore } from '../../store/actions/profileActions';
import { getAllEthnicityTags } from '../../store/actions/tagActions';
import { ProfileTagFieldIds } from '../../models/profileTagFieldIds';

const UserProfileInformationPanel = props => {
  const { profile } = props;
  const dispatch = useDispatch();
  const { ethnicityAdmin } = useSelector(state => state.tags);
  const ethnicity = profile?.ethnicityAdmin?.map(e => e.id) || [];

  React.useEffect(() => {
    dispatch(getAllEthnicityTags(ProfileTagFieldIds.ethnicityAdmin));
  }, []);

  const onEthnicityChange = ({ target }) => {
    const selection = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    const value = selection.map(s => parseInt(s));
    dispatch(changeEthnicity(value));
  };

  const onScoreChange = ({ target }) => {
    let value = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value)[0];
    if (value === null || `${value}`.toLowerCase() === 'blank') {
      value = null;
    }
    dispatch(changeScore(value));
    target.closest('.dropdown-menu').classList.remove('show');
  };

  return (
    <CCard className={Card}>
      <CCardHeader className="d-flex justify-content-between align-items-center border-0">
        <h3 className="m-0">Profile Information</h3>
      </CCardHeader>
      <CCardBody className="pt-0">
        <CRow>
          <CCol md="4" className="py-3">
            <ProfileBirthDate
              label="Date of Birth"
              id="birthDate"
              placeholder={profile.getBirthDay || ''}
              value={profile.birthDate || ''}
              userId={profile.userId}
            />
            <FormGroupComponent label="Gender" id="gender" placeholder={profile.getGender} />
            <FormGroupComponent
              label="Relationship Status"
              id="rStatus"
              placeholder={profile.getRelationship}
            />
            <FormGroupComponent
              label="Looking For"
              id="looking-for"
              placeholder={profile.getLookingFor}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CLabel>Admin handled Ethnicity</CLabel>
              <CDropdown key={ethnicityAdmin.length} title="Change user ethnicity (multiple)">
                <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                  {`Selected (${ethnicity.length}) ethnicit${ethnicity.length === 1 ? 'y' : 'ies'}`}
                </CDropdownToggle>
                <CDropdownMenu style={{ padding: 0 }}>
                  <select
                    aria-label="Ethnicity"
                    id="ethnicity-select"
                    multiple
                    defaultValue={ethnicity}
                    size={ethnicityAdmin.length}
                    onChange={onEthnicityChange}
                    style={{ width: '100%' }}
                  >
                    {ethnicityAdmin?.map(e => (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </CDropdownMenu>
              </CDropdown>
            </div>
          </CCol>
          <CCol md="4" className="py-3">
            <FormGroupComponent label="Height" id="height" placeholder={profile.getHeight} />
            <FormGroupComponent label="Position" id="position" placeholder={profile.getPosition} />
            <FormGroupComponent
              label="Smoke Cigarettes"
              id="sCigarettes"
              placeholder={profile.cigarettes}
            />
            <FormGroupComponent
              label="Interests"
              id="interests"
              placeholder={profile.getInterests}
            />
            <FormGroupComponent
              label="User's self Ethnicity"
              id="ethnicity"
              placeholder={profile.getEthnicity}
            />
          </CCol>
          <CCol md="4" className="py-3">
            <FormGroupComponent
              label="Weight"
              id="weight"
              placeholder={`${profile.getWeight} lb`}
            />
            <FormGroupComponent label="HIV Status" id="hivStatus" placeholder={profile.hivStatus} />
            <FormGroupComponent label="pronouns" id="pronouns" placeholder={profile.getPronouns} />
            <FormGroupComponent
              label="Neighborhood"
              id="homeLocation"
              placeholder={[profile.homeNeighborhood, profile.homeCity].join(', ')}
            />
            <CFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
              <CLabel>Score</CLabel>
              <CDropdown title="Change user score (single)">
                <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}>
                  {`${profile.score === null ? 'blank' : profile.score}`}
                </CDropdownToggle>
                <CDropdownMenu style={{ padding: 0 }}>
                  <select
                    aria-label="Score"
                    id="score-select"
                    defaultValue={profile.score}
                    size={7}
                    onChange={onScoreChange}
                    style={{ width: '100%' }}
                  >
                    {[null, 0, 1, 2, 3, 4, 5].map(value => (
                      <option key={`${value}`} value={value}>
                        {value === null ? 'blank' : value}
                      </option>
                    ))}
                  </select>
                </CDropdownMenu>
              </CDropdown>
            </CFormGroup>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

UserProfileInformationPanel.propTypes = {
  profile: PropTypes.instanceOf(Profile).isRequired,
};

export default UserProfileInformationPanel;
