import React from 'react';
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import PropTypes from 'prop-types';
import { BanUnbanModal, BanUnbanFooter } from '../styles/components/BanUnbanPopUp.module.css';

const BanUnbanPopUp = props => {
  return (
    <>
      <CModal
        centered
        onClose={props.closeBanUnbanModal}
        show={props.isBanUnbanModalOpen}
        size="sm"
        addContentClass={BanUnbanModal}
      >
        <CModalBody>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="font-weight-bold text-center">
              Are you sure you want to {props.isBanned ? 'unban' : 'ban'} this user?
            </div>
          </div>
        </CModalBody>
        <CModalFooter className={BanUnbanFooter}>
          <CButton onClick={props.toggleBan} color={props.isBanned ? 'info' : 'danger'}>
            Yes
          </CButton>
          <CButton onClick={props.closeBanUnbanModal} variant="outline" color="secondary">
            No
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

BanUnbanPopUp.propTypes = {
  isBanned: PropTypes.bool.isRequired,
  isBanUnbanModalOpen: PropTypes.bool.isRequired,
  closeBanUnbanModal: PropTypes.func.isRequired,
  toggleBan: PropTypes.func.isRequired,
};

export default BanUnbanPopUp;
