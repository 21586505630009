import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import authReducer from './reducers/authReducer';
import photoModerationReducer from './reducers/photoModerationReducer';
import textReducer from './reducers/textReducer';
import flagsReducer from './reducers/flagsReducer';
import usersReducer from './reducers/usersReducer';
import roomsReducer from './reducers/roomsReducer';
import profileReducer from './reducers/profileReducer';
import errorModalReducer from './reducers/errorModalReducer';
import tagsReducer from './reducers/tagsReducer';

import timezoneReducer from './reducers/timezoneReducer';

export const browserHistory = createBrowserHistory();

const rootReducer = history =>
  combineReducers({
    auth: authReducer,
    photos: photoModerationReducer,
    text: textReducer,
    flags: flagsReducer,
    users: usersReducer,
    rooms: roomsReducer,
    profile: profileReducer,
    error: errorModalReducer,
    router: connectRouter(history),
    tags: tagsReducer,
    timezone: timezoneReducer,
  });

const composeWithDev = composeWithDevTools({
  maxAge: 100,
});

const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDev : compose;

const store = createStore(
  rootReducer(browserHistory),
  composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), ReduxThunk))
);

export default store;
