const USER_SESSION = 'USER_SESSION';
const USER_DATA = 'USER_DATA';

const storeUserSession = isActive => {
  localStorage.setItem(USER_SESSION, isActive);
};

const getUserSession = () => {
  return localStorage.getItem(USER_SESSION);
};

const removeUserSession = () => {
  return localStorage.removeItem(USER_SESSION);
};

const storeUserData = user => {
  localStorage.setItem(USER_DATA, JSON.stringify(user));
};

const getUserData = () => {
  return localStorage.getItem(USER_DATA);
};

const removeUserData = () => {
  return localStorage.removeItem(USER_DATA);
};

export {
  storeUserSession,
  getUserSession,
  removeUserSession,
  storeUserData,
  getUserData,
  removeUserData,
};
