export const INSTAGRAM_BASE = `https://www.instagram.com/`;
export const TWITTER_BASE = `https://www.twitter.com/`;
export const TIKTOK_PROFILE_BASE = `https://www.tiktok.com/@`;
export const SNAPCHAT_PROFILE_BASE = `https://www.snapchat.com/add/`;

export const PROFILE_BASE_URL_BY_SOCIAL_MEDIA = {
  tikTok: TIKTOK_PROFILE_BASE,
  twitter: TWITTER_BASE,
  instagram: INSTAGRAM_BASE,
  snapChat: SNAPCHAT_PROFILE_BASE,
};

export const SUPPORTED_SOCIAL_MEDIA = {
  instagram: 'instagram',
  // twitter: 'twitter',
  // snapChat: 'snapChat',
  // tikTok: 'tikTok',
};
