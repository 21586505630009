import CIcon from '@coreui/icons-react';
import { CButton, CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as errorModalActions from '../store/actions/errorModalActions';
import * as profileActions from '../store/actions/profileActions';
import LocationPin from './LocationPin';

const ProfileSpoofLocation = props => {
  const locationString = props.location
    ? `${parseFloat(props.location.latitude).toFixed(7)},${parseFloat(props.location.longitude).toFixed(7)}`
    : '';
  const [location, setLocation] = useState(locationString);
  const [forceLocation, setForceLocation] = useState(props.forceLocation);
  const [modified, setModified] = useState(false);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  const onSaveLocation = async () => {
    try {
      setSaving(true);
      await dispatch(profileActions.spoofLocation(props.userId, location, forceLocation));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setSaving(false);
  };
  if (saving) {
    return <CSpinner color="info" style={{ marginLeft: '50%' }} />;
  }
  return (
    <div>
      <h4 style={{ display: 'inline' }}>Spoof location</h4>
      <p style={{ fontSize: '0.7em' }}>
        Allows user to spoof an arbitrary world location. Copy it from google maps{' '}
        <code>latitude,longitude</code> format.
      </p>

      <label>
        <input
          type="checkbox"
          checked={forceLocation}
          style={{ marginRight: '4px' }}
          onChange={e => {
            setForceLocation(e.target.checked);
            setModified(true);
          }}
        ></input>
        Enable Spoofing
      </label>

      <div>
        {printLocationLink(location)}
        <label style={{ width: '80%' }}>
          <input
            style={{ width: '100%' }}
            type="text"
            placeholder="-34.8816075,-56.1592461"
            value={location}
            onChange={e => {
              setLocation(e.target.value);
              setModified(true);
            }}
          ></input>
        </label>
      </div>

      {modified && (
        <CButton className="flex-grow-1" color={'info'} size="sm" onClick={onSaveLocation}>
          Save
        </CButton>
      )}
    </div>
  );
};

// TODO: this is copied from UsersTable.js, move to its own component
const printLocationLink = locationStr => {
  const a = locationStr.split(',');
  let location;
  if (a.length === 2 && !isNaN(parseFloat(a[0])) && !isNaN(parseFloat(a[1]))) {
    location = { latitude: parseFloat(a[0]), longitude: parseFloat(a[1]) };
  }
  return <LocationPin location={location} />;
};

ProfileSpoofLocation.propTypes = {
  userId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  forceLocation: PropTypes.bool.isRequired,
};

export default ProfileSpoofLocation;
