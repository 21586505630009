import { CButton } from '@coreui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const FieldOption = props => {
  const { id, name } = props.option;
  const { index, options, isTag } = props;

  const move = direction => {
    props.onOptionMove({ direction, index });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
      <div>
        <strong>"{name}"</strong>
        (id=
        {isTag ? (
          <span>{id}</span>
        ) : (
          <Link
            to={{
              pathname: `/main/preferenceOptions/${id}`,
            }}
            className="link"
            style={{ color: '#4A90E2' }}
          >
            {id}
          </Link>
        )}
        )
      </div>
      <div>
        <CButton
          color="secondary"
          disabled={index === 0}
          size="sm"
          style={{ marginRight: 6, fontSize: 9 }}
          onClick={() => move('up')}
        >
          up
        </CButton>
        <CButton
          color="secondary"
          size="sm"
          disabled={index === (options || []).length - 1}
          style={{ marginRight: 6, fontSize: 9 }}
          onClick={() => move('down')}
        >
          down
        </CButton>
      </div>
    </div>
  );
};
