import { CCard, CCol, CDataTable } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from '../../styles/components/DataTable.module.css';
import FilterComponent from '../FilterComponent';
import LocationPin from '../LocationPin';

const UsersTable = props => {
  const fields = [
    {
      key: 'userId',
      label: 'ID',
      _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'name',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    // {
    //   key: "location",
    //   _style: { width: "1%", fontSize: "0.75rem", borderTop: "0px" },
    //   sorter: false,
    //   filter: false,
    // },

    // {
    //   key: "signUpLocation",
    //   _style: { width: "1%", fontSize: "0.75rem", borderTop: "0px" },
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: 'signUpCountry',
      label: 'Signup Country',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'signUpState',
      label: 'Signup State',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'signUpCity',
      label: 'Signup City',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },

    // {
    //   key: "homeLocation",
    //   label: 'Home Location',
    //   _style: { width: "1%", fontSize: "0.75rem", borderTop: "0px" },
    //   sorter: false,
    //   filter: false,
    // },
    {
      key: 'homeCountry',
      label: 'Home Country',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'homeState',
      label: 'Home State',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'homeCity',
      label: 'Home City',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },

    // created - user list only
    props.isPhotoQueue
      ? null
      : {
          key: 'created',
          label: 'Date Registered',
          _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
        },

    // referred by
    props.isPhotoQueue
      ? null
      : {
          key: 'referredBy',
          label: 'Referred',
          _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
        },

    // about - user list only
    // props.isPhotoQueue ? null : {
    //   key: "about",
    //   label: "About Me",
    //   _style: { width: "20%", fontSize: "0.75rem", borderTop: "0px" },
    // },

    // oldestPendingMedia - photo queue only
    props.isPhotoQueue
      ? {
          key: 'oldestPendingMedia',
          label: 'Oldest Pending Photo',
          _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
        }
      : null,
    {
      key: 'status',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
      filter: false,
    },
  ].filter(entry => entry);

  return (
    <>
      <FilterComponent {...props} />
      <CCol md="12">
        <CCard className={[Table, 'pl-3'].join(' ')}>
          <CDataTable
            onSorterValueChange={props.onOrderChange}
            size="sm"
            items={props.users}
            fields={fields}
            hover
            sorter={{ external: true }}
            scopedSlots={{
              userId: user => (
                <td>
                  <div style={{ wordBreak: 'break-all' }}>
                    <p>{user.userId}</p>
                  </div>
                </td>
              ),
              name: user => (
                <td>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                      <Link
                        to={{
                          pathname: `/main/profile/${user.userId}`,
                          state: { prevLocation: props.location.pathname },
                        }}
                      >
                        <div className="c-avatar mr-1" style={{ backgroundColor: '#c7cbd1' }}>
                          <img
                            src={user.profilePhoto}
                            className="c-avatar-img"
                            alt="Not found"
                            style={{
                              maxHeight: '100%',
                              objectFit: 'cover',
                              minHeight: '36px',
                              minWidth: '36px',
                            }}
                          />
                        </div>
                      </Link>
                      <p className="mb-0 align-self-center">
                        <Link
                          to={{
                            pathname: `/main/profile/${user.userId}`,
                            state: { prevLocation: props.location.pathname },
                          }}
                          className="link"
                          style={{ color: '#4A90E2' }}
                        >
                          {user.name}
                        </Link>
                      </p>
                    </div>
                  </div>
                </td>
              ),
              location: user => (
                <td>
                  <LocationPin location={user.location} />
                </td>
              ),
              signUpLocation: user => (
                <td>
                  <LocationPin location={user.signUpLocation} />
                </td>
              ),
              signUpCountry: user => <td>{user.signUpCountry}</td>,
              signUpState: user => <td>{user.signUpState}</td>,
              signUpCity: user => <td>{user.signUpCity}</td>,
              homeLocation: user => (
                <td>
                  <LocationPin location={user.homeLocation} />
                </td>
              ),
              homeCountry: user => <td>{user.homeCountry}</td>,
              homeState: user => <td>{user.homeState}</td>,
              homeCity: user => <td>{user.homeCity}</td>,
              created: user => <td>{user.getCreated}</td>,
              referredBy: user => (
                <td>
                  {user.referredBy ? (
                    <Link
                      to={{
                        pathname: `/main/profile/${user.referredBy}`,
                        state: { prevLocation: props.location.pathname },
                      }}
                    >
                      Yes
                    </Link>
                  ) : (
                    'No'
                  )}
                </td>
              ),
              about: user => (
                <td>
                  {user.about && (
                    <div className="d-flex justify-content-between">
                      <div style={{ wordBreak: 'break-word' }}>
                        <p>{user.about}</p>
                      </div>
                    </div>
                  )}
                </td>
              ),
              oldestPendingMedia: user => <td>{user.getOldestPendingMedia}</td>,
            }}
          />
        </CCard>
      </CCol>
    </>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};

export default UsersTable;
