import ApiService from '../../services/ApiService';
import Image from '../../models/image';

export const GET_IMAGES = 'GET_IMAGES';
export const REJECT_PHOTO = 'REJECT_PHOTO';
export const UNREJECT_PHOTO = 'UNREJECT_PHOTO';
export const MODERATE_IMAGES = 'MODERATE_IMAGES';
export const SWITCH_FETCHING = 'SWITCH_FETCHING';

export const rejectPhoto = (id, reasons) => {
  return dispatch => {
    dispatch({
      type: REJECT_PHOTO,
      id: id,
      reasons: reasons,
    });
  };
};

export const unrejectPhoto = id => {
  return dispatch => {
    dispatch({
      type: UNREJECT_PHOTO,
      id: id,
    });
  };
};

export const switchFetchingStatus = () => {
  return dispatch => {
    dispatch({
      type: SWITCH_FETCHING,
    });
  };
};

export const getImages = () => {
  return async dispatch => {
    const response = await ApiService.get(`images/pending?limit=10`, {
      withCredentials: true,
    });

    const images = response.data.images.map(imgData => {
      return new Image(imgData);
    });

    const pendingPhotos = response.data.pendingCount ?? 0;

    dispatch({
      type: GET_IMAGES,
      photos: images,
      pendingPhotos: pendingPhotos,
    });
  };
};

export const sendReviewedImages = () => {
  return async (dispatch, getState) => {
    const photos = getState().photos.photos;

    const body = {
      approved: filterApprovedPhotos(photos),
      rejected: filterRejectedPhotos(photos),
    };

    await ApiService.post('images/moderate', body, { withCredentials: true });

    dispatch({ type: MODERATE_IMAGES });
  };
};

const filterApprovedPhotos = photos => {
  return photos.reduce((acc, photo) => (!photo.rejected ? [...acc, photo.id.toString()] : acc), []);
};

const filterRejectedPhotos = photos => {
  return photos.reduce(
    (acc, photo) =>
      photo.rejected ? [...acc, { id: photo.id.toString(), reasons: photo.rejectReasons }] : acc,
    []
  );
};
