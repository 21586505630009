import React from 'react';
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import PropTypes from 'prop-types';
import { DeleteUserModal, DeleteUserFooter } from '../styles/components/DeleteUserPopUp.module.css';

const DeleteUserPopUp = props => {
  return (
    <>
      <CModal
        centered
        onClose={props.closeDeleteUserModal}
        show={props.isDeleteUserModalOpen}
        size="sm"
        addContentClass={DeleteUserModal}
      >
        <CModalBody>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="font-weight-bold text-center">
              Are you sure you want to delete this user? All its data will be lost permanently!
            </div>
          </div>
        </CModalBody>
        <CModalFooter className={DeleteUserFooter}>
          <CButton onClick={props.onAction} color="danger">
            Yes
          </CButton>
          <CButton onClick={props.closeDeleteUserModal} variant="outline" color="secondary">
            No
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

DeleteUserPopUp.propTypes = {
  isDeleteUserModalOpen: PropTypes.bool.isRequired,
  closeDeleteUserModal: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default DeleteUserPopUp;
