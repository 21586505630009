import ApiService from '../../services/ApiService';
import User from '../../models/user';

export const GET_USERS = 'GET_USERS';

export const defaultUsersState = {
  search: '',
  page: 0,
  limit: 25,
  status: null,
  country: null,
  state: null,
  activePlaces: null,
  city: null,
  homeCountry: null,
  homeState: null,
  homeCity: null,
  referred: '',
  orderBy: '',
  orderDirection: '',
  total: 0,
};

export const getUsers = params => {
  return async (dispatch, getState) => {
    const state = getState();
    params = {
      ...defaultUsersState,
      ...state.users,
      ...params,
    };

    const response = await ApiService.get(
      `profiles?${[
        `search=${params.search}`,
        `limit=${params.limit}`,
        `page=${params.page}`,
        `status=${params.status && params.status.length ? params.status.join() : ''}`,
        `activePlaces=${params.activePlaces && params.activePlaces.length ? params.activePlaces.join() : ''}`,
        `referred=${params.referred && params.referred.length ? params.referred.join() : ''}`,
        `orderBy=${params.orderBy}`,
        `orderDirection=${params.orderDirection}`,
        `country=${params.country || ''}`,
        `state=${params.state || ''}`,
        `city=${params.city || ''}`,
        `homeCountry=${params.homeCountry || ''}`,
        `homeState=${params.homeState || ''}`,
        `homeCity=${params.homeCity || ''}`,
      ].join('&')}`,
      { withCredentials: true }
    );

    const { profiles, more, total } = response.data;

    dispatch({
      type: GET_USERS,
      users: MapProfilesFromResponse(profiles),
      moreUsersLeft: more,
      currentPage: params.page,
      status: params.status,
      activePlaces: params.activePlaces,
      country: params.country,
      state: params.state,
      city: params.city,
      homeCountry: params.homeCountry,
      homeState: params.homeState,
      homeCity: params.homeCity,
      search: params.search,
      referred: params.referred,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
      total,
      limit: params.limit,
    });
  };
};

const MapProfilesFromResponse = profiles => {
  return profiles.map(profile => {
    return new User(profile);
  });
};
