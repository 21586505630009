export const statusEnum = Object.freeze({
  0: 'Approved',
  1: 'Banned',
  3: 'Needs Review',
  4: 'On Hold',
  5: 'Pending User',
  6: 'Waitlisted',
  7: 'Auto-Approved',
});

export const referredOptions = Object.freeze({
  referred: 'Referred',
  notReferred: 'Not referred',
});

export const PROFILE_IMAGE_STATUS = Object.freeze({
  PENDING: 0,
  PENDING_HUMAN: 1,
  APPROVED: 2,
  REJECTED: 3,
});

export const IMAGE_REJECTION_REASONS = Object.freeze([
  { id: 100, name: 'Low quality' },
  { id: 101, name: 'Zoom' },
  { id: 102, name: 'Sex / nudity' },
  { id: 103, name: 'Underage person' },
  { id: 104, name: 'Violence / weapon' },
  { id: 105, name: 'No face' },
  { id: 106, name: 'Illicit activity' },
  { id: 107, name: 'Other' },
  { id: 108, name: 'Animation' },
  { id: 109, name: "Doesn't match user" },
  { id: 110, name: 'Too many people' },
  { id: 111, name: 'Face covered' },
  { id: 112, name: 'Selfie' },
  { id: 113, name: 'Text' },
  { id: 114, name: 'Too Similar' },
  { id: 115, name: 'Modified Image' },
  { id: 116, name: 'Old Photos' },
  { id: 117, name: 'Screenshot' },
]);

export const ALBUMS_ID = Object.freeze({
  PROFILE: 1,
  RECENT: 2,
  INSTAGRAM: 3,
  SELFIE_VERIFICATION: 4,
});

export const LocationType = Object.freeze({
  signUp: 'signUp',
  home: 'home',
});

export default statusEnum;
