import { CFormGroup, CLabel, CTextarea, CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BirthDayFormatter } from '../models/profile';
import * as errorModalActions from '../store/actions/errorModalActions';
import { ProfileActionButton } from './ProfileActionButton';
import * as profileActions from '../store/actions/profileActions';
import moment from 'moment';

function isValidBirthDate(d) {
  const diff = moment(new Date()).year() - moment(d).year();
  // const years = diff.duration().years()
  if (diff < 18) {
    return 'User age must be greater than 18 years.';
  }
  if (diff > 110) {
    return 'User age cannot be greater than 110 years.';
  }
}

function formatDateForInput(s) {
  let result = s;
  const a = s.split('T');
  if (a.length > 1) {
    result = a[0];
  }
  return result;
}

const ProfileBirthDate = props => {
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState(props.value);
  const previousValue = props.value;

  if (saving) {
    return <CSpinner color="info" style={{ marginLeft: '50%' }} />;
  }
  return (
    <CFormGroup>
      {!editing && (
        <div>
          <CLabel>{props.label}</CLabel>
          <CTextarea
            className="bg-white"
            id={props.id}
            value={BirthDayFormatter(value)}
            disabled
            style={{ textOverflow: 'ellipsis' }}
          />
          <ProfileActionButton
            label="Edit"
            buttonStyle={{ float: 'right', marginTop: '4px' }}
            onClick={() => setEditing(true)}
          />
        </div>
      )}
      {editing && (
        <div>
          <div>
            <input
              type="date"
              value={formatDateForInput(value)}
              style={{ width: '100%' }}
              onChange={e => {
                if (!e.target.value) {
                  // cancelled
                  return;
                }
                setValue(e.target.value);
                const invalidReason = isValidBirthDate(new Date(e.target.value));
                if (invalidReason) {
                  setError(`Invalid birthday: ${invalidReason}`);
                  return;
                }
                // at this point we have a valid value
                setError(false);
              }}
            ></input>
            <br />
            {error && (
              <p style={{ color: 'red', fontSize: '0.8em', marginBottom: '4px' }}>{error}</p>
            )}
            {!error && (
              <ProfileActionButton
                label="Save"
                type="save"
                buttonStyle={{ marginRight: '4px' }}
                onClick={async e => {
                  try {
                    setSaving(true);
                    await dispatch(profileActions.editBirthDate(props.userId, value));
                    setEditing(false);
                    setSaving(false);
                  } catch (err) {
                    await dispatch(errorModalActions.showModal(err.message));
                  }
                }}
              />
            )}

            <ProfileActionButton
              label="Cancel"
              type="cancel"
              onClick={async e => {
                try {
                  setValue(previousValue);
                  setEditing(false);
                } catch (err) {
                  await dispatch(errorModalActions.showModal(err.message));
                }
              }}
            />
          </div>
        </div>
      )}
    </CFormGroup>
  );
};

ProfileBirthDate.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ProfileBirthDate;
