import { SHOW_MODAL, HIDE_MODAL } from '../actions/errorModalActions';

const initialState = {
  show: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        ...{ show: action.show, errorMessage: action.errorMessage },
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
