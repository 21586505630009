import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { CContainer } from '@coreui/react';
import * as authActions from '../store/actions/authActions';
import * as photoModerationActions from '../store/actions/photoModerationActions';
import * as errorModalActions from '../store/actions/errorModalActions';
import PhotoQueuesView from '../views/Moderation/Photos/PhotoQueuesView';
import FlagQueueView from '../views/Moderation/Flags/FlagQueueView';
import UserListView from '../views/Users/UserListView';
import { RoomsView } from '../views/rooms/RoomsView';
import { RoomView } from '../views/rooms/RoomView';
import TextQueueView from '../views/Moderation/Text/TextQueueView';
import UserProfileView from '../views/Users/UserProfileView';
import BatchSuggestions from '../views/batch/BatchSuggestions';
import { RoomsImport } from '../views/rooms/RoomsImport';
import { RoomTypesView } from '../views/roomTypes/RoomTypesView';
import { RoomTypeView } from '../views/roomTypes/RoomTypeView';
import { PreferenceOption } from '../views/roomTypes/PreferenceOption';

const Content = () => {
  const dispatch = useDispatch();

  const errorHandler = async () => {
    try {
      await dispatch(authActions.checkAuthentication());
      await dispatch(photoModerationActions.getImages());
    } catch (err) {
      await dispatch(photoModerationActions.switchFetchingStatus());
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  useEffect(() => {
    errorHandler();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <main className="c-main bg-light">
        <CContainer fluid>
          <Switch>
            <Route
              exact
              path="/main/users"
              name="Users"
              render={props => <UserListView {...props} />}
            />
            <Route
              exact
              path="/main/photo-queue"
              name="Photo Queue"
              render={props => <UserListView {...props} isPhotoQueue />}
            />
            <Route
              exact
              path="/main/photos"
              name="Photos"
              render={props => <PhotoQueuesView {...props} />}
            />
            <Route
              exact
              path="/main/text"
              name="Text"
              render={props => <TextQueueView {...props} />}
            />
            <Route
              exact
              path="/main/flags"
              name="Flags"
              render={props => <FlagQueueView {...props} />}
            />
            <Route
              exact
              path="/main/profile/:userId"
              name="User Profile"
              render={props => <UserProfileView {...props} />}
            />
            <Route
              exact
              path="/main/batchManualSuggestions"
              name="batchManualSuggestions"
              render={props => <BatchSuggestions {...props} />}
            />

            <Route
              exact
              path="/main/rooms"
              name="Rooms"
              render={props => <RoomsView {...props} />}
            />
            <Route
              exact
              path="/main/rooms-import"
              name="Rooms"
              render={props => <RoomsImport {...props} />}
            />
            <Route
              exact
              path="/main/rooms/:roomId"
              name="Room"
              render={props => <RoomView {...props} />}
            />

            <Route
              exact
              path="/main/roomTypes"
              name="Rooms"
              render={props => <RoomTypesView {...props} />}
            />
            <Route
              exact
              path="/main/roomTypes/:roomTypeId"
              name="Room"
              render={props => <RoomTypeView {...props} />}
            />
            <Route
              exact
              path="/main/preferenceOptions/:preferenceOptionId"
              name="Room"
              render={props => <PreferenceOption {...props} />}
            />

            <Redirect from="/main" to="/main/users" />
          </Switch>
        </CContainer>
      </main>
    </>
  );
};

export default Content;
