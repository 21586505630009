import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import { PreferenceOptionSelect } from './PreferenceOptionSelect';

/**
 * props.selection []
 * props.onChange(preferenceOption data)
 */
export const AddPreferenceOption = props => {
  const [visible, setVisible] = useState(false);
  let data = [...props.selection];

  function onAdd() {
    props.onChange(data);
    setVisible(false);
  }

  function onChange(d) {
    data = d;
  }

  return (
    <>
      <CButton color="primary" size="sm" onClick={() => setVisible(!visible)}>
        Change options
      </CButton>
      <CModal show={visible} onClose={() => setVisible(false)} aria-labelledby="AddRoomType">
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">Change options</CModalTitle>
        </CModalHeader>

        <CModalBody>
          <PreferenceOptionSelect selection={props.selection} onChange={onChange} />
        </CModalBody>

        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={onAdd}>
            Save
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
