import React from 'react';

/**
 * props.roomType
 */
export const RoomTypePoEditor = props => {
  const roomType = { ...props.roomType };
  const options = roomType.schema.fields
    .filter(f => f.type === 'list')
    .map(f => f.options)
    .flat()
    .map(o => o.id)
    .filter((f, i, a) => a.indexOf(f) === i);

  console.log('roomType', roomType, options);
  return (
    <div>
      <p>
        These are PoEditor keys you can use to customize this room type translations dynamically:
      </p>

      <code>
        # option title - used in lists, filters, inbox & view-profile
        {options.map(o => (
          <div>app.darkRoom.preferences.option.{o}.title</div>
        ))}
        <br /># option description - used in second lists
        {options.map(o => (
          <div>app.darkRoom.preferences.option.{o}.description</div>
        ))}
        <br /># field titles
        {roomType.schema.fields.map((f, i) => (
          <div>
            app.darkRoom.onboarding.{roomType.id}.step.{i + 1}.title
          </div>
        ))}
      </code>
    </div>
  );
};
