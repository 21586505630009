import store from '../store/Store';
import { defaultUsersState, getUsers } from '../store/actions/usersActions';
import { parseUrl } from './urlUtil';
import { Deferred } from './utils';

/*
implements search url <-> state mapping. Example store state:

state = {
  users: {
    city: "",
    country: "",
    currentPage: 0
    homeCity: "",
    homeCountry: "",
    homeState: "",
    limit: 25
    moreUsersLeft: false
    orderBy: "created"
    orderDirection: "desc"
    referred: "",
    search: "seba"
    state: "",
    status: "",
    total: 0
  }
}

example url:
http://localhost:3001/main/users?moreUsersLeft=false&currentPage=0&status=1%2C3&country=&state=&city=&homeCountry=&homeState=&homeCity=&search=user&referred=&orderBy=&orderDirection=&total=7&limit=25

ISSUES:
 * timeout on dispatchUrl is because we need to dispatch before first response

FIXED: 

 * extra http request (before 2 and now 3)
 * multiple dropdowns like signup and home locations breaks. if you only set country loading url will break states dropdown.
 * dropdown options are incorrectly selected (the first one like homeLocation.byCountry). If I click first one nothing happens but it should.
 * search params keeps present when navigating to user's profile. This is good since they are remembered when user go-back-to-listing from user details.
 * pagination not showing selected page
 * UserListView loading breaks
 * users loading and modal error no longer work. 
*/

export let urlDispatched = new Deferred();

/** meant to be called when app starts, parses url and call getUsers() redux action */
export async function dispatchUrl(url = window.location.href) {
  if (urlDispatched.status === 'resolved') {
    return;
  }
  const parsed = urlToState(url) || defaultUsersState;
  if (!parsed) {
    return;
  }
  const fn = getUsers(parsed.users);
  const dispatch = store.dispatch.bind(store),
    getState = store.getState.bind(store);
  try {
    await fn(dispatch, getState);
    urlDispatched.resolve(true);
  } catch (error) {
    alert(`${error}`);
  }
}

/** meant to be called on store state changes. It will just update URL with new state params so users can share it */
export function setUrlFromState(state = store.getState()) {
  const url = new URL(window.location.href);
  // remove search query when navigating to other pages
  if (!['/main/users', '/main/photo-queue'].includes(url.pathname)) {
    url.search = '';
  } else {
    url.search = stateToUrl(state);
  }
  window.history.replaceState('', '', url.toString());
}

const propsToIgnore = ['users', 'moreUsersLeft', 'total'];

function stateToUrl(state = store.getState()) {
  const obj = { ...state.users };
  propsToIgnore.forEach(p => {
    delete obj[p];
  });
  Object.keys(obj).forEach(k => {
    const value = obj[k];
    if (typeof value === 'undefined' || value === null) {
      delete obj[k];
    }
  });
  const queryString = new URLSearchParams(obj).toString();
  return queryString;
}

function urlToState(url = window.location.href) {
  const parsed = parseUrl(url).params;
  const keys = Object.keys(parsed);
  keys.forEach(k => {
    parsed[k] = decodeURIComponent(parsed[k]);
    // fix URLSearchParams behavior that translate spaces to '+'
    parsed[k] = parsed[k].replace(/[+]/g, ' ');
  });
  if (!keys.length) {
    return null;
  }
  parsed.page = parseInt(parsed.currentPage) || 0;
  delete parsed.currentPage;

  delete parsed.total;

  if (parsed.status) {
    parsed.status = parsed.status.split(',').map(i => parseInt(i));
  }
  if (parsed.referred) {
    parsed.referred = parsed.referred.split(',');
  }
  if (parsed.activePlaces) {
    parsed.activePlaces = parsed.activePlaces.split(',');
  }

  return {
    users: parsed,
  };
}
