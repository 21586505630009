import { userIdPattern } from '../../constants/regex';
import ApiService from '../../services/ApiService';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';
import ForbiddenError from '../../exceptions/ForbiddenError';

export const sendReactionRequest = receiverId => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState().profile;
      let response;

      if (!receiverId || !receiverId.match(userIdPattern) || profile.userId === receiverId) {
        return false;
      }

      const toastId = toast.loading('Pending...', toastDefaultConfig);

      try {
        response = await ApiService.post(
          `requests/`,
          {
            senderId: profile.userId,
            receiverId,
          },
          { withCredentials: true }
        );
      } catch (err) {
        toast.dismiss(toastId);
        throw err;
      }

      if (response.status === 204) {
        toast.update(toastId, {
          ...toastDefaultConfig,
          render: 'There is already a conversation',
          type: 'warning',
          isLoading: false,
        });
      } else {
        toast.update(toastId, {
          ...toastDefaultConfig,
          render: 'Sent!',
          type: 'success',
          isLoading: false,
        });
      }
      return response.data?.success;
    } catch (err) {
      if (err instanceof ForbiddenError) {
        toast.error('Access to this resource on the server is denied', toastDefaultConfig);
      } else {
        toast.error('Something went wrong');
      }
      console.log(err.response);
    }
  };
};
