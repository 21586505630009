import ApiService from './ApiService';
import { LocationType } from '../utils/statusEnum';

let userCountries = {};

export const getUserCountries = async (locationType = LocationType.signUp) => {
  if (!userCountries[locationType]) {
    const response = await ApiService.get(`location/countries?type=${locationType}`, {
      withCredentials: true,
    });
    userCountries[locationType] = response.data.countries;
  }
  return userCountries[locationType];
};

// We cache countries, states cities locally to prevent expensive calls
const countryStates = {
  [LocationType.signUp]: {},
  [LocationType.home]: {},
};

export const getUserStatesForCountry = async (locationType, country) => {
  if (!countryStates[locationType][country]) {
    countryStates[locationType][country] = {};
    (await fetchUserStatesForCountry(locationType, country)).forEach(state => {
      countryStates[locationType][country][state] = { name: state };
    });
  }
  return Object.keys(countryStates[locationType][country]);
};

export const getUserCitiesForCountryState = async (locationType, country, state) => {
  await getUserStatesForCountry(locationType, country); // make sure we already have country states
  if (!countryStates[locationType][country][state]) {
    throw new Error(`Invalid State ${state} for country ${country}`);
  }
  if (!countryStates[locationType][country][state].cities) {
    countryStates[locationType][country][state].cities = await fetchUserCitiesForCountryState(
      locationType,
      country,
      state
    );
  }
  return countryStates[locationType][country][state].cities;
};

const fetchUserStatesForCountry = async (locationType, country) => {
  const response = await ApiService.get(`location/states?type=${locationType}`, {
    params: { country },
    withCredentials: true,
  });
  return response.data.states;
};

const fetchUserCitiesForCountryState = async (locationType, country, state) => {
  const response = await ApiService.get(`location/cities?type=${locationType}`, {
    params: { country, state },
    withCredentials: true,
  });
  return response.data.cities;
};

let activePlaces;
export async function getActivePlaces() {
  if (!activePlaces) {
    const response = await ApiService.get(`activePlaces`, { params: {}, withCredentials: true });
    activePlaces = response.data.activePlaces;
  }
  return activePlaces;
}
