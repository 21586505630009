import React from 'react';
import Header from './Header';
import Sidebar from './SideBar';
import Content from './ContentContainer';
import { ToastContainer } from 'react-toastify';

const MainContainer = () => {
  return (
    <div className="c-app c-default-layout">
      <ToastContainer />
      <Sidebar />
      <div className="c-wrapper">
        <Header />
        <div className="c-body">
          <Content />
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
