import { CButton, CCard, CCardBody } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ProfileActionButton } from '../../components/ProfileActionButton';
import {
  PROFILE_BASE_URL_BY_SOCIAL_MEDIA,
  SUPPORTED_SOCIAL_MEDIA,
} from '../../constants/ExternalUrls';
import ProfileSpoofLocation from '../ProfileSpoofLocation';
import ProfileReferralInfo from '../ProfileReferralInfo';
import UserFeatures from '../UserFeatures';
import {
  CAvatar,
  ClearBadgeContainer,
  InfoPanel,
  InfoPanelBigScreen,
  ProfileImagesTitle,
} from '../../styles/views/UserProfileView.module.css';
import PanelImageComponent from '../PanelImageComponent';
import LocationPin from '../LocationPin';
import { useSelector } from 'react-redux';
import { ProfileGenerateRequest } from '../ProfileGenerateRequest';
import config from '../../configs/Config';

const UserProfileInfoPanel = props => {
  const isBigScreen = useMediaQuery({ query: '(min-width: 1921px)' });

  const referralInfo = useSelector(state => state.profile.referralInfo);

  const getHyperlink = section => {
    if (!PROFILE_BASE_URL_BY_SOCIAL_MEDIA[section]) {
      return <p>{props.profile[section]}</p>;
    }
    return (
      <a
        href={`${PROFILE_BASE_URL_BY_SOCIAL_MEDIA[section]}${props.profile[section]}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {props.profile[section]}
      </a>
    );
  };

  const renderCleanableSection = (section, param, title) => {
    return (
      <>
        <h6 style={{ textTransform: 'capitalize' }}>{title || section}</h6>
        <div className={[ClearBadgeContainer, 'mb-3'].join(' ')}>
          {param}
          <ProfileActionButton
            label="Clear"
            onClick={() => props.openClearModal(section, props.profile.userId)}
          />
        </div>
      </>
    );
  };

  const renderSocialNetworks = () => {
    return Object.keys(SUPPORTED_SOCIAL_MEDIA).map(socialMedia => {
      return (
        <div key={socialMedia}>
          {renderCleanableSection(socialMedia, getHyperlink(socialMedia))}
        </div>
      );
    });
  };

  return (
    <>
      <CCard id={InfoPanel} className={isBigScreen ? InfoPanelBigScreen : ''}>
        <CCardBody className="m-0">
          <span className={ProfileImagesTitle}>Verification Selfie</span>
          <div className={CAvatar}>
            <PanelImageComponent
              key={props.profile.selfieVerification?.id}
              photo={props.profile?.selfieVerification}
              selfieVerification={props.profile?.selfieVerification}
              onRejectPhoto={props.onRejectPhoto}
              onApprovePhoto={props.onApprovePhoto}
            />
          </div>

          <div>
            <h4 className="mb-3">User Info</h4>
            {renderCleanableSection('name', props.profile.name, 'User Name')}
            <div className="mb-3">
              <h6>Email Address</h6>
              <p>{props.profile.email}</p>
            </div>
            <div className="mb-3">
              <h6>User Status</h6>
              <p>{props.profile.status}</p>
            </div>
            <div className="mb-3">
              <h6>
                <LocationPin location={props.profile.signUpLocation} /> Signup Location
              </h6>
              <p>
                {props.profile.signUpLocation
                  ? printLocation(
                      props.profile.signUpCountry,
                      props.profile.signUpState,
                      props.profile.signUpCity
                    )
                  : 'Unknown'}
              </p>
            </div>
            <div className="mb-3">
              <h6>
                <LocationPin location={props.profile.homeLocation} /> Home Location
              </h6>
              <p>
                {props.profile.homeLocation
                  ? printLocation(
                      props.profile.homeCountry,
                      props.profile.homeState,
                      props.profile.homeCity,
                      props.profile.homeNeighborhood,
                      props.profile.homePostalCode
                    )
                  : 'Unknown'}
              </p>
            </div>
          </div>

          <hr />

          <div>
            <h4 className="mb-3">Social Accounts</h4>
            {renderSocialNetworks()}
          </div>

          <hr />

          <ProfileSpoofLocation
            userId={props.profile.userId}
            location={props.profile.location}
            forceLocation={props.profile.forceLocation}
          />

          <hr />

          <ProfileReferralInfo userId={props.profile.userId} referralInfo={referralInfo} />

          <hr />

          <UserFeatures userId={props.profile.userId} features={props.profile.features || []} />

          <hr />

          {config.environment !== 'prod' && (
            <>
              <ProfileGenerateRequest />
              <hr />
            </>
          )}

          <div className="d-flex justify-content-center m-0">
            <CButton
              className="flex-grow-1"
              color={props.profile.isBanned ? 'info' : 'danger'}
              size="lg"
              onClick={() => {
                props.openBanUnbanModal(props.profile.userId, props.profile.isBanned);
              }}
            >
              {props.profile.isBanned ? 'Unban' : 'Ban'}
            </CButton>
          </div>

          <div className="d-flex justify-content-center m-0">
            <CButton
              className="flex-grow-1"
              style={{ marginTop: '20px' }}
              color="danger"
              size="lg"
              onClick={() => {
                props.openDeleteUserModal(props.profile.userId);
              }}
            >
              Delete user
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </>
  );
};

function printLocation(...args) {
  return args.map(s => s || '').join(', ');
}

UserProfileInfoPanel.propTypes = {
  profile: PropTypes.object.isRequired,
  openBanUnbanModal: PropTypes.func.isRequired,
  openDeleteUserModal: PropTypes.func.isRequired,
  openClearModal: PropTypes.func.isRequired,
};

export default UserProfileInfoPanel;
