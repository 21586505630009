import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as errorModalActions from '../../store/actions/errorModalActions';
import { RoomTypeField } from '../rooms/RoomTypes';
import { ProfileTagFieldIds } from '../../models/profileTagFieldIds';

export const CreateRoomTypeField = ({ onCreated }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onCreate = async () => {
    setLoading(true);
    try {
      const rt = { type, name, description };
      if (type === RoomTypeField.tag) {
        rt.profileFieldId = ProfileTagFieldIds.sexualInterests;
      }
      onCreated && onCreated(rt);
    } catch (err) {
      console.error('err', err);
      dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
    setVisible(false);
    setName('');
    setDescription('');
    setType('');
  };

  return (
    <>
      {loading && <CSpinner />}

      <CButton color="primary" style={{ marginBottom: 10 }} onClick={() => setVisible(!visible)}>
        Add Field
      </CButton>
      <CModal show={visible} onClose={() => setVisible(false)} aria-labelledby="AddRoomType">
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">New field</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="mb-3">
            <label for="type" className="form-label">
              Field Type
            </label>
            <select
              className="form-select form-control"
              aria-label="Default select example"
              id="type"
              onChange={e => {
                setType(e.target.value);
              }}
            >
              <option selected={!type} value={0}>
                Select one
              </option>
              {Object.values(RoomTypeField).map(f => (
                <option selected={type === f}>{f}</option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label for="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="new field"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label for="description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              placeholder="new field description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={onCreate} disabled={!name || !type}>
            Create
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
