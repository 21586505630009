import ImageModeration from './ImageModeration';

class ProfileModeration {
  constructor(profile, newStatusId) {
    this.userId = profile.userId;
    this.status = newStatusId;
    this.images = formatImages(profile);
  }
}

const formatImages = profile => {
  const photos = profile.photos.map(photo => new ImageModeration(photo));
  photos.push(new ImageModeration(profile.selfieVerification));
  return photos;
};

export default ProfileModeration;
