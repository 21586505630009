import React from 'react';
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import PropTypes from 'prop-types';
import {
  ModalStyle,
  ModalFooter,
} from '../styles/components/ConfirmRequestGenerationPopup.module.css';

export const ConfirmRequestGenerationPopup = ({
  onConfirm,
  receiverId,
  modalVisibility,
  toggleModalVisibility,
}) => {
  return (
    <>
      <CModal
        centered
        onClose={toggleModalVisibility}
        show={modalVisibility}
        size="sm"
        addContentClass={ModalStyle}
      >
        <CModalBody>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="font-weight-bold text-center">
              Are you sure you want to send a request to {receiverId}?
            </div>
          </div>
        </CModalBody>
        <CModalFooter className={ModalFooter}>
          <CButton onClick={() => [toggleModalVisibility(), onConfirm()]} color={'info'}>
            Yes
          </CButton>
          <CButton onClick={toggleModalVisibility} color="secondary">
            No
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

ConfirmRequestGenerationPopup.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ConfirmRequestGenerationPopup;
