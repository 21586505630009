import React from 'react';
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import PropTypes from 'prop-types';
import { CloseFlagsModal, CloseFlagsFooter } from '../styles/components/CloseFlagsPopUp.module.css';

const CloseFlagsPopUp = props => {
  return (
    <>
      <CModal
        centered
        onClose={props.closeCloseFlagsModal}
        show={props.isCloseFlagsModalOpen}
        size="sm"
        addContentClass={CloseFlagsModal}
      >
        <CModalBody>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="font-weight-bold text-center">
              Are you sure you want to close all flags?
            </div>
          </div>
        </CModalBody>
        <CModalFooter className={CloseFlagsFooter}>
          <CButton onClick={props.onCloseFlags} color="info">
            Yes
          </CButton>
          <CButton onClick={props.closeCloseFlagsModal} variant="outline" color="secondary">
            No
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

CloseFlagsPopUp.propTypes = {
  isCloseFlagsModalOpen: PropTypes.bool.isRequired,
  closeCloseFlagsModal: PropTypes.func.isRequired,
  onCloseFlags: PropTypes.func.isRequired,
};

export default CloseFlagsPopUp;
