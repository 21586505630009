import { CCard, CCardBody, CCardHeader, CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Profile from '../../models/profile';
import { Card } from '../../styles/views/UserProfileView.module.css';
import { getUserBatchSuggestions } from '../../services/batchSuggestionsService';
import * as moment from 'moment';

export const UserBatchSuggestions = props => {
  const { profile } = props;
  const [list, setList] = useState(null);
  const baseButton = { maxWidth: '200px' };

  return (
    <CCard className={Card}>
      <CCardHeader className="d-flex justify-content-between align-items-center border-0">
        <h3 className="m-0">Batch Suggestions</h3>
      </CCardHeader>
      <CCardBody className="pt-0">
        <CButton
          color="primary"
          style={{ ...baseButton }}
          onClick={async () => {
            const result = await getUserBatchSuggestions(profile.userId);
            setList(result);
          }}
        >
          List all
        </CButton>

        {list && (
          <>
            <h4 style={{ marginTop: '20px' }}>Records ({list.length})</h4>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Record id</th>
                  <th scope="col">suggestedUserId</th>
                  <th scope="col">reason</th>
                  <th scope="col">suggestionNote</th>
                  <th scope="col">expirationDate</th>
                </tr>
              </thead>
              <tbody>
                {list.map(record => (
                  <tr>
                    <th scope="row">{record.id}</th>
                    <td>
                      <UserLink userId={record.suggestedUserId} />
                    </td>
                    <td>{record.reason}</td>
                    <td>{record.suggestionNote}</td>
                    <td>{moment(record.expirationDate).format()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </CCardBody>
    </CCard>
  );
};

const UserLink = ({ userId, label }) => {
  return (
    <a href={`/main/profile/${userId}`} target="_blank">
      {label || userId}
    </a>
  );
};

UserBatchSuggestions.propTypes = {
  profile: PropTypes.instanceOf(Profile).isRequired,
};
