import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Brand } from '../styles/containers/SideBar.module.css';

// sidebar nav config
import navigation from './_nav';

import * as authActions from '../store/actions/authActions';

const Sidebar = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(state => state.auth.sidebarShow);

  return (
    <CSidebar size="sm" colorScheme="light" minimize={sidebarShow.minimized} show="responsive">
      <CSidebarBrand
        style={{
          color: '#ACACAC',
          backgroundColor: '#FFFFFF',
        }}
        className={Brand}
      >
        <CIcon
          height={15}
          name="cil-menu"
          style={{ position: 'absolute', right: 18, cursor: 'pointer' }}
          onClick={() => dispatch(authActions.toggleSideBar())}
        ></CIcon>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
    </CSidebar>
  );
};

export default Sidebar;
