import ApiService from '../../services/ApiService';
import { ethnicityFieldIds, ProfileTagFieldIds } from '../../models/profileTagFieldIds';

export const GET_ETHNICITY_TAGS = 'GET_ETHNICITY_TAGS';
export const GET_ALL_TAGS = 'GET_ALL_TAGS';

export const getAllTags = () => {
  return async dispatch => {
    const response = await ApiService.get(`tags`, { withCredentials: true });
    if (!response.data?.tags) {
      return null;
    }
    return dispatch({
      type: GET_ALL_TAGS,
      tags: response.data.tags,
    });
  };
};

export const getAllEthnicityTags = profileFieldId => {
  return async dispatch => {
    try {
      if (!Object.values(ethnicityFieldIds).includes(profileFieldId)) {
        return null;
      }
      const response = await ApiService.get(`tags/${ProfileTagFieldIds.ethnicityAdmin}`, {
        withCredentials: true,
      });

      if (!response.data?.tags) {
        return null;
      }

      return dispatch({
        type: GET_ETHNICITY_TAGS,
        payload: response.data.tags,
        profileFieldId,
      });
    } catch (err) {
      console.log(err);
    }
  };
};
