import React, { useEffect, useState } from 'react';
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInput,
  CPagination,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CButton,
  CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
  SearchIcon,
  SearchInput,
  SectionTitle,
} from '../styles/components/FilterComponent.module.css';
import PropTypes from 'prop-types';
import statusEnum, { LocationType, referredOptions } from '../utils/statusEnum';
import { useSelector } from 'react-redux';
import {
  getUserCountries,
  getUserStatesForCountry,
  getUserCitiesForCountryState,
  getActivePlaces,
} from '../services/locationService';
import { debounce } from 'lodash';

// see updateStateAndCityDropdowns below
let done = false;

const FilterComponent = props => {
  const [signUpCountries, setSignUpCountries] = useState([]);
  const [signUpStates, setSignUpStates] = useState([]);
  const [signUpCities, setSignUpCities] = useState([]);

  const [homeCountries, setHomeCountries] = useState([]);
  const [homeStates, setHomeStates] = useState([]);
  const [homeCities, setHomeCities] = useState([]);
  const [activePlaces, setActivePlaces] = useState([]);

  useEffect(() => {
    (async () => {
      setSignUpCountries(await getUserCountries(LocationType.signUp));
      setHomeCountries(await getUserCountries(LocationType.home));
      setActivePlaces(await getActivePlaces());
    })();
  }, []);

  const onSearchChange = debounce(async filterKey => {
    const lastChar = filterKey.substr(filterKey.length - 1);
    if (lastChar !== ' ') {
      await props.onSearchChange(filterKey.trim());
    }
  }, 1000);

  const onStatusChange = async ({ target }) => {
    const selection = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    const value = selection.includes('all') ? null : selection.filter(v => !isNaN(parseInt(v)));
    await props.onStatusChange(value);
  };

  const onReferredChange = async ({ target }) => {
    const selection = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    const value =
      selection.includes('all') || selection.length !== 1
        ? null
        : selection.filter(v => v !== 'all');
    await props.onReferredChange(value);
  };

  const onActivePlaceChange = async ({ target }) => {
    const selection = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    const value = selection.includes('all') ? null : selection.filter(v => v);
    await props.onActivePlaceChange(value);
  };

  const signUpCountryFilter = useSelector(state => state.users.country);
  const signUpStateFilter = useSelector(state => state.users.state);
  const signUpCityFilter = useSelector(state => state.users.city);

  const homeCountryFilter = useSelector(state => state.users.homeCountry);
  const homeStateFilter = useSelector(state => state.users.homeState);
  const homeCityFilter = useSelector(state => state.users.homeCity);

  const referredFilter = useSelector(state => state.users.referred);

  const statusFilter = (useSelector(state => state.users.status) || []).map(f => `${f}`);

  /** this is a hack so we can fetch states and city for location dropdowns when they are given by url */
  const updateStateAndCityDropdowns = async () => {
    if (signUpCountryFilter) {
      done = true;
      setSignUpStates(await getUserStatesForCountry(LocationType.signUp, signUpCountryFilter));
    }
    if (signUpStateFilter) {
      done = true;
      setSignUpCities(
        await getUserCitiesForCountryState(
          LocationType.signUp,
          signUpCountryFilter,
          signUpStateFilter
        )
      );
    }
    if (homeCountryFilter) {
      done = true;
      setHomeStates(await getUserStatesForCountry(LocationType.home, homeCountryFilter));
    }
    if (homeStateFilter) {
      done = true;
      setHomeCities(
        await getUserCitiesForCountryState(LocationType.home, homeCountryFilter, homeStateFilter)
      );
    }
  };
  if (!done) {
    updateStateAndCityDropdowns();
  }
  const activePlacesFilter = useSelector(state => state.users.activePlaces);
  console.log({ activePlacesFilter });
  return (
    <CCol md="12">
      <CCard className="mb-0 border-bottom-0">
        <CCardHeader className="ml-3 border-bottom-0">
          <CRow style={{ height: '35px' }}>
            <CCol className={'col-11'}>
              <div className={SectionTitle}>{props.isPhotoQueue ? 'Photo Queue' : 'User List'}</div>
            </CCol>
            <CCol className={'col-1'}>{props.loading ? <CSpinner color="info" /> : null}</CCol>
          </CRow>
          <CRow className="mb-2" style={{ justifyContent: 'space-between' }}>
            <CRow>
              {/* Search */}
              <div className="form-group" style={{ marginLeft: '10px' }}>
                <div
                  style={{
                    ...(useSelector(state => state.users.search)
                      ? { color: 'rgb(198, 107, 50)' }
                      : {}),
                  }}
                >
                  <CIcon name="cil-filter" />
                  <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Search by name</label>
                </div>
                <CInputGroup className="w-auto">
                  <CInputGroupPrepend>
                    <CInputGroupText className={SearchIcon}>
                      <CIcon name="cil-magnifying-glass" />
                    </CInputGroupText>
                  </CInputGroupPrepend>
                  <CInput
                    id="search"
                    className={SearchInput}
                    placeholder="Search"
                    defaultValue={useSelector(state => state.users.search)}
                    onChange={event => {
                      onSearchChange(event.target.value);
                    }}
                  />
                </CInputGroup>
                {useSelector(state => state.users.search) && (
                  <CButton
                    style={{ padding: 0, marginBottom: '32px' }}
                    onClick={e => {
                      const input = document.querySelector('#search');
                      input.value = '';
                      onSearchChange('');
                    }}
                  >
                    <CIcon name="cil-x"></CIcon>
                    clear
                  </CButton>
                )}
              </div>

              {/* Status */}
              <div className="form-group" style={{ marginLeft: '16px' }}>
                <div style={{ ...(statusFilter.length ? { color: 'rgb(198, 107, 50)' } : {}) }}>
                  <CIcon name="cil-filter" />
                  <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Status</label>
                </div>
                <CDropdown title="Filter by status (multiple)">
                  <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                    {useSelector(state => (state.users.status ? 'Filtered Status' : 'All Status'))}
                  </CDropdownToggle>
                  <CDropdownMenu style={{ padding: 0 }}>
                    <select
                      aria-label="Status"
                      id="status-select"
                      multiple
                      defaultValue={['all']}
                      size={Object.keys(statusEnum).length + 1}
                      onChange={onStatusChange}
                      style={{ width: '100%' }}
                    >
                      <option value="all" selected={!statusFilter.length}>
                        All Status
                      </option>
                      {Object.keys(statusEnum).map(id => (
                        <option key={id} value={id} selected={statusFilter.includes(id)}>
                          {statusEnum[id]}
                        </option>
                      ))}
                    </select>
                  </CDropdownMenu>
                </CDropdown>
                {useSelector(state => state.users.status) && (
                  <CButton
                    style={{ padding: 0, marginBottom: '32px' }}
                    onClick={e => {
                      const select = document.querySelector('#status-select');
                      Array.from(select.options).forEach((option, i) => {
                        option.selected = i === 0 ? true : false;
                      });
                      onStatusChange({ target: select });
                    }}
                  >
                    <CIcon name="cil-x"></CIcon>
                    clear
                  </CButton>
                )}
              </div>

              {/* Sign up location  - TODO: move to another file */}

              <div className="form-group" style={{ marginLeft: '16px' }}>
                <div style={{ ...(signUpCountryFilter ? { color: 'rgb(198, 107, 50)' } : {}) }}>
                  <CIcon name="cil-filter" />
                  <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Sign up location</label>
                </div>
                {/* Sign up Country */}
                <CDropdown title="Filter by country">
                  <CDropdownToggle
                    style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}
                  >
                    {signUpCountryFilter || 'All countries'}
                  </CDropdownToggle>
                  <CDropdownMenu style={{ padding: 0 }}>
                    <select
                      aria-label="Country"
                      id="country-select"
                      defaultValue={signUpCountryFilter || 'all'}
                      size={Math.min(10, (signUpCountries || []).length + 2)}
                      onChange={async ({ target }) => {
                        const country = target.value;
                        if (country === 'all') {
                          props.onLocationChange({ country: null, state: null, city: null });
                        } else {
                          setSignUpStates(
                            await getUserStatesForCountry(LocationType.signUp, country)
                          );
                          props.onLocationChange({ country, state: null, city: null });
                        }
                        target.closest('.dropdown-menu').classList.remove('show');
                      }}
                      style={{ width: '100%' }}
                    >
                      <option value="all" selected={!signUpCountryFilter}>
                        All Countries
                      </option>
                      <option value="Unknown" selected={'Unknown' === signUpCountryFilter}>
                        Unknown
                      </option>
                      {signUpCountries.map(id => (
                        <option key={id} value={id} selected={id === signUpCountryFilter}>
                          {id}
                        </option>
                      ))}
                    </select>
                  </CDropdownMenu>
                </CDropdown>
                {/* Sign up State */}
                {signUpCountryFilter && signUpCountryFilter !== 'Unknown' && (
                  <CDropdown title="Filter by State" style={{ marginTop: '4px' }}>
                    <CDropdownToggle
                      style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}
                    >
                      {signUpStateFilter || 'All States'}
                    </CDropdownToggle>
                    <CDropdownMenu style={{ padding: 0 }}>
                      <select
                        aria-label="state"
                        id="state-select"
                        defaultValue={signUpStateFilter || 'all'}
                        size={signUpStates.length + 1}
                        onChange={async ({ target }) => {
                          const state = target.value;
                          if (state === 'all') {
                            props.onLocationChange({
                              country: signUpCountryFilter,
                              state: null,
                              city: null,
                            });
                          } else {
                            setSignUpCities(
                              await getUserCitiesForCountryState(
                                LocationType.signUp,
                                signUpCountryFilter,
                                state
                              )
                            );
                            props.onLocationChange({ state: target.value, city: null });
                          }
                          target.closest('.dropdown-menu').classList.remove('show');
                        }}
                        style={{ width: '100%' }}
                      >
                        <option value="all" selected={!signUpStateFilter}>
                          All States
                        </option>
                        {signUpStates.map(id => (
                          <option key={id} value={id} selected={id === signUpStateFilter}>
                            {id}
                          </option>
                        ))}
                      </select>
                    </CDropdownMenu>
                  </CDropdown>
                )}
                {/* Sign up City */}
                {signUpStateFilter && (
                  <CDropdown title="Filter by City" style={{ marginTop: '4px' }}>
                    <CDropdownToggle
                      style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}
                    >
                      {signUpCityFilter || 'All Cities'}
                    </CDropdownToggle>
                    <CDropdownMenu style={{ padding: 0 }}>
                      <select
                        aria-label="city"
                        id="city-select"
                        defaultValue={signUpCityFilter || 'all'}
                        size={signUpCities.length + 1}
                        onChange={({ target }) => {
                          const city = target.value;
                          props.onLocationChange({ city: city === 'all' ? null : city });
                          target.closest('.dropdown-menu').classList.remove('show');
                        }}
                        style={{ width: '100%' }}
                      >
                        <option value="all" selected={!signUpCityFilter}>
                          All cities
                        </option>
                        {signUpCities.map(id => (
                          <option key={id} value={id} selected={id === signUpCityFilter}>
                            {id}
                          </option>
                        ))}
                      </select>
                    </CDropdownMenu>
                  </CDropdown>
                )}
                {signUpCountryFilter && (
                  <CButton
                    style={{ padding: 0, marginBottom: '32px' }}
                    onClick={e => {
                      props.onLocationChange({ country: null, state: null, city: null });
                    }}
                  >
                    <CIcon name="cil-x"></CIcon>
                    clear
                  </CButton>
                )}
              </div>

              {/* Home location - TODO: move to another file */}

              <div className="form-group" style={{ marginLeft: '16px' }}>
                <div style={{ ...(homeCountryFilter ? { color: 'rgb(198, 107, 50)' } : {}) }}>
                  <CIcon name="cil-filter" />
                  <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Home location</label>
                </div>
                {/* Home Country */}
                <CDropdown title="Filter by country">
                  <CDropdownToggle
                    style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}
                  >
                    {homeCountryFilter || 'All countries'}
                  </CDropdownToggle>
                  <CDropdownMenu style={{ padding: 0 }}>
                    <select
                      aria-label="Country"
                      id="country-select"
                      defaultValue={homeCountryFilter || 'all'}
                      size={Math.min(10, homeCountries.length + 2)}
                      onChange={async ({ target }) => {
                        const homeCountry = target.value;
                        if (homeCountry === 'all') {
                          props.onLocationChange({
                            homeCountry: null,
                            homeState: null,
                            homeCity: null,
                          });
                        } else {
                          setHomeStates(
                            await getUserStatesForCountry(LocationType.home, homeCountry)
                          );
                          props.onLocationChange({ homeCountry, homeState: null, homeCity: null });
                        }
                        target.closest('.dropdown-menu').classList.remove('show');
                      }}
                      style={{ width: '100%' }}
                    >
                      <option value="all" selected={!homeCountryFilter}>
                        All Countries
                      </option>
                      <option value="Unknown" selected={homeCountryFilter === 'Unknown'}>
                        Unknown
                      </option>
                      {homeCountries.map(id => (
                        <option key={id} value={id} selected={id === homeCountryFilter}>
                          {id}
                        </option>
                      ))}
                    </select>
                  </CDropdownMenu>
                </CDropdown>
                {/* Home State */}
                {homeCountryFilter && homeCountryFilter !== 'Unknown' && (
                  <CDropdown title="Filter by State" style={{ marginTop: '4px' }}>
                    <CDropdownToggle
                      style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}
                    >
                      {homeStateFilter || 'All States'}
                    </CDropdownToggle>
                    <CDropdownMenu style={{ padding: 0 }}>
                      <select
                        aria-label="state"
                        id="state-select"
                        defaultValue={homeStateFilter || 'all'}
                        size={homeStates.length + 1}
                        onChange={async ({ target }) => {
                          const state = target.value;
                          if (state === 'all') {
                            props.onLocationChange({
                              homeCountry: homeCountryFilter,
                              homeState: null,
                              homeCity: null,
                            });
                          } else {
                            setHomeCities(
                              await getUserCitiesForCountryState(
                                LocationType.home,
                                homeCountryFilter,
                                state
                              )
                            );
                            props.onLocationChange({ homeState: target.value, homeCity: null });
                          }
                          target.closest('.dropdown-menu').classList.remove('show');
                        }}
                        style={{ width: '100%' }}
                      >
                        <option value="all" selected={!homeStateFilter}>
                          All States
                        </option>
                        {homeStates.map(id => (
                          <option key={id} value={id} selected={id === homeStateFilter}>
                            {id}
                          </option>
                        ))}
                      </select>
                    </CDropdownMenu>
                  </CDropdown>
                )}
                {/* Home City */}
                {homeStateFilter && (
                  <CDropdown title="Filter by City" style={{ marginTop: '4px' }}>
                    <CDropdownToggle
                      style={{ border: '1px solid rgb(216, 219, 224)', width: '100%' }}
                    >
                      {homeCityFilter || 'All Cities'}
                    </CDropdownToggle>
                    <CDropdownMenu style={{ padding: 0 }}>
                      <select
                        aria-label="city"
                        id="city-select"
                        defaultValue={homeCityFilter || 'all'}
                        size={homeCities.length + 1}
                        onChange={({ target }) => {
                          const homeCity = target.value;
                          props.onLocationChange({ homeCity });
                          target.closest('.dropdown-menu').classList.remove('show');
                        }}
                        style={{ width: '100%' }}
                      >
                        <option value="all" selected={!homeCityFilter}>
                          All cities
                        </option>
                        {homeCities.map(id => (
                          <option key={id} value={id} selected={id === homeCityFilter}>
                            {id}
                          </option>
                        ))}
                      </select>
                    </CDropdownMenu>
                  </CDropdown>
                )}
                {homeCountryFilter && (
                  <CButton
                    style={{ padding: 0, marginBottom: '32px' }}
                    onClick={e => {
                      props.onLocationChange({
                        homeCountry: null,
                        homeState: null,
                        homeCity: null,
                      });
                    }}
                  >
                    <CIcon name="cil-x"></CIcon>
                    clear
                  </CButton>
                )}
              </div>

              {/* Referred */}
              <div
                className="form-group"
                style={{
                  marginLeft: '16px',
                }}
              >
                <div
                  style={{
                    ...(referredFilter ? { color: 'rgb(198, 107, 50)' } : {}),
                  }}
                >
                  <CIcon name="cil-filter" />
                  <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Referred</label>
                </div>
                <CDropdown title="Filter by referred">
                  <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                    {useSelector(state => (state.users.referred ? 'Filtered' : 'All Users'))}
                  </CDropdownToggle>
                  <CDropdownMenu style={{ padding: 0 }}>
                    <select
                      aria-label="Referred"
                      id="referred-select"
                      multiple
                      defaultValue={[referredFilter || 'all']}
                      size={Object.keys(referredOptions).length + 1}
                      onChange={onReferredChange}
                      style={{ width: '100%' }}
                    >
                      <option value="all">All Users</option>
                      {Object.keys(referredOptions).map(id => (
                        <option key={id} value={id} selected={referredFilter == id}>
                          {referredOptions[id]}
                        </option>
                      ))}
                    </select>
                  </CDropdownMenu>
                </CDropdown>
                {useSelector(state => state.users.referred) && (
                  <CButton
                    style={{ padding: 0, marginBottom: '32px' }}
                    onClick={e => {
                      const select = document.querySelector('#referred-select');
                      Array.from(select.options).forEach((option, i) => {
                        option.selected = i === 0 ? true : false;
                      });
                      onReferredChange({ target: select });
                    }}
                  >
                    <CIcon name="cil-x"></CIcon>
                    clear
                  </CButton>
                )}
              </div>

              {/* Active Place */}
              <div className="form-group" style={{ marginLeft: '16px' }}>
                <div
                  style={{ ...(activePlacesFilter?.length ? { color: 'rgb(198, 107, 50)' } : {}) }}
                >
                  <CIcon name="cil-filter" />
                  <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Active Places</label>
                </div>
                <CDropdown title="Filter by active place (multiple)">
                  <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                    {activePlacesFilter?.length ? 'Filtered Active Places' : 'All Active Places'}
                  </CDropdownToggle>
                  <CDropdownMenu style={{ padding: 0 }}>
                    <select
                      aria-label="Active Places"
                      id="activePlaces-select"
                      multiple
                      defaultValue={
                        activePlacesFilter && activePlacesFilter.length
                          ? activePlacesFilter
                          : ['all']
                      }
                      size={activePlaces.length + 1}
                      onChange={onActivePlaceChange}
                      style={{ width: '600px' }}
                    >
                      <option
                        value="all"
                        selected={!activePlacesFilter || !activePlacesFilter.length}
                      >
                        All Active Places
                      </option>
                      {/* TODO: Unknown - to list those users without active place */}
                      {activePlaces.map(place => (
                        <option
                          key={place.name}
                          value={place.name}
                          selected={(activePlacesFilter || []).includes(place.name)}
                        >
                          {place.name}
                        </option>
                      ))}
                    </select>
                  </CDropdownMenu>
                </CDropdown>
                {activePlacesFilter?.length && (
                  <CButton
                    style={{ padding: 0, marginBottom: '32px' }}
                    onClick={e => {
                      const select = document.querySelector('#activePlaces-select');
                      Array.from(select.options).forEach((option, i) => {
                        option.selected = i === 0 ? true : false;
                      });
                      onActivePlaceChange({ target: select });
                    }}
                  >
                    <CIcon name="cil-x"></CIcon>
                    clear
                  </CButton>
                )}
              </div>
            </CRow>

            {/* Count and pagination */}
            <div>
              <span>
                {useSelector(
                  state =>
                    `${state.users.currentPage * state.users.limit + 1}-${Math.min((state.users.currentPage + 1) * state.users.limit, state.users.total)} of ${state.users.total}`
                )}
              </span>

              <CPagination
                activePage={useSelector(state => state.users.currentPage + 1)}
                pages={useSelector(state => Math.trunc(state.users.total / state.users.limit) + 1)}
                onActivePageChange={props.onPageChange}
              ></CPagination>
            </div>
          </CRow>
        </CCardHeader>
      </CCard>
    </CCol>
  );
};

FilterComponent.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  // sectionTitle: PropTypes.string.isRequired,
  onLocationChange: PropTypes.func.isRequired,
};

export default FilterComponent;
