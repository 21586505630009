import React from 'react';
import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import PropTypes from 'prop-types';
import { ClearModal, ClearFooter } from '../styles/components/ClearPopUp.module.css';

const ClearPopUp = props => {
  return (
    <>
      <CModal
        centered
        onClose={props.closeClearModal}
        show={props.isClearModalOpen}
        size="sm"
        addContentClass={ClearModal}
      >
        <CModalBody>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="font-weight-bold text-center">
              Are you sure you want to clear the field?
            </div>
          </div>
        </CModalBody>
        <CModalFooter className={ClearFooter}>
          <CButton onClick={props.onClearText} color="info">
            Yes
          </CButton>
          <CButton onClick={props.closeClearModal} variant="outline" color="secondary">
            No
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

ClearPopUp.propTypes = {
  isClearModalOpen: PropTypes.bool.isRequired,
  closeClearModal: PropTypes.func.isRequired,
  onClearText: PropTypes.func.isRequired,
};

export default ClearPopUp;
