import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CCard, CCardBody, CCol, CContainer, CForm, CRow } from '@coreui/react';
import GoogleButton from 'react-google-button';
import * as authActions from '../../store/actions/authActions';
import * as errorModalActions from '../../store/actions/errorModalActions';

const LoginView = props => {
  const dispatch = useDispatch();

  const authHandler = async () => {
    try {
      await dispatch(authActions.checkAuthenticationOnLogin());
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  useEffect(() => {
    authHandler();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="5">
            <CCard className="p-4">
              <CCardBody className="text-center">
                <CForm>
                  <h1>Login</h1>
                  <p className="text-muted">Sign In with your Google account</p>
                  <CRow className="text-muted justify-content-center">
                    <CCol xs="8">
                      <Link to="/redirect">
                        <GoogleButton type="dark" />
                      </Link>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LoginView;
