import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as roomsActions from '../../../store/actions/roomsActions';
import { moveItemDown, moveItemUp } from '../../../utils/utils';
import { Field } from '../../rooms/RoomCommonComponents';
import { AddPreferenceOption } from '../AddPreferenceOption';
import { FieldOption } from './FieldOption';

/**
 * props: field , roomTypeId, onChange
 */
export const ListField = props => {
  const dispatch = useDispatch();
  const { options } = props.field;
  const roomTypes = useSelector(state => state.rooms.roomTypes);

  const onChange = data => {
    let roomType = roomTypes.find(rt => rt.id === props.roomTypeId);
    //  heads up: we filter by name since until saved there are no ids.
    const field = roomType.schema.fields.find(f => f.name === props.field.name);

    field.options = data;
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  const onOptionMove = ({ direction, index }) => {
    const roomType = roomTypes.find(rt => rt.id === props.roomTypeId);
    //  heads up: we filter by name since until saved there are no ids.
    const field = roomType.schema.fields.find(f => f.name === props.field.name);
    if (direction === 'up') {
      moveItemUp(field.options, index);
    }
    if (direction === 'down') {
      moveItemDown(field.options, index);
    }
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  return (
    <div>
      <Field {...props.fieldProps(props.field, 'name')} editable useOnBlur />
      <Field {...props.fieldProps(props.field, 'type')} />
      <Field {...props.fieldProps(props.field, 'description')} editable useOnBlur />

      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong>Options</strong>
          <div>
            <AddPreferenceOption selection={options} onChange={onChange} />
          </div>
        </div>
        <ul>
          {options.map((o, index) => (
            <li>
              <FieldOption option={o} index={index} onOptionMove={onOptionMove} options={options} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
