import { CButton, CCardBody, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardFooter,
  HiveResult,
  Reason,
  ReasonCard,
  ReasonContainer,
  RejectButton,
} from '../styles/components/TextPopUp.module.css';
import CIcon from '@coreui/icons-react';
import { XIcon } from '../styles/components/ImagePopUp.module.css';

const TextPopUp = props => {
  return (
    <>
      <CModal centered onClose={props.closeTextModal} show={props.isModalOpen}>
        <CModalHeader>
          <div>
            <div className="mb-3">
              <h6 className="text-muted">Type</h6>
              <h5>{props.text.type}</h5>
            </div>
            <div>
              <h6 className="text-muted">Text</h6>
              <h5>{props.text.text}</h5>
            </div>
          </div>
          <div>
            <CIcon className={XIcon} size={'lg'} name="cil-x" onClick={props.closeTextModal} />
          </div>
        </CModalHeader>
        <CModalBody className="p-0">
          <div className={Card}>
            <CCardBody className={CardBody}>
              <div
                className="col-sm-7 col-md-6"
                style={{
                  overflow: 'auto',
                }}
              >
                <CCardBody style={{ padding: '0.5rem' }}>
                  {props.text.hiveResults
                    ? props.text.hiveResults.map((hiveResult, index) => (
                        <div key={index} className={HiveResult}>
                          <p>{hiveResult.description}</p>
                          <p className="text-muted">{hiveResult.score}</p>
                        </div>
                      ))
                    : ''}
                </CCardBody>
              </div>
              <div
                className="col-sm-5 col-md-6"
                style={{ borderLeft: '1px solid #d8dbe0', overflow: 'auto' }}
              >
                <CCardBody className={ReasonCard}>
                  {props.reasons.map((reason, index) => (
                    <div
                      key={index}
                      className={ReasonContainer}
                      style={{
                        backgroundColor: reason.isChecked ? '#DB2410' : null,
                        color: reason.isChecked ? '#FFFFFF' : null,
                      }}
                      onClick={() => props.onReasonSelected(reason.id)}
                    >
                      <p className={Reason}>{reason.name}</p>
                    </div>
                  ))}
                </CCardBody>
              </div>
            </CCardBody>
          </div>
        </CModalBody>
        <CModalFooter className={CardFooter}>
          <CButton className={RejectButton} onClick={props.onRejectText}>
            Reject
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

TextPopUp.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onReasonSelected: PropTypes.func.isRequired,
  onRejectText: PropTypes.func.isRequired,
  reasons: PropTypes.array.isRequired,
  closeTextModal: PropTypes.func.isRequired,
  text: PropTypes.object.isRequired,
};

export default TextPopUp;
