import React from 'react';
import { CDataTable, CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import { BanButton, UnbanButton } from '../../styles/components/DataTable.module.css';
import { ButtonRed, ButtonWhite } from '../../styles/views/TextQueueView.module.css';
import Text from '../../models/text';
import CIcon from '@coreui/icons-react';

const TextTable = props => {
  const fields = [
    {
      key: 'userId',
      label: 'ID Number',
      _style: { width: '20%', fontSize: '0.75rem', borderTop: '0px' },
    },
    { key: 'name', _style: { width: '20%', fontSize: '0.75rem', borderTop: '0px' } },
    {
      key: 'type',
      label: 'Type',
      _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'text',
      label: 'Text',
      _style: { width: '20%', fontSize: '0.75rem', borderTop: '0px' },
    },
    { key: 'warnings', _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' } },
    {
      key: 'priorBans',
      label: 'Prior Bans',
      _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'userStatus',
      label: 'User Status',
      _style: { width: '10%', fontSize: '0.75rem', borderTop: '0px' },
      filter: false,
    },
    {
      key: 'actions',
      _style: { width: '5%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
    {
      key: 'changeStatus',
      label: 'Change Status',
      _style: { width: '5%', fontSize: '0.75rem', borderTop: '0px' },
      sorter: false,
      filter: false,
    },
  ];

  return (
    <CDataTable
      items={props.pendingText}
      fields={fields}
      hover
      sorter
      scopedSlots={{
        userId: text => (
          <td>
            <div style={{ wordBreak: 'break-all' }}>
              <p>{text.userId}</p>
            </div>
          </td>
        ),
        name: text => (
          <td>
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between">
                <div className="c-avatar mr-1" style={{ backgroundColor: '#c7cbd1' }}>
                  <img
                    src={text.profilePhoto}
                    className="c-avatar-img"
                    alt="Not found"
                    style={{
                      maxHeight: '100%',
                      objectFit: 'cover',
                      minHeight: '36px',
                      minWidth: '36px',
                    }}
                  />
                </div>
                <p className="mb-0 align-self-center">
                  {/* <Link
                    to={{
                      pathname: `/main/profile/${text.userId}`,
                      state: { prevLocation: props.location.pathname },
                    }}
                    className="link"
                    style={{ color: "#4A90E2" }}
                  > */}
                  {text.name}
                  {/* </Link> */}
                </p>
              </div>
            </div>
          </td>
        ),
        type: text => (
          <td>
            <div className="d-flex justify-content-between">
              <div style={{ wordBreak: 'break-word' }}>
                <p>{text.type}</p>
              </div>
            </div>
          </td>
        ),
        text: text => (
          <td>
            <div className="d-flex justify-content-between">
              <div style={{ wordBreak: 'break-word' }}>
                <p>{text.text}</p>
              </div>
            </div>
          </td>
        ),
        actions: text => {
          return (
            <td className="py-2">
              <div className="d-flex">
                <CButton
                  className={props.bannedUsers.includes(text.userId) ? UnbanButton : BanButton}
                  shape="square"
                  size="sm"
                  onClick={() => {
                    props.openBanUnbanModal(text.userId);
                  }}
                >
                  {props.bannedUsers.includes(text.userId) ? 'Unban' : 'Ban'}
                </CButton>
              </div>
            </td>
          );
        },
        userStatus: text => {
          return (
            <td className="py-2">
              <div className="d-flex">
                <div style={{ wordBreak: 'break-word' }}>
                  <p>{text.userStatus}</p>
                </div>
              </div>
            </td>
          );
        },
        changeStatus: text => {
          return (
            <td className="py-2">
              <CButton
                id={text.id}
                className={text.rejected ? ButtonRed : ButtonWhite}
                shape="pill"
                onClick={() => {
                  text.rejected ? props.onUnrejectText(text) : props.openTextPopUpModal(text);
                }}
              >
                <CIcon name="cil-x" className="h-75 w-75" />
              </CButton>
            </td>
          );
        },
      }}
    />
  );
};

TextTable.propTypes = {
  pendingText: PropTypes.arrayOf(PropTypes.instanceOf(Text)).isRequired,
  openBanUnbanModal: PropTypes.func.isRequired,
  openTextPopUpModal: PropTypes.func.isRequired,
  onUnrejectText: PropTypes.func.isRequired,
  bannedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TextTable;
