import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import ErrorModalComponent from './hoc/ErrorModalComponent';
import * as serviceWorker from './serviceWorker';

import * as Icons from '@coreui/icons';
import store, { browserHistory } from './store/Store';

React.icons = Icons;

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <App />
      <ErrorModalComponent />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
