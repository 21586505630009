import { CButton, CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as errorModalActions from '../store/actions/errorModalActions';
import * as profileActions from '../store/actions/profileActions';

const UserFeatures = props => {
  const [saving, setSaving] = useState(false);
  const [features, setFeatures] = useState([...props.features]);
  const dispatch = useDispatch();
  const [modified, setModified] = useState(false);

  const onSave = async () => {
    try {
      setSaving(true);
      await dispatch(profileActions.setUserFeatures(props.userId, features));
      setModified(false);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setSaving(false);
  };
  if (saving) {
    return <CSpinner color="info" style={{ marginLeft: '50%' }} />;
  }
  return (
    <div>
      <h4 style={{ display: 'inline' }}>Features</h4>
      <ul
        style={{
          maxHeight: '75px',
          overflowY: 'scroll',
          paddingLeft: '20px',
          marginTop: '6px',
        }}
      >
        {features.map(feature => (
          <li>
            <label>
              <input
                type="checkbox"
                checked={feature.value}
                style={{ marginRight: '8px' }}
                onChange={e => {
                  setModified(true);
                  setFeatures([
                    ...features.filter(f => f.name !== feature.name),
                    { name: feature.name, value: e.target.checked },
                  ]);
                }}
              ></input>
              {featuresNames[feature.name] || feature.name}
            </label>
          </li>
        ))}
      </ul>
      {modified && (
        <CButton className="flex-grow-1" color={'info'} size="sm" onClick={onSave}>
          Save
        </CButton>
      )}
    </div>
  );
};

const featuresNames = {
  preventScreenshots: 'No Screenshots',
};

UserFeatures.propTypes = {
  userId: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
};

export default UserFeatures;
