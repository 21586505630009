import ApiService from '../../services/ApiService';
import FlaggedUser from '../../models/flaggedUser';

export const GET_FLAGGED_USERS = 'GET_FLAGGED_USERS';
export const BAN_FLAGGED_USER = 'BAN_FLAGGED_USER';
export const UNBAN_FLAGGED_USER = 'UNBAN_FLAGGED_USER';
export const CLOSE_FLAGS = 'CLOSE_FLAGS';

export const banUser = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.post('ban', { userId: userId }, { withCredentials: true });
    dispatch({ type: BAN_FLAGGED_USER, userId: userId });
  };
};

export const unbanUser = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.delete('ban', {
      data: { userId: userId },
      withCredentials: true,
    });
    dispatch({ type: UNBAN_FLAGGED_USER, userId: userId });
  };
};

export const getFlaggedUsers = () => {
  const limit = 10;
  return async dispatch => {
    const response = await ApiService.get(`profiles/reportedprofiles?&limit=${limit}`, {
      withCredentials: true,
    });

    const { reports } = response.data;

    const users = MapProfilesFromResponse(reports);

    dispatch({
      type: GET_FLAGGED_USERS,
      flaggedUsers: users,
    });
  };
};

export const closeFlags = () => {
  return async (dispatch, getState) => {
    const flaggedUsers = getState().flags.flaggedUsers;

    const body = {
      ids: flaggedUsers.map(user => {
        return user.reportId;
      }),
    };

    // eslint-disable-next-line
    const response = await ApiService.post(`profiles/closereportedprofiles`, body, {
      withCredentials: true,
    });

    dispatch({
      type: CLOSE_FLAGS,
    });
  };
};

const MapProfilesFromResponse = reports => {
  return reports.map(report => {
    return new FlaggedUser(report);
  });
};
