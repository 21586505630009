import React, { useRef } from 'react';
import {
  CModal,
  CModalBody,
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CCol,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
  ModalBody,
  ImageContainer,
  ModalImage,
  Card,
  CardHeader,
  HeaderTable,
  CardBody,
  XIcon,
  HiveResult,
  ReasonCard,
  ReasonContainer,
  Reason,
  CardFooter,
  RejectButton,
  ApproveButton,
  ImageWrapper,
  ModalActionSection,
  TakenAtHeader,
} from '../styles/components/ImagePopUp.module.css';
import PropTypes from 'prop-types';

const ImagePopUp = props => {
  const imgElement = useRef(null);

  const isRejectionReasonSelected = props?.reasons?.some(reason => reason?.isChecked === true);

  const sortedReasons = props.reasons.sort((a, b) =>
    a.name.toLowerCase() === 'other' ? 1 : a.name.localeCompare(b.name)
  );

  return (
    <>
      <CModal
        centered
        onClose={props.switchShowModal}
        show={props.isModalOpen}
        style={{
          display: 'flex',
          flex: 1,
          alignSelf: 'center',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
      >
        <CModalBody className={ModalBody}>
          <div className={ImageWrapper}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ marginLeft: 10, marginTop: 10, color: 'white' }}>
                {props.selfieVerification?.takenAt}
              </div>
              <div className={ImageContainer}>
                <img
                  className={ModalImage}
                  src={props?.selfieVerification?.url}
                  alt="Not Found"
                  ref={imgElement}
                />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div style={{ marginLeft: 10, marginTop: 10, color: 'white' }}>
                {props.photo?.takenAt}
              </div>
              <div className={ImageContainer}>
                <img
                  className={ModalImage}
                  src={props.photo?.url}
                  alt="Not Found"
                  ref={imgElement}
                />
              </div>
            </div>
          </div>
          <div className={ModalActionSection}>
            <CCard className={Card}>
              <CCardHeader className={CardHeader}>
                <CCol className="col-sm-11 col-md-11 p-0" style={{ overflow: 'hidden' }}>
                  <table className={HeaderTable}>
                    <tbody>
                      <tr className="d-flex">
                        <td className="text-center pr-2">
                          <CButton
                            color="success"
                            className={ApproveButton}
                            size={'sm'}
                            onClick={props?.onApprovePhoto}
                            disabled={isRejectionReasonSelected}
                          >
                            Approve
                          </CButton>
                          <CButton
                            color="info"
                            size={'sm'}
                            className={RejectButton}
                            onClick={props.onRejectPhoto}
                            disabled={!isRejectionReasonSelected}
                          >
                            Reject
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CCol>
                <CCol className="col-sm-1 col-md-1 p-0">
                  <div className="card-header-actions">
                    <CIcon
                      className={XIcon}
                      size={'lg'}
                      name="cil-x"
                      onClick={props.switchShowModal}
                    />
                  </div>
                </CCol>
              </CCardHeader>
              <CCardBody className={CardBody}>
                <CCol
                  className="col-sm-7 col-md-6 p-0"
                  style={{
                    overflow: 'auto',
                  }}
                >
                  <CCardBody style={{ padding: '0.5rem' }}>
                    {props.photo?.hiveResults?.map((hiveResult, index) => (
                      <div key={index} className={HiveResult}>
                        <p>{hiveResult.description}</p>
                        <p className="text-muted">{hiveResult.score}</p>
                      </div>
                    ))}
                  </CCardBody>
                </CCol>
                <CCol
                  className="col-sm-5 col-md-6 p-0"
                  style={{ borderLeft: '1px solid #768192', overflow: 'auto' }}
                >
                  <CCardBody className={ReasonCard}>
                    {sortedReasons.map((reason, index) => (
                      <div
                        key={index}
                        className={ReasonContainer}
                        style={{
                          backgroundColor: reason.isChecked ? '#DB2410' : null,
                        }}
                        onClick={() => props.onReasonSelected(reason.id)}
                      >
                        <p className={Reason}>{reason.name}</p>
                      </div>
                    ))}
                  </CCardBody>
                </CCol>
              </CCardBody>
            </CCard>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

ImagePopUp.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onRejectPhoto: PropTypes.func.isRequired,
  onReasonSelected: PropTypes.func.isRequired,
  onUnrejectPhoto: PropTypes.func,
  photo: PropTypes.object.isRequired,
  reasons: PropTypes.array.isRequired,
  switchShowModal: PropTypes.func.isRequired,
};

export default ImagePopUp;
