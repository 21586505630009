import statusEnum, { ALBUMS_ID } from '../utils/statusEnum';
import geohash from 'ngeohash';
import Image from './image';
import { tagsToCommaSeparated } from '../utils/Tags';
import { CurrentIntentionsWhen, CurrentIntentionsWhere, Positions } from '../utils/ProfileFields';
import * as moment from 'moment';

class Profile {
  constructor(profile) {
    const photos = profile.images ? ImagesFormatter(profile) : [];

    this.userId = profile.userId;
    this.email = profile.email;
    //TODO: Check for default image.
    this.profilePhoto =
      profile.images && profile.images.length > 0
        ? profile.images[0].url
        : 'https://cdn4.iconfinder.com/data/icons/jetflat-2-faces-2/60/005b_042_user_profile_avatar_man_boy_round-512.png';
    this.photos = photos.filter(image => image.albumId !== ALBUMS_ID.SELFIE_VERIFICATION);
    this.status = statusEnum[profile.status];
    this.birthDate = profile.birthDate;
    this.created = profile.creationTime;
    this.signedIn = profile.lastConnection;
    this.name = profile.name;
    this.location = profile.location ? geohash.decode(profile.location) : null;
    this.forceLocation = profile.forceLocation;

    this.provider = ProvidersFormatter(profile.providers);
    this.about = profile.about;
    this.isBanned = statusEnum[profile.status] === statusEnum[1];
    this.previousState =
      statusEnum[profile.status] === statusEnum[1] ? statusEnum[0] : statusEnum[profile.status];
    this.instagram = profile.instagram;
    this.twitter = profile.twitter;
    this.snapChat = profile.snapChat;
    this.tikTok = profile.tikTok;
    this.gender = profile.gender;
    this.relationship = profile.relationship;
    this.height = profile.height;
    this.weight = profile.weight;
    this.position = profile.position;
    this.cigarettes = profile.cigarettes;
    this.hivStatus = profile.hivStatus;
    this.sendbirdId = profile.sendbirdId || '';

    this.signUpLocation = profile.signUpLocation ? geohash.decode(profile.signUpLocation) : null;
    this.signUpCountry = profile.signUpCountry || '';
    this.signUpState = profile.signUpState || '';
    this.signUpCity = profile.signUpCity || '';

    this.homeLocation = profile.homeLocation ? geohash.decode(profile.homeLocation) : null;
    this.homeNeighborhood = profile.homeNeighborhood || '';
    this.homeCity = profile.homeCity || '';
    this.homeCountry = profile.homeCountry || '';
    this.homeState = profile.homeState || '';
    this.homePostalCode = profile.homePostalCode || '';

    this.selfieVerification = photos.filter(
      image => image.albumId === ALBUMS_ID.SELFIE_VERIFICATION
    )[0];
    this.currentIntentionWhen = profile.currentIntentionWhen;
    this.currentIntentionWhere = profile.currentIntentionWhere;
    this.currentIntentionFreeForm = profile.currentIntentionFreeForm;

    // Tag fields:
    this.hosting = profile.hosting;
    this.sexualInterests = profile.sexualInterests;
    this.pronouns = profile.pronouns;
    this.gender = profile.gender;
    this.lookingFor = profile.lookingFor;
    this.relationship = profile.relationship;
    this.interests = profile.interests;
    this.ethnicity = profile.ethnicity;
    this.ethnicityAdmin = profile.ethnicityAdmin;
    this.score = profile.score;
    this.yesScore = profile.yesScore;
    this.features = profile.features;
  }

  get getBirthDay() {
    return BirthDayFormatter(this.birthDate);
  }

  get getCreated() {
    return DateFormatter(this.created);
  }

  get getSignedIn() {
    return DateFormatter(this.signedIn);
  }

  get getInterests() {
    return tagsToCommaSeparated(this.interests);
  }

  get getHeight() {
    return HeightFormatter(this.height);
  }

  get getWeight() {
    return this.weight > 0 ? this.weight : '';
  }

  get getHosting() {
    return tagsToCommaSeparated(this.hosting);
  }

  get getSexualInterests() {
    return tagsToCommaSeparated(this.sexualInterests);
  }

  get getPronouns() {
    return tagsToCommaSeparated(this.pronouns);
  }

  get getGender() {
    return tagsToCommaSeparated(this.gender);
  }

  get getLookingFor() {
    return tagsToCommaSeparated(this.lookingFor);
  }

  get getRelationship() {
    return tagsToCommaSeparated(this.relationship);
  }

  get getCurrentIntentionWhen() {
    return Object.keys(CurrentIntentionsWhen).find(
      key => CurrentIntentionsWhen[key] === this.currentIntentionWhen
    );
  }

  get getCurrentIntentionWhere() {
    return this.currentIntentionWhere.map(value => {
      return Object.keys(CurrentIntentionsWhere).find(key => CurrentIntentionsWhere[key] === value);
    });
  }

  get getPosition() {
    return Object.keys(Positions).find(key => Positions[key] === this.position);
  }

  get getEthnicity() {
    return tagsToCommaSeparated(this.ethnicity);
  }
}

const ImagesFormatter = profile => {
  return profile.images.map((imgData, index) => {
    const image = new Image(imgData);
    image.id = index;
    image.userName = profile.name;
    image.userId = profile.userId;
    image.userPhoto = profile.images[0].url;
    return image;
  });
};

const LocationLinkFormatter = location => {
  if (!location) {
    return '';
  }
  const latlon = geohash.decode(location);
  return `https://www.google.com/maps/search/?api=1&query=${latlon.latitude},${latlon.longitude}`;
};

// TODO move to src/utils/utils.js
export const BirthDayFormatter = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('MMMM DD, YYYY');
};

// TODO move to src/utils/utils.js
export const DateFormatter = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('MM-DD-YYYY');
};

const HeightFormatter = height => {
  if (height > 0) {
    const realFeet = height / 12;
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);
    return feet + "'-" + inches + "''";
  }
  return '';
};

const ProvidersFormatter = providers => {
  let formattedProviders = [];

  if (providers) {
    formattedProviders = providers.map(provider => {
      const cleanedName = provider.split('.');
      return cleanedName[0].charAt(0).toUpperCase() + cleanedName[0].slice(1);
    });
  }

  return formattedProviders.length > 0 ? formattedProviders[0] : '';
};

export default Profile;
