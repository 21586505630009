export default [
  {
    _tag: 'CSidebarNavDivider',
  },
  {
    _tag: 'CSidebarNavDivider',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'User List',
    to: '/main/users',
    icon: 'cil-user',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Photo Queue',
    to: '/main/photo-queue',
    icon: 'cil-image',
  },

  // // TODO: remove all components related to the following commented features
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Photo Queue",
  //   to: "/main/photos",
  //   icon: "cil-image",
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Text Queue",
  //   to: "/main/text",
  //   icon: "cil-comment-square",
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Flag Queue',
    to: '/main/flags',
    icon: 'cil-flag-alt',
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Settings",
  //   to: "/main/settings",
  //   icon: "cil-settings",
  // },

  {
    _tag: 'CSidebarNavItem',
    name: 'Batch Suggestions',
    to: '/main/batchManualSuggestions',
    icon: 'cil-user-plus',
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Rooms',
    to: '/main/rooms',
    icon: 'cil-room',
  },

  {
    _tag: 'CSidebarNavItem',
    name: 'Room Types',
    to: '/main/roomTypes',
    icon: 'cil-room',
  },
];
