import { CCard, CTabContent, CTabPane, CTabs } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/views/UserProfileView.module.css';
import {
  Card,
  Container,
  ImagesContainer,
  ImageWrapper,
  ProfileImagesTitle,
} from '../../styles/views/UserProfileView.module.css';
import PanelImageComponent from '../PanelImageComponent';

const UserProfilePhotoPanel = props => {
  const dispatch = useDispatch();

  const profilePhotos = useSelector(state => state.profile.profile.photos);
  const selfieVerification = useSelector(state => state?.profile?.profile?.selfieVerification);

  //create an auxiliary array completed with dummy objects to have always 6 "images"
  const auxiliaryPhotos = [...profilePhotos];
  for (let i = profilePhotos.length; i < 6; i++) {
    auxiliaryPhotos.push(null);
  }
  // slice the images to render images in two rows
  const firstRowImages = auxiliaryPhotos.slice(0, 3);
  const secondRowImages = auxiliaryPhotos.slice(3);

  return (
    <>
      <CCard className={Card}>
        <CTabs>
          <CTabContent style={{ paddingBottom: '15px' }}>
            <CTabPane>
              <div className={Container} style={{ paddingTop: '20px' }}>
                <span className={ProfileImagesTitle}>Profile Photos ({profilePhotos.length})</span>

                <div className={ImagesContainer}>
                  {firstRowImages.map((image, i) => (
                    <div className={ImageWrapper} key={i}>
                      <PanelImageComponent
                        photo={image}
                        selfieVerification={selfieVerification}
                        onRejectPhoto={props.onRejectPhoto}
                        onApprovePhoto={props?.onApprovePhoto}
                      />
                    </div>
                  ))}
                </div>
                <div className={ImagesContainer}>
                  {secondRowImages.map((image, i) => (
                    <div className={ImageWrapper} key={i}>
                      <PanelImageComponent
                        photo={image}
                        selfieVerification={selfieVerification}
                        onRejectPhoto={props.onRejectPhoto}
                        onApprovePhoto={props?.onApprovePhoto}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </CTabPane>
          </CTabContent>
        </CTabs>
      </CCard>
    </>
  );
};

UserProfilePhotoPanel.propTypes = {
  onRejectPhoto: PropTypes.func.isRequired,
};

export default UserProfilePhotoPanel;
