import {
  GET_PROFILE,
  CLEAR_NAME,
  CLEAR_ABOUT_ME,
  CLEAR_INSTAGRAM,
  CLEAR_TWITTER,
  CLEAR_SNAPCHAT,
  CLEAR_TIKTOK,
  BAN_USER,
  UNBAN_USER,
  REJECT_USER_PHOTO,
  UPDATE_USER_STATUS,
  APPROVE_ALL_PHOTOS,
  APPROVE_USER_PHOTO,
  REJECT_USER_SELFIE,
  APPROVE_USER_SELFIE,
  EDIT_BIRTH_DATE,
  EDIT_ETHNICITY,
  EDIT_SCORE,
} from '../actions/profileActions';
import statusEnum, { PROFILE_IMAGE_STATUS } from '../../utils/statusEnum';

const initialState = {
  profile: null,
};

export default (state = initialState, action) => {
  let updatedProfile;

  switch (action.type) {
    case GET_PROFILE:
      return { ...state, ...{ profile: action.profile, referralInfo: action.referralInfo } };
    case CLEAR_NAME:
      updatedProfile = state.profile;
      updatedProfile.name = '';
      return { ...state, ...{ profile: updatedProfile } };
    case EDIT_BIRTH_DATE:
      updatedProfile = state.profile;
      updatedProfile.birthDate = action.birthDate || updatedProfile.birthDate;
      return { ...state, ...{ profile: updatedProfile } };
    case CLEAR_ABOUT_ME:
      updatedProfile = state.profile;
      updatedProfile.about = '';
      return { ...state, ...{ profile: updatedProfile } };
    case CLEAR_INSTAGRAM:
      updatedProfile = state.profile;
      updatedProfile.instagram = '';
      return { ...state, ...{ profile: updatedProfile } };
    case CLEAR_TWITTER:
      updatedProfile = state.profile;
      updatedProfile.twitter = '';
      return { ...state, ...{ profile: updatedProfile } };
    case CLEAR_SNAPCHAT:
      updatedProfile = state.profile;
      updatedProfile.snapChat = '';
      return { ...state, ...{ profile: updatedProfile } };
    case CLEAR_TIKTOK:
      updatedProfile = state.profile;
      updatedProfile.tikTok = '';
      return { ...state, ...{ profile: updatedProfile } };
    case BAN_USER:
      updatedProfile = state.profile;
      updatedProfile.status = statusEnum[1];
      updatedProfile.isBanned = true;
      return { ...state, ...{ profile: updatedProfile } };
    case UNBAN_USER:
      updatedProfile = state.profile;
      updatedProfile.status = updatedProfile.previousState;
      updatedProfile.isBanned = false;
      return { ...state, ...{ profile: updatedProfile } };
    case REJECT_USER_PHOTO:
      updatedProfile = state.profile;
      updatedProfile.photos = updatedProfile.photos.map(photo =>
        photo.id === action.photoId
          ? {
              ...photo,
              status: PROFILE_IMAGE_STATUS.REJECTED,
              rejectReasons: action.reasons,
              rejected: true,
            }
          : { ...photo }
      );
      return { ...state, profile: { ...updatedProfile } };
    case REJECT_USER_SELFIE:
      updatedProfile = state.profile;
      updatedProfile.selfieVerification = {
        ...updatedProfile.selfieVerification,
        status: PROFILE_IMAGE_STATUS.REJECTED,
        rejectReasons: action.reasons,
        rejected: true,
      };

      return { ...state, profile: { ...updatedProfile } };
    case UPDATE_USER_STATUS:
      updatedProfile = state.profile;
      updatedProfile.status = statusEnum[action.statusId];
      updatedProfile.isBanned = statusEnum['1'] === statusEnum[action.statusId];
      return { ...state, profile: { ...updatedProfile } };
    case APPROVE_USER_PHOTO:
      updatedProfile = state?.profile;
      updatedProfile.photos = updatedProfile?.photos?.map(photo =>
        photo?.id === action?.photoId
          ? { ...photo, status: PROFILE_IMAGE_STATUS?.APPROVED, rejectReasons: [], rejected: false }
          : { ...photo }
      );
      return { ...state, profile: { ...updatedProfile } };
    case APPROVE_USER_SELFIE:
      updatedProfile = state.profile;
      updatedProfile.selfieVerification = {
        ...updatedProfile.selfieVerification,
        status: PROFILE_IMAGE_STATUS.APPROVED,
        rejectReasons: [],
        rejected: false,
      };
      return { ...state, profile: { ...updatedProfile } };
    case APPROVE_ALL_PHOTOS:
      updatedProfile = state.profile;
      updatedProfile.photos = updatedProfile.photos.map(photo => ({
        ...photo,
        status: PROFILE_IMAGE_STATUS.APPROVED,
        rejectReasons: [],
        rejected: false,
      }));
      //Approve selfie
      updatedProfile.selfieVerification = {
        ...updatedProfile.selfieVerification,
        status: PROFILE_IMAGE_STATUS.APPROVED,
        rejectReasons: [],
        rejected: false,
      };

      return { ...state, profile: { ...updatedProfile } };
    case EDIT_ETHNICITY:
      updatedProfile = state.profile;
      updatedProfile.ethnicityAdmin = action.ethnicityAdmin || updatedProfile.ethnicityAdmin;
      return { ...state, ...{ profile: updatedProfile } };

    case EDIT_SCORE:
      updatedProfile = state.profile;
      updatedProfile.score = action.score;
      return { ...state, ...{ profile: updatedProfile } };
    default:
      return state;
  }
};
