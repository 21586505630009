import { ethnicityFieldIds } from '../../models/profileTagFieldIds';
import { GET_ETHNICITY_TAGS, GET_ALL_TAGS } from '../actions/tagActions';

const initialState = {
  ethnicity: [],
  ethnicityAdmin: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ETHNICITY_TAGS:
      const key = Object.keys(ethnicityFieldIds).find(
        key => ethnicityFieldIds[key] === action.profileFieldId
      );
      return {
        ...state,
        ...{ [key]: action.payload },
      };

    case GET_ALL_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    default:
      return state;
  }
};
