import { CButton, CSpinner } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { statusEnum } from '../utils/statusEnum';
import { BirthDayFormatter } from '../models/profile';
import * as errorModalActions from '../store/actions/errorModalActions';
import * as profileActions from '../store/actions/profileActions';
import { CSV } from '../utils/csv';
import { downloadFile, formatDateSql } from '../utils/utils';
import { ProfileActionButton } from '../components/ProfileActionButton';

function buildReferrals(referralInfo, userId) {
  return referralInfo.referrals.map(referral => {
    return {
      ...referral,
      birthDate: BirthDayFormatter(referral.birthDate),
      referralId: userId,
      creationTime: formatDateSql(referral.creationTime),
      status: statusEnum[referral.status] || 'N/A',
      name: referral.name || 'N/A',
    };
  });
}

function onExport(referralInfo, userId) {
  const referrals = buildReferrals(referralInfo, userId);
  var s = CSV.serialize({
    fields: Object.keys(referrals[0]).map(id => ({ id })),
    records: referrals,
  });
  downloadFile(s, `referrals_${formatDateSql(new Date())}.csv`);
}

const ProfileReferralInfo = props => {
  const [saving, setSaving] = useState(false);
  const { referralInfo } = props;
  const [maxReferrals, setMaxReferrals] = useState(referralInfo ? referralInfo.maxReferrals : 3);
  const dispatch = useDispatch();
  const [modified, setModified] = useState(false);

  const onSaveMaxReferrals = async () => {
    try {
      setSaving(true);
      await dispatch(profileActions.setMaxReferrals(props.userId, maxReferrals));
      setModified(false);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setSaving(false);
  };
  if (saving) {
    return <CSpinner color="info" style={{ marginLeft: '50%' }} />;
  }
  const itemStyle = { paddingTop: '6px' };
  return (
    <div>
      <h4 style={{ display: 'inline' }}>Referrals</h4>

      <div style={{ ...itemStyle, paddingTop: '12px' }}>
        <strong>Referred?</strong>:{' '}
        {referralInfo.referredBy ? (
          // TODO: single page navigation - Had trouble with <Link> - url changed but it didn't reload the profile.
          <a href={`/main/profile/${referralInfo.referredBy.userId}`} target="_blank">
            Yes
          </a>
        ) : (
          'No'
        )}
      </div>

      <div style={itemStyle}>
        <strong>Code</strong>: {referralInfo.referralCode}
      </div>

      <label style={itemStyle}>
        <strong>Max referrals</strong>
        <input
          type="number"
          style={{ width: '100%' }}
          min="0"
          step="1"
          value={maxReferrals}
          onChange={e => {
            setMaxReferrals(parseInt(e.target.value));
            setModified(true);
          }}
        ></input>
      </label>

      {modified && (
        <CButton className="flex-grow-1" color={'info'} size="sm" onClick={onSaveMaxReferrals}>
          Save
        </CButton>
      )}

      {!!referralInfo.referrals && !!referralInfo.referrals.length && (
        <div style={itemStyle}>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <div>Total: {referralInfo.referrals.length}.</div>
            <div>Approved: {referralInfo.referrals.filter(user => user.status === 0).length}.</div>
            <ProfileActionButton
              label="Export"
              onClick={() => onExport(referralInfo, props.userId)}
            />
          </div>
          <ul
            style={{
              maxHeight: '75px',
              overflowY: 'scroll',
              paddingLeft: '20px',
              marginTop: '6px',
            }}
          >
            {referralInfo.referrals.map((r, i) => {
              return (
                <li>
                  <a href={`/main/profile/${r.userId}`} target="_blank">
                    {r.status === null ? `Unknown_${i}` : r.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

ProfileReferralInfo.propTypes = {
  userId: PropTypes.string.isRequired,
  referralInfo: PropTypes.object.isRequired,
};

export default ProfileReferralInfo;
