const CurrentIntentionsWhen = Object.freeze({
  now: 1,
  soon: 2,
  later: 3,
});

const CurrentIntentionsWhere = Object.freeze({
  none: 0,
  host: 1,
  travel: 2,
  meetOut: 4,
});

const Positions = Object.freeze({
  top: 1,
  bottom: 2,
  verse: 3,
  verseTop: 4,
  verseBottom: 5,
  exploring: 6,
  side: 7,
  preferNotToSay: 8,
});

export { CurrentIntentionsWhen, CurrentIntentionsWhere, Positions };
