import statusEnum from '../utils/statusEnum';
import geohash from 'ngeohash';

class User {
  constructor(profile) {
    this.userId = profile.userId;
    this.name = profile.name;
    //TODO: Check for default image.
    this.profilePhoto =
      profile.images && profile.images.length > 0
        ? profile.images[0].url
        : 'https://cdn4.iconfinder.com/data/icons/jetflat-2-faces-2/60/005b_042_user_profile_avatar_man_boy_round-512.png';
    this.location = profile.location ? geohash.decode(profile.location) : null;
    this.created = profile.creationTime;
    this.about = profile.about;
    this.status = statusEnum[profile.status];
    this.oldestPendingMedia = profile.oldestPendingMedia;

    this.signUpLocation = profile.signUpLocation ? geohash.decode(profile.signUpLocation) : null;
    this.signUpCountry = profile.signUpCountry || '';
    this.signUpState = profile.signUpState || '';
    this.signUpCity = profile.signUpCity || '';

    this.homeLocation = profile.homeLocation ? geohash.decode(profile.homeLocation) : null;
    this.homeNeighborhood = profile.homeNeighborhood || '';
    this.homeCity = profile.homeCity || '';
    this.homeCountry = profile.homeCountry || '';
    this.homeState = profile.homeState || '';
    this.homePostalCode = profile.homePostalCode || '';

    this.referredBy = profile.referredBy || '';
  }

  get getCreated() {
    return formatDate(this.created);
  }

  get getOldestPendingMedia() {
    return formatDate(this.oldestPendingMedia);
  }
}

/** @returns date in MM-DD-YYYY format or `defaultValue` if `date` string param is not parsable */
const formatDate = (date, defaultValue = '') => {
  let formattedDate = new Date(date || '');
  let day = ('0' + formattedDate.getDate()).slice(-2);
  let month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
  let year = formattedDate.getFullYear();
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return defaultValue;
  }
  return `${month}/${day}/${year}`;
};

export default User;
