import React from 'react';
import { CAlert } from '@coreui/react';

const ProfileNotFoundComponent = () => {
  return (
    <CAlert color="info" style={{ textAlign: 'center' }}>
      Sorry, the profile wasn't found.
    </CAlert>
  );
};

export default ProfileNotFoundComponent;
