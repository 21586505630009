import React, { useState } from 'react';
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
  ImageContainer,
  Image,
  CardBody,
  ImageAndButtonContainer,
  FooterBody,
  FooterUserName,
  FooterTextContainer,
  FooterIdContainer,
  ButtonRed,
  ButtonWhite,
  ButtonRedBigScreen,
  ButtonWhiteBigScreen,
  ImageContainerBigScreen,
  CardBodyBigScreen,
  ImageAndButtonContainerBigScreen,
  FooterBodyBigScreen,
  CAvatarBigScreen,
} from '../styles/components/ImageComponent.module.css';
import ImagePopUp from './ImagePopUp';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { IMAGE_REJECTION_REASONS } from '../utils/statusEnum';

// Note: this is NOT user profile
const ImageComponent = props => {
  const [isRejected, setIsRejected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);

  const isBigScreen = useMediaQuery({ query: '(min-width: 1921px)' });

  const switchShowModal = () => {
    !isModalOpen ? setSelectedReasons(checkReasons(props.photo)) : setSelectedReasons([]);
    setIsModalOpen(!isModalOpen);
  };

  const onReasonSelected = reasonId => {
    const newSelectedReasons = [...selectedReasons];
    const indexReason = newSelectedReasons.findIndex(p => p.id === reasonId);
    newSelectedReasons[indexReason].isChecked = !newSelectedReasons[indexReason].isChecked;
    setSelectedReasons(newSelectedReasons);
  };

  const onRejectPhoto = () => {
    const reasonsIds = selectedReasons.reduce(
      (acc, reason) => (reason.isChecked === true ? [...acc, reason.id] : acc),
      []
    );
    props.onRejectPhoto(props.photo.id, reasonsIds);
    setIsModalOpen(!isModalOpen);
    setIsRejected(!isRejected);
    setSelectedReasons([]);
  };

  const onUnrejectPhoto = () => {
    props.onUnrejectPhoto(props.photo.id);
    setIsRejected(!isRejected);
    setSelectedReasons(checkReasons(props.photo));
  };

  return (
    <>
      <div className={isBigScreen ? CardBodyBigScreen : CardBody}>
        <div className={isBigScreen ? ImageAndButtonContainerBigScreen : ImageAndButtonContainer}>
          <div
            className={isBigScreen ? ImageContainerBigScreen : ImageContainer}
            onClick={() => switchShowModal()}
          >
            <img className={Image} src={props.photo.url} alt="Not Found" />
          </div>
          {isRejected ? (
            <CButton
              className={isBigScreen ? ButtonRedBigScreen : ButtonRed}
              shape="pill"
              onClick={onUnrejectPhoto}
            >
              <CIcon name="cil-x" className="h-75 w-75" />
            </CButton>
          ) : (
            <CButton
              className={isBigScreen ? ButtonWhiteBigScreen : ButtonWhite}
              shape="pill"
              onClick={switchShowModal}
            ></CButton>
          )}
        </div>
        <div className={isBigScreen ? FooterBodyBigScreen : FooterBody}>
          <div className="h-100 d-flex align-items-center pr-2">
            <div className="p-0 h-100 w-25 align-items-center d-flex justify-content-center col-md-3">
              <div
                className={isBigScreen ? CAvatarBigScreen : 'c-avatar'}
                style={{ backgroundColor: '#c7cbd1' }}
              >
                <img
                  src={props.photo.userPhoto}
                  className="c-avatar-img"
                  alt="Not Found"
                  style={{
                    maxHeight: '100%',
                    objectFit: 'cover',
                    minHeight: '36px',
                    minWidth: '36px',
                  }}
                />
              </div>
            </div>
            <div className="px-0 h-100 w-100 align-items-start d-flex flex-column justify-content-center col-md-9">
              <div className={FooterTextContainer}>
                <span className={FooterUserName}>{props.photo.userName}</span>
                <span className="text-muted text-uppercase">{props.photo.getCreatedAt}</span>
              </div>
              <div className={FooterIdContainer}>
                <span className="text-muted">{props.photo.userId}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImagePopUp
        isModalOpen={isModalOpen}
        onReasonSelected={onReasonSelected}
        onRejectPhoto={onRejectPhoto}
        onUnrejectPhoto={onUnrejectPhoto}
        photo={props.photo}
        reasons={selectedReasons}
        switchShowModal={switchShowModal}
      />
    </>
  );
};

const checkReasons = photo => {
  return IMAGE_REJECTION_REASONS.map(reason => {
    return {
      ...reason,
      isChecked: photo.rejectReasons.includes(reason.id),
    };
  });
};

ImageComponent.propTypes = {
  onRejectPhoto: PropTypes.func.isRequired,
  photo: PropTypes.object.isRequired,
};

export default ImageComponent;
