import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CModal,
  CModalBody,
  CButton,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import * as errorModalActions from '../store/actions/errorModalActions';
import * as authActions from '../store/actions/authActions';

const ErrorModalComponent = props => {
  const dispatch = useDispatch();
  const show = useSelector(state => state.error.show);
  const errorMessage = useSelector(state => state.error.errorMessage);

  const onCloseModalHandler = () => {
    dispatch(errorModalActions.hideModal());
    if (errorMessage === 'Session expired.') {
      dispatch(authActions.logout());
    }
  };

  return (
    <div style={{ width: 'auto' }}>
      <CModal show={show} onClose={() => onCloseModalHandler()} color="info">
        <CModalHeader style={{ width: '100%' }}>
          <CModalTitle>Oops!</CModalTitle>
        </CModalHeader>
        <CModalBody>{errorMessage}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => onCloseModalHandler()}>
            Okay
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default ErrorModalComponent;
