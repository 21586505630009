export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const showModal = errorMessage => {
  return dispatch => {
    dispatch({ type: SHOW_MODAL, show: true, errorMessage: errorMessage });
  };
};

export const hideModal = () => {
  return dispatch => {
    dispatch({ type: HIDE_MODAL });
  };
};
