import { CCard, CCol, CDataTable, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { CreateRoomType } from './CreateRoomType';
import { PreferenceOptions } from './RoomPreferenceOptions';

export const RoomTypesView = props => {
  const dispatch = useDispatch();
  const roomTypes = useSelector(state => state.rooms.roomTypes);
  const [tab, setTab] = useState('list');
  const [loading, setLoading] = useState(true);

  const getRoomTypes = async () => {
    setLoading(true);
    try {
      await dispatch(roomsActions.getRoomTypes());
    } catch (err) {
      console.error('err', err);
      dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoomTypes();
  }, []);

  const fields = [
    {
      key: 'id',
      label: 'ID',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'name',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'description',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
  ];

  return (
    <CCol md="12">
      {loading && <CSpinner />}

      <nav
        className="navbar navbar-expand navbar-light bg-light"
        style={{ paddingLeft: 0, backgroundColor: '#e3f2fd', fontSize: '1.4em' }}
      >
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto" style={{ paddingLeft: 0 }}>
            <li className={`nav-item ${tab === 'list' ? 'active' : ''}`}>
              <a
                className="nav-link"
                href="#"
                onClick={() => setTab('list')}
                style={{ paddingLeft: 0 }}
              >
                Room types
              </a>
            </li>
            <li className={`nav-item ${tab === 'options' ? 'active' : ''}`}>
              <a
                className="nav-link"
                href="#"
                onClick={() => setTab('options')}
                style={{ paddingLeft: 0 }}
              >
                Options
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {tab === 'list' && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2>Room Types</h2>

            <div>
              <CreateRoomType onChange={e => {}} />
            </div>
          </div>

          <CCard className={['pl-3'].join(' ')}>
            <CDataTable
              size="sm"
              items={roomTypes || []}
              fields={fields}
              hover
              sorter
              scopedSlots={{
                id: roomType => (
                  <td>
                    <Link
                      to={{
                        pathname: `/main/roomTypes/${roomType.id}`,
                      }}
                      className="link"
                      style={{ color: '#4A90E2' }}
                    >
                      {roomType.id}
                    </Link>
                  </td>
                ),
                name: roomType => <td>{roomType.name}</td>,
                description: roomType => <td>{roomType.description}</td>,
              }}
            />
          </CCard>
        </div>
      )}

      {tab === 'options' && <PreferenceOptions />}
    </CCol>
  );
};
