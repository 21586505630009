import statusEnum from '../utils/statusEnum';

class FlaggedUser {
  constructor(report) {
    this.userId = report.userId;
    this.name = report.name;
    this.profilePhoto = report.imageUrl
      ? report.imageUrl.url
      : 'https://cdn4.iconfinder.com/data/icons/jetflat-2-faces-2/60/005b_042_user_profile_avatar_man_boy_round-512.png';
    this.reportId = report.reportId;
    this.reportReason = report.reportReason;
    this.reportDatetime = report.reportDatetime;
    this.reporterId = report.reporterId;
    this.reporterName = report.reporterName;
    this.uniqueFlags = report.uniqueFlags ?? 0;
    this.repeatedFlags = report.repeatedFlags ?? 0;
    this.about = report.about;
    this.status = statusEnum[report.status];
    this.previousState =
      statusEnum[report.status] === statusEnum[1] ? statusEnum[0] : statusEnum[report.status];
  }

  get getReportDatetime() {
    return DateFormatter(this.reportDatetime);
  }
}

const DateFormatter = date => {
  if (!date) {
    return '';
  }

  let formattedDate = new Date(date);

  let day = ('0' + formattedDate.getDate()).slice(-2);
  let month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
  let year = formattedDate.getFullYear();
  let hour = formattedDate.getHours();
  let minutes = ('0' + formattedDate.getMinutes()).slice(-2);
  let AMPM = 'AM';

  if (parseInt(hour) > 12) {
    AMPM = 'PM';
    const newHour = hour - 12;
    hour = newHour;
  } else if (hour === 0) {
    hour = '12';
  }

  // returns date in MM-DD-YYYY HH:MM AM/PM format
  return month + '/' + day + '/' + year + ' ' + hour + ':' + minutes + ' ' + AMPM;
};

export default FlaggedUser;
