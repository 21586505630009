import ApiService from "./ApiService";

export const importBatchSuggestions = async (file) => {
  if (!file) {
    return;
  }
  var formData = new FormData();
  formData.append('file', file);
  const response = await ApiService.post(
    "/manualsuggestions",
    formData,
    {
      timeout: 300000,
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );
  return response.data;
};

export const getBatchSuggestions = async () => {
  const response = await ApiService.get(
    "/manualsuggestions",
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const deleteAllBatchSuggestions = async () => {
  const response = await ApiService.delete(
    "/manualsuggestions",
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const getUserBatchSuggestions = async (userId) => {
  const response = await ApiService.get(
    `/manualsuggestions/${userId}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};