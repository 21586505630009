import CIcon from '@coreui/icons-react';
import React from 'react';

/**
 * Location pin component with a link to google maps.
 * prop location is optional {latitude, longitude}
 */
const LocationPin = ({ location }) => {
  if (location) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`}
        className="text-danger"
      >
        <CIcon height={28} name="cil-location-pin" />
      </a>
    );
  } else {
    return (
      <span className="text-secondary">
        <CIcon height={28} name="cil-location-pin" />
      </span>
    );
  }
};

export default LocationPin;
