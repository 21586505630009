class Text {
  constructor(text) {
    this.id = text.id;
    this.userId = text.userId;
    this.profilePhoto =
      text.images && text.images.length > 0
        ? text.images[0].url
        : 'https://cdn4.iconfinder.com/data/icons/jetflat-2-faces-2/60/005b_042_user_profile_avatar_man_boy_round-512.png';
    this.name = text.name;
    this.type = text.type;
    this.text = text.text;
    this.warnings = text.warnings;
    this.priorBans = text.priorBans;
    this.userStatus = text.userStatus;
    this.previousState = text.previousState;
    this.hiveResults = MapHiveResults(text.comment);
    this.rejectReasons = [];
    this.rejected = false;
  }
}

const MapHiveResults = comment => {
  if (!comment) {
    return [];
  }

  const commentJSON = JSON.parse(comment);

  let hiveCategories = Object.keys(commentJSON);

  return hiveCategories.map(hiveCategory => ({
    description: hiveCategory,
    score: commentJSON[hiveCategory],
  }));
};

export default Text;
