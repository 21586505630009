import {
  CSpinner,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';

export const CreatePreferenceOption = ({ onCreated }) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onCreate = async () => {
    const toastId = toast.loading('Creating...', toastDefaultConfig);
    setLoading(true);
    try {
      const rt = { name, description };
      await dispatch(roomsActions.createRoomPreferenceOption(rt));
      onCreated && onCreated(rt);
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Created!',
        type: 'success',
        isLoading: false,
      });
    } catch (err) {
      console.error('err', err);
      dispatch(errorModalActions.showModal(err.message));
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Error creating room type',
        type: 'error',
        isLoading: false,
      });
    }
    setLoading(false);
    setVisible(false);
  };

  return (
    <>
      <CButton
        color="primary"
        style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
        onClick={() => setVisible(!visible)}
      >
        Create
      </CButton>
      <CModal show={visible} onClose={() => setVisible(false)} aria-labelledby="AddRoomType">
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">New room preference option</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="mb-3">
            <label for="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="new option"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label for="description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              placeholder="new option description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Cancel
          </CButton>
          {loading && <CSpinner />}
          <CButton color="primary" onClick={onCreate} disabled={loading || !name || !description}>
            Create
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};
