import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * props.selection: [..selected {id} items]
 * props.onChange(selection)
 * props.profileFieldId
 * */
export const TagSelect = props => {
  const tags = (useSelector(state => state.tags.tags) || []).filter(
    t => t.profileFieldId === props.profileFieldId
  );
  const [selection, setSelection] = useState([...props.selection]);

  const onChange = po => {
    const selected = !!selection.find(o => o.id === po.id);
    let newSelection = selection;
    if (selected) {
      newSelection = selection.filter(o => o.id !== po.id);
    } else {
      newSelection = [...selection, po];
    }

    props.onChange(newSelection);
    setSelection(newSelection);
  };

  return (
    <div style={{ width: 400, overflow: 'scroll' }}>
      {tags.map(tag => {
        const selected = selection.find(p => p.id === tag.id);
        return (
          <button
            type="button"
            style={{ borderRadius: 50, padding: 4 }}
            class={`btn ${selected ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => onChange(tag)}
          >
            {tag.name}
          </button>
        );
      })}
    </div>
  );
};
