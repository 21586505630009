import React from 'react';
import { Link } from 'react-router-dom';
import { RoomAddUsers } from './RoomAddUsers';
import { RoomUserType } from './RoomTypes';

/**
 * 'type' is RoomUserType
 */
export const RoomUsers = ({ room, users, type, onDeleteUser, deleteUserDisabled }) => {
  users = users || [];
  return (
    <div>
      {type !== RoomUserType.current && (
        <div>
          <h5>Add {type} users</h5>
          <RoomAddUsers room={room} type={type} />
        </div>
      )}

      {!users.length ? (
        <p style={{ marginTop: 10 }}>No {type} users in this room.</p>
      ) : (
        <div style={{ marginTop: 10 }}>
          <h5>List of {type} users</h5>
          <div>Total: {users.length}</div>
          <table className="table" style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <th scope="col">userId</th>
                <th scope="col">Name</th>
                <th scope="col">Join Date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr>
                  <td>
                    <Link to={{ pathname: `/main/profile/${user.userId}` }}>{user.userId}</Link>
                  </td>
                  <td>{user.name}</td>
                  <td>{user.joinDate}</td>
                  <td>
                    <button
                      type="button"
                      disabled={deleteUserDisabled}
                      style={{ borderRadius: 50, padding: 4 }}
                      class={'btn btn-danger'}
                      onClick={() => onDeleteUser(user.userId)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
