import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as roomsActions from '../../../store/actions/roomsActions';
import { moveItemDown, moveItemUp } from '../../../utils/utils';
import { Field } from '../../rooms/RoomCommonComponents';
import { AddTag } from '../AddTag';
import { FieldOption } from './FieldOption';
import { ProfileTagFieldIds } from '../../../models/profileTagFieldIds';

/**
 * props: field , roomTypeId, onChange
 */
export const TagField = props => {
  const dispatch = useDispatch();
  const { options, profileFieldId } = props.field;
  const roomTypes = useSelector(state => state.rooms.roomTypes);

  const onChange = data => {
    const roomType = roomTypes.find(rt => rt.id === props.roomTypeId);
    //  heads up: we filter by name since until saved there are no ids.
    const field = roomType.schema.fields.find(f => f.name === props.field.name);
    field.options = data;

    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  const onOptionMove = ({ direction, index }) => {
    const roomType = roomTypes.find(rt => rt.id === props.roomTypeId);
    // heads up: checking by name
    const field = roomType.schema.fields.find(f => f.name === props.field.name);
    if (direction === 'up') {
      moveItemUp(field.options, index);
    }
    if (direction === 'down') {
      moveItemDown(field.options, index);
    }
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  const onTypeChange = newProfileFieldId => {
    if (options.length && !window.confirm('Changing profile field will delete current options')) {
      return;
    }
    const roomType = roomTypes.find(rt => rt.id === props.roomTypeId);
    const field = roomType.schema.fields.find(f => f.id === props.field.id);
    field.profileFieldId = newProfileFieldId;
    field.options = [];
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  return (
    <div>
      <Field {...props.fieldProps(props.field, 'name')} editable useOnBlur />

      {/* TODO use ProfileTagFieldIds select editor */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
          profileFieldId
        </label>

        <select
          className="form-select form-control"
          value={profileFieldId}
          onChange={e => {
            onTypeChange(parseInt(e.target.value));
          }}
        >
          {Object.keys(ProfileTagFieldIds).map(fieldName => (
            <option
              selected={profileFieldId === ProfileTagFieldIds[fieldName]}
              value={ProfileTagFieldIds[fieldName]}
            >
              {fieldName}
            </option>
          ))}
        </select>
      </div>

      <Field {...props.fieldProps(props.field, 'description')} editable useOnBlur />
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <strong>Tags</strong>
          <div>
            <AddTag
              selection={options}
              onChange={onChange}
              profileFieldId={props.field.profileFieldId}
            />
          </div>
        </div>

        <ul>
          {options.map((o, index) => (
            <li>
              <FieldOption
                option={o}
                index={index}
                onOptionMove={onOptionMove}
                options={options}
                isTag={true}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
