import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Config from './configs/Config';
import MainContainer from './containers/MainContainer';
import store from './store/Store';
import './styles/style.scss';
import { dispatchUrl, setUrlFromState } from './utils/urlState';
import AuthView from './views/Login/AuthVIew';
import LoginView from './views/Login/LoginView';
import mixpanel from 'mixpanel-browser';

const App = props => {
  // TODO: manage global registering differently so we prevent a second request.
  // idea: execute before this class: extract url params and just update state, then let to run default action
  useEffect(() => {
    // when app loads we read url
    // TODO : this will trigger a second request/render
    setTimeout(() => {
      dispatchUrl();
    }, 200);

    // each time store change we update url
    setTimeout(() => {
      store.subscribe(() => {
        setUrlFromState();
      });
    }, 400);
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      mixpanel.init(Config.mixpanelToken, { verbose: true, debug: true, ip: false });
    }
  });
  return (
    <Switch>
      <Route exact path="/" name="Login Page" render={props => <LoginView {...props} />} />
      <Route
        path="/redirect"
        name="Redirect"
        render={() => (window.location.href = `${Config.backendUrl}auth/google`)}
      />
      <Route path="/main" name="Main" render={props => <MainContainer {...props} />} />
      <Route
        path="/auth/google/callback"
        name="Authentication"
        render={props => <AuthView {...props} />}
      />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default App;
