import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * props.preferenceOptions: [all of them]
 * props.selection: [..selected {id} items]
 * props.onChange(selection)
 * */
export const PreferenceOptionSelect = props => {
  const roomPreferenceOptions = useSelector(state => state.rooms.roomPreferenceOptions);
  const [selection, setSelection] = useState([...props.selection]);

  const onChange = po => {
    const selected = selection.find(o => o.id === po.id);
    let newSelection = selection;
    if (selected) {
      newSelection = selection.filter(o => o.id !== po.id);
    } else {
      newSelection = [...selection, po];
    }
    props.onChange(newSelection);
    setSelection(newSelection);
  };

  return (
    <div style={{ width: 400, overflow: 'scroll' }}>
      {roomPreferenceOptions.map(po => {
        const selected = selection.find(p => p.id === po.id);
        return (
          <button
            type="button"
            style={{ borderRadius: 50, padding: 4 }}
            class={`btn ${selected ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => onChange(po)}
          >
            {po.name}
          </button>
        );
      })}
    </div>
  );
};
