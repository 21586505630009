import { CButton } from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { downloadFile, humanFileSize } from '../../utils/utils';
import { RoomUserType } from './RoomTypes';

export const RoomAddUsers = ({ room, type }) => {
  const baseButton = { maxWidth: '200px' };
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [report, setReport] = useState(null);
  const handleFileChange = e => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setFile(file);
      setReport(null);
    }
  };

  function template() {
    downloadFile(
      `
userId
4a809e24-a954-4a67-9918-cd4f2fb0df07
0b669417-86f0-46d7-830f-7d7bc6a02fa6
4a809e24-a954-4a67-9918-cd4f2fb0df07
ed4f3725-7a5a-4e2b-8a71-7e0b8ec54c6b
  `.trim(),
      'motto-room-users-template.csv',
      'text/csv'
    );
  }

  const handleUploadClick = async () => {
    if (file.size > 3000000) {
      alert('Cannot upload files bigger than 3 MB. Aborting');
      return;
    }
    try {
      if (type === RoomUserType.invited || type === RoomUserType.host) {
        const results = await roomsActions.importRoomUsers(room.id, file, type);
        setReport(results);
      }
      // else if (type === RoomUserType.host) {
      //   alert('not impl')
      // }
      else {
        alert('not impl');
      }
      await dispatch(roomsActions.getRoom(room.id));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  return (
    <div>
      <p>
        Provide a CSV file like{' '}
        <a href="" onClick={template}>
          this template
        </a>{' '}
        in order to add {type} users.
      </p>
      <div className="form-group">
        <input
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          onChange={handleFileChange}
        />
        <div>{file && `${humanFileSize(file.size)} ${file.type}`}</div>
      </div>
      {file && (
        <CButton color="primary" style={{ ...baseButton }} onClick={handleUploadClick}>
          Import
        </CButton>
      )}

      {report && (
        <div>
          <h4 style={{ marginTop: '20px' }}>Import results</h4>

          <h5>Failures ({report.results.filter(r => r.status === 'fail').length})</h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" style={{ width: '50%' }}>
                  Reason
                </th>
                <th scope="col">Body</th>
              </tr>
            </thead>
            <tbody>
              {(report.results || [])
                .filter(r => r.status === 'fail')
                .map((r, i) => (
                  <tr key={i}>
                    <th scope="row">{i}</th>
                    <td>{r.failureReason}</td>
                    <td>
                      <ul style={{ padding: 0 }}>
                        {columns.map(c => (
                          <li style={{ display: 'inline' }}>
                            <strong>{c}</strong>:{' '}
                            <span style={{ marginRight: 8 }}>{r.entry[c]}</span>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <h5>Success ({report.results.filter(r => r.status === 'success').length})</h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                {columns.map(c => (
                  <th scope="col">{c}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {report.results
                .filter(r => r.status === 'success')
                .map((r, i) => (
                  <tr key={i}>
                    <th scope="row">{i}</th>
                    {columns.map(c => (
                      <td>{r.entry[c]}</td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const columns = ['userId'];
