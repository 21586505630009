import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as authActions from '../../store/actions/authActions';

const AuthView = props => {
  const dispatch = useDispatch();

  const authHandler = async () => {
    try {
      await dispatch(authActions.login('auth/google/success'));
      props.history.replace('/main');
    } catch (err) {
      props.history.replace('/');
    }
  };

  useEffect(() => {
    authHandler();
    // eslint-disable-next-line
  }, []);

  return <div></div>;
};

export default withRouter(AuthView);
