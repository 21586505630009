const clearFieldsEnum = Object.freeze({
  0: 'UserName',
  1: 'About',
  2: 'Instagram',
  3: 'Twitter',
  4: 'Snapchat',
  5: 'TikTok',
});

export default clearFieldsEnum;
