import React from 'react';
import { RoomTypeField } from '../../rooms/RoomTypes';
import { ListField } from './ListField';
import { TagField } from './TagField';

/** props: field, roomTypeId, onChange */
export const FieldComponent = props => {
  const { type, name, description, options, profileFieldId } = props.field;
  let Body;
  if (type === RoomTypeField.list) {
    Body = () => <ListField {...props} />;
  } else if (type === RoomTypeField.tag) {
    Body = () => <TagField {...props} />;
  } else {
    Body = () => (
      <div>
        <div>name: {name}</div>
        <div>type: {type} (not supported)</div>
        <div>description: {description}</div>
      </div>
    );
  }

  return (
    <div>
      <Body />
    </div>
  );
};
