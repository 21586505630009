import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CRow, CCol, CCard, CCardFooter, CSpinner, CButton, CCardHeader } from '@coreui/react';
import { SectionTitle } from '../../../styles/views/FlagQueueView.module.css';
import BanUnbanPopUp from '../../../components/BanUnbanPopUp';
import CloseFlagsPopUp from '../../../components/CloseFlagsPopUp';
import FlagsTable from '../../../components/tables/FlagsTable';
import statusEnum from '../../../utils/statusEnum';
import * as flagsActions from '../../../store/actions/flagsActions';
import * as errorModalActions from '../../../store/actions/errorModalActions';

const FlagQueueView = props => {
  const dispatch = useDispatch();
  const flaggedUsers = useSelector(state => state.flags.flaggedUsers);
  const [isFetching, SetIsFetching] = useState(true);
  const [isBanUnbanModalOpen, setIsBanUnbanModalOpen] = useState(false);
  const [isCloseFlagsModalOpen, setIsCloseFlagsModalOpen] = useState(false);
  const [banUnbanParameters, setBanUnbanParameters] = useState(initialBanUnbanState);
  const [bannedUsers, setBannedUsers] = useState([]);

  const fetchFlaggedUsersHandler = async () => {
    try {
      await dispatch(flagsActions.getFlaggedUsers());
      setBannedUsers(filterBannedUsers(flaggedUsers));
    } catch (err) {
      dispatch(errorModalActions.showModal(err.message));
    }
    SetIsFetching(false);
  };

  useEffect(() => {
    fetchFlaggedUsersHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBannedUsers(filterBannedUsers(flaggedUsers));
  }, [flaggedUsers]);

  const openBanUnbanModal = userId => {
    setBanUnbanParameters({
      userId: userId,
      isBanned: bannedUsers.includes(userId) ? true : false,
    });
    setIsBanUnbanModalOpen(true);
  };

  const toggleBan = async () => {
    try {
      const userId = banUnbanParameters.userId;
      const position = bannedUsers.indexOf(userId);
      let newBannedUsers = bannedUsers.slice();
      if (position !== -1) {
        newBannedUsers.splice(position, 1);
        await dispatch(flagsActions.unbanUser(userId));
      } else {
        newBannedUsers = [...bannedUsers, userId];
        await dispatch(flagsActions.banUser(userId));
      }
      setBannedUsers(newBannedUsers);
      setBanUnbanParameters(initialBanUnbanState);
      setIsBanUnbanModalOpen(!isBanUnbanModalOpen);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const closeBanUnbanModal = () => {
    setIsBanUnbanModalOpen(false);
  };

  const closaAllHandler = async () => {
    SetIsFetching(true);
    try {
      await dispatch(flagsActions.closeFlags());
      await dispatch(flagsActions.getFlaggedUsers());
    } catch (err) {
      dispatch(errorModalActions.showModal(err.message));
    }
    SetIsFetching(false);
  };

  const openCloseFlagsModal = () => {
    setIsCloseFlagsModalOpen(true);
  };

  const closeCloseFlagsModal = () => {
    setIsCloseFlagsModalOpen(false);
  };

  const onCloseFlags = async () => {
    await closaAllHandler();
    setIsCloseFlagsModalOpen(false);
  };

  return (
    <>
      <CRow>
        {isFetching ? (
          <div className="d-flex flex-row justify-content-center w-100">
            <CSpinner color="info" />
          </div>
        ) : (
          <>
            <CCol md="12">
              <CCard className="mb-0 border-bottom-0">
                <CCardHeader className="ml-3 border-bottom-0">
                  <CRow className="mb-2">
                    <div className={SectionTitle}>Flag Queue</div>
                  </CRow>
                </CCardHeader>
              </CCard>
            </CCol>
            <CCol md="12">
              <CCard className="border-top-0 rounded-0 pl-3">
                <FlagsTable
                  {...props}
                  bannedUsers={bannedUsers}
                  flaggedUsers={flaggedUsers}
                  openBanUnbanModal={openBanUnbanModal}
                />
                <CCardFooter>
                  <div className="d-flex flex-row justify-content-end">
                    <CButton color="info" onClick={() => openCloseFlagsModal()}>
                      Close All
                    </CButton>
                  </div>
                </CCardFooter>
              </CCard>
            </CCol>
            <CloseFlagsPopUp
              isCloseFlagsModalOpen={isCloseFlagsModalOpen}
              closeCloseFlagsModal={closeCloseFlagsModal}
              onCloseFlags={onCloseFlags}
            />
            <BanUnbanPopUp
              isBanned={banUnbanParameters.isBanned}
              isBanUnbanModalOpen={isBanUnbanModalOpen}
              closeBanUnbanModal={closeBanUnbanModal}
              toggleBan={toggleBan}
            />
          </>
        )}
      </CRow>
    </>
  );
};

const filterBannedUsers = users => {
  return users.reduce(
    (acc, user) => (user.status === statusEnum[1] ? [...acc, user.userId] : acc),
    []
  );
};

const initialBanUnbanState = {
  userId: '',
  isBanned: false,
};

export default FlagQueueView;
