import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UsersTable from '../../components/tables/UsersTable';
import * as usersActions from '../../store/actions/usersActions';
import * as errorModalActions from '../../store/actions/errorModalActions';
import { urlDispatched } from '../../utils/urlState';

const UserListView = props => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.users);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    urlDispatched.then(() => {
      setLoading(false);
    });
  }, []);

  const onSearchChange = async search => {
    try {
      setLoading(true);
      await dispatch(usersActions.getUsers({ search }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onStatusChange = async status => {
    try {
      setLoading(true);
      await dispatch(usersActions.getUsers({ status }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onActivePlaceChange = async activePlaces => {
    try {
      setLoading(true);
      await dispatch(usersActions.getUsers({ activePlaces }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onReferredChange = async referred => {
    try {
      setLoading(true);
      await dispatch(usersActions.getUsers({ referred }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onOrderChange = async event => {
    const { column, asc } = event;
    if (typeof column === 'undefined' && typeof asc === 'undefined') {
      if (urlDispatched.status === 'resolved') {
        setLoading(false);
      }
      return;
    }
    try {
      setLoading(true);
      await dispatch(
        usersActions.getUsers({
          orderBy: column || 'created',
          orderDirection: asc ? 'asc' : 'desc',
        })
      );
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onPageChange = async page => {
    try {
      setLoading(true);
      await dispatch(usersActions.getUsers({ page: page - 1 }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onLocationChange = async location => {
    try {
      setLoading(true);
      await dispatch(usersActions.getUsers(location));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  return (
    <UsersTable
      {...props}
      users={users}
      onSearchChange={onSearchChange}
      onStatusChange={onStatusChange}
      onReferredChange={onReferredChange}
      onOrderChange={onOrderChange}
      onPageChange={onPageChange}
      onLocationChange={onLocationChange}
      onActivePlaceChange={onActivePlaceChange}
      loading={loading}
    />
  );
};

export default UserListView;
