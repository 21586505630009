export const ethnicityFieldIds = {
  ethnicity: 9,
  ethnicityAdmin: 10,
};

export const ProfileTagFieldIds = {
  hosting: 1,
  sexualInterests: 2,
  pronouns: 3,
  gender: 4,
  relationship: 5,
  lookingFor: 6,
  interestedIn: 7,
  intentions: 8,
  ...ethnicityFieldIds,
};
