import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CInput, CButton } from '@coreui/react';
import 'react-toastify/dist/ReactToastify.css';
import { Title, ContentContainer } from '../styles/components/ProfileGenerateRequest.module.css';
import { ConfirmRequestGenerationPopup } from './ConfirmRequestGenerationPopup';
import { userIdPattern } from '../constants/regex';
import { sendReactionRequest } from '../store/actions/requestActions';

export const ProfileGenerateRequest = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.profile);
  const [receiverId, setReceiverId] = React.useState(null);
  const [modalVisibility, toggleModalVisibility] = React.useState(false);

  return (
    <>
      <h4 className={Title}>Generate request</h4>
      <div className={ContentContainer}>
        <CInput
          id="requested-user"
          placeholder="Insert user id"
          onChange={event => setReceiverId(event.target.value)}
        />
        <CButton
          onClick={() => toggleModalVisibility(!modalVisibility)}
          color="dark"
          disabled={
            !receiverId || !receiverId.match(userIdPattern) || profile.userId === receiverId
          }
        >
          Send
        </CButton>
      </div>
      <ConfirmRequestGenerationPopup
        receiverId={receiverId}
        onConfirm={() => dispatch(sendReactionRequest(receiverId))}
        toggleModalVisibility={() => toggleModalVisibility(!modalVisibility)}
        modalVisibility={modalVisibility}
      />
    </>
  );
};
