import moment from 'moment';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';
import Profile from '../../models/profile';
import ProfileModeration from '../../models/requests/ProfileModeration';
import ApiService from '../../services/ApiService';
import clearFieldsEnum from '../../utils/clearFieldsEnum';
import { PROFILE_IMAGE_STATUS } from '../../utils/statusEnum';
var mixpanel = require('mixpanel-browser');

export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_NAME = 'CLEAR_NAME';
export const SPOOF_LOCATION = 'SPOOF_LOCATION';
export const SET_MAX_REFERRALS = 'SET_MAX_REFERRALS';
export const CLEAR_ABOUT_ME = 'CLEAR_ABOUT_ME';
export const CLEAR_INSTAGRAM = 'CLEAR_INSTRAGRAM';
export const CLEAR_TWITTER = 'CLEAR_TWITTER';
export const CLEAR_SNAPCHAT = 'CLEAR_SNAPCHAT';
export const CLEAR_TIKTOK = 'CLEAR_TIKTOK';
export const BAN_USER = 'BAN_USER';
export const UNBAN_USER = 'UNBAN_USER';
export const DELETE_USER = 'DELETE_USER';
export const REJECT_USER_PHOTO = 'REJECT_USER_PHOTO';
export const REJECT_USER_SELFIE = 'REJECT_USER_SELFIE';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const APPROVE_ALL_PHOTOS = 'APPROVE_ALL_PHOTOS';
export const APPROVE_USER_PHOTO = 'APPROVE_USER_PHOTO';
export const APPROVE_USER_SELFIE = 'APPROVE_USER_SELFIE';
export const EDIT_BIRTH_DATE = 'EDIT_BIRTH_DATE';
export const EDIT_ETHNICITY = 'EDIT_ETHNICITY';
export const EDIT_SCORE = 'EDIT_SCORE';
export const UPDATE_USER_FEATURES = 'UPDATE_USER_FEATURES';

export const banUser = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.post('ban', { userId: userId }, { withCredentials: true });
    dispatch({ type: BAN_USER });
  };
};

export const unbanUser = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.delete('ban', {
      data: { userId: userId },
      withCredentials: true,
    });
    dispatch({ type: UNBAN_USER });
  };
};

export const deleteUser = userId => {
  return async dispatch => {
    await ApiService.delete(`profiles/${userId}`, {
      data: { userId: userId },
      withCredentials: true,
    });
    dispatch({ type: DELETE_USER });
  };
};

export const clearName = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.put(
      `profiles/resetfield`,
      { userId: userId, field: `${clearFieldsEnum[0]}` },
      { withCredentials: true }
    );

    dispatch({ type: CLEAR_NAME });
  };
};

export const spoofLocation = (userId, location, forceLocation) => {
  const [latitude, longitude] = location.split(',').map(s => parseFloat(s.trim()));
  if (isNaN(latitude) || isNaN(longitude) || location.split(',').length !== 2) {
    throw {
      message: `Invalid expression. Please use two numbers latitude,longitude comma separated. You can copy them from google maps URL`,
    };
  }
  location = { latitude, longitude };
  return async dispatch => {
    const response = await ApiService.put(
      `profiles/forceLocation`,
      { userId, location: forceLocation ? location : undefined },
      { withCredentials: true }
    );
    dispatch({ type: SPOOF_LOCATION });
  };
};

export const setMaxReferrals = (userId, maxReferrals) => {
  return async dispatch => {
    const response = await ApiService.put(
      `profiles/maxReferrals`,
      { userId, maxReferrals },
      { withCredentials: true }
    );
    dispatch({ type: SET_MAX_REFERRALS });
  };
};

export const clearAboutMe = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.put(
      `profiles/resetfield`,
      { userId: userId, field: `${clearFieldsEnum[1]}` },
      { withCredentials: true }
    );

    dispatch({ type: CLEAR_ABOUT_ME });
  };
};

export const editBirthDate = (userId, birthDate) => {
  return async dispatch => {
    await ApiService.put(
      `profiles/birthdate`,
      { userId, birthDate: moment(birthDate).utc() },
      { withCredentials: true }
    );
    dispatch({ type: EDIT_BIRTH_DATE, birthDate });
  };
};

export const clearInstagram = userId => {
  return async dispatch => {
    await ApiService.put(
      `profiles/resetfield`,
      {
        userId: userId,
        field: `${clearFieldsEnum[2]}`,
      },
      { withCredentials: true }
    );

    dispatch({
      type: CLEAR_INSTAGRAM,
    });
  };
};

export const clearTwitter = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.put(
      `profiles/resetfield`,
      {
        userId: userId,
        field: `${clearFieldsEnum[3]}`,
      },
      { withCredentials: true }
    );

    dispatch({
      type: CLEAR_TWITTER,
    });
  };
};

export const clearSnapChat = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.put(
      `profiles/resetfield`,
      {
        userId: userId,
        field: `${clearFieldsEnum[4]}`,
      },
      { withCredentials: true }
    );

    dispatch({
      type: CLEAR_SNAPCHAT,
    });
  };
};

export const clearTikTok = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    const response = await ApiService.put(
      `profiles/resetfield`,
      {
        userId: userId,
        field: `${clearFieldsEnum[5]}`,
      },
      { withCredentials: true }
    );

    dispatch({
      type: CLEAR_TIKTOK,
    });
  };
};

export const getProfile = userId => {
  return async dispatch => {
    const response = await ApiService.get(`profiles/profile?userId=${userId}`, {
      withCredentials: true,
    });

    const { profile, referralInfo } = response.data;

    const mappedProfile = new Profile(profile);

    dispatch({
      type: GET_PROFILE,
      profile: mappedProfile,
      referralInfo,
    });
  };
};

/**
 * @param moderation Example: `[{mediaId: '', status: PROFILE_IMAGE_STATUS.REJECTED, reasons: [IMAGE_REJECTION_REASONS[0].id]}]`
 */
const moderatePhotoService = async moderation => {
  moderation = Array.isArray(moderation) ? moderation : [moderation];
  moderation = moderation.map(m => ({
    mediaId: m.mediaId,
    status: m.status,
    reasons: m.reasons,
  }));
  await ApiService.put(`images`, { media: moderation }, { withCredentials: true });
};

export const rejectPhoto = (media, reasons) => {
  return async dispatch => {
    try {
      await moderatePhotoService({ ...media, status: PROFILE_IMAGE_STATUS.REJECTED, reasons });
      dispatch({
        type: REJECT_USER_PHOTO,
        photoId: media.id,
        reasons: reasons,
      });
    } catch (error) {
      console.error('Reject photo error', error);
    }
  };
};

export const rejectSelfie = (media, reasons) => {
  return async dispatch => {
    try {
      await moderatePhotoService({ ...media, status: PROFILE_IMAGE_STATUS.REJECTED, reasons });
      dispatch({
        type: REJECT_USER_SELFIE,
        photoId: media.id,
        reasons: reasons,
      });
    } catch (error) {
      console.error('Reject selfie error', error);
    }
  };
};

export const updateUserStatus = (statusId, oldStatus, newStatus, moderatorEmail) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    const userProfile = currentState.profile.profile;
    const profileModeration = new ProfileModeration(userProfile, statusId);
    ApiService.put(`profiles/status`, profileModeration, { withCredentials: true })
      .then(response => {
        if (response && response.status === 200) {
          updateMixpanel(userProfile.userId, newStatus, oldStatus, moderatorEmail);
        }
        dispatch({
          type: UPDATE_USER_STATUS,
          statusId: statusId,
        });
      })
      .catch(error => console.error('Update user status error', error));
  };
};

const updateMixpanel = async (userId, newStatus, oldStatus, moderatorEmail) => {
  if (process.env.NODE_ENV !== 'development') {
    mixpanel.identify(userId);
    mixpanel.people.set('external_user_id', userId);
    mixpanel.track('admin_user_status_update', {
      newStatus: newStatus,
      oldStatus: oldStatus,
      moderatorEmail: moderatorEmail,
    });
  }
};

export const approvePhoto = media => {
  return async dispatch => {
    try {
      await moderatePhotoService({ ...media, status: PROFILE_IMAGE_STATUS.APPROVED });
      dispatch({
        type: APPROVE_USER_PHOTO,
        photoId: media.id,
      });
    } catch (error) {
      console.error('Approve photo error', error);
    }
  };
};

export const approveSelfie = media => {
  return async dispatch => {
    try {
      await moderatePhotoService({ ...media, status: PROFILE_IMAGE_STATUS.APPROVED });
      dispatch({
        type: APPROVE_USER_SELFIE,
      });
    } catch (error) {
      console.error('Approve selfie error', error);
    }
  };
};

export const approveAllPhotos = () => {
  return async (dispatch, getState) => {
    const profile = getState().profile.profile;
    const moderation = [...profile.photos, profile.selfieVerification].map(media => ({
      ...media,
      status: PROFILE_IMAGE_STATUS.APPROVED,
    }));
    try {
      await moderatePhotoService(moderation);
      dispatch({
        type: APPROVE_ALL_PHOTOS,
      });
    } catch (error) {
      console.error('Approve all photos error', error);
    }
  };
};

export const changeEthnicity = ethnicity => {
  return async (dispatch, getState) => {
    const profile = getState().profile.profile;
    try {
      await ApiService.put(
        `profiles/${profile.userId}/ethnicity`,
        { ethnicity },
        { withCredentials: true }
      );
      dispatch({
        type: EDIT_ETHNICITY,
        ethnicityAdmin: ethnicity,
      });
    } catch (error) {
      console.error('Approve all photos error', error);
    }
  };
};

export const changeScore = score => {
  return async (dispatch, getState) => {
    const profile = getState().profile.profile;
    try {
      await ApiService.put(
        `profiles/${profile.userId}/score`,
        { score },
        { withCredentials: true }
      );
      dispatch({
        type: EDIT_SCORE,
        score,
      });
    } catch (error) {
      console.error('Change score error', error);
    }
  };
};

export const setUserFeatures = (userId, features) => {
  const toastId = toast.loading('Saving...', toastDefaultConfig);
  return async (dispatch, getState) => {
    await ApiService.put(`profiles/${userId}/features`, { features }, { withCredentials: true });
    dispatch({
      type: UPDATE_USER_FEATURES,
      features,
    });
    toast.update(toastId, {
      ...toastDefaultConfig,
      render: 'Saved!',
      type: 'success',
      isLoading: false,
    });
  };
};
