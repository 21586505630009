import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CCol,
  CRow,
  CButton,
  CSpinner,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CBadge,
} from '@coreui/react';
import {
  PhotoQueuesRows,
  Tabs,
  TabItem,
  TabItemActive,
  ApproveButton,
} from '../../../styles/views/PhotoQueuesView.module.css';
import ImageRow from '../../../components/ImageRow';
import NoImagesFoundComponent from '../../../components/NoImagesFoundComponent';
import * as photoModerationActions from '../../../store/actions/photoModerationActions';
import * as errorModalActions from '../../../store/actions/errorModalActions';

const PhotoQueuesView = props => {
  const dispatch = useDispatch();
  const photos = useSelector(state => state.photos.photos);
  const pendingPhotos = useSelector(state => state.photos.pendingPhotos);
  const photosToApprove = useSelector(state => state.photos.photosToApprove);
  const isFetching = useSelector(state => state.photos.isFetching);
  const [active, setActive] = useState(0);

  const nextHandler = async () => {
    try {
      await dispatch(photoModerationActions.sendReviewedImages());
      await dispatch(photoModerationActions.getImages());
    } catch (err) {
      if (isFetching) {
        await dispatch(photoModerationActions.switchFetchingStatus());
      }
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const onRejectPhoto = async (id, reasons) => {
    await dispatch(photoModerationActions.rejectPhoto(id, reasons));
  };

  const onUnrejectPhoto = async (id, reasons) => {
    await dispatch(photoModerationActions.unrejectPhoto(id));
  };

  return (
    <>
      <CTabs activeTab={active} onActiveTabChange={idx => setActive(idx)}>
        <CNav className={Tabs} variant="tabs">
          <CNavItem>
            <CNavLink className={active === 0 ? TabItemActive : TabItem}>
              Pending Photos{' '}
              <CBadge color="secondary" shape="pill">
                {pendingPhotos.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </CBadge>
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink className={active === 1 ? TabItemActive : TabItem}>Auto Rejected</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink className={active === 2 ? TabItemActive : TabItem}>
              Manually Rejected
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane>
            {photos.length <= 0 && !isFetching ? (
              <NoImagesFoundComponent />
            ) : (
              <div className={PhotoQueuesRows}>
                <ImageRow
                  photos={photos.slice(0, 5)}
                  onRejectPhoto={onRejectPhoto}
                  onUnrejectPhoto={onUnrejectPhoto}
                />
                <ImageRow
                  photos={photos.slice(5, 10)}
                  onRejectPhoto={onRejectPhoto}
                  onUnrejectPhoto={onUnrejectPhoto}
                />
              </div>
            )}
            <CRow>
              <CCol sm="12">
                <div className="d-flex flex-row justify-content-center m-5">
                  {isFetching ? (
                    <CSpinner color="info" />
                  ) : (
                    <CButton className={ApproveButton} color="info" onClick={() => nextHandler()}>
                      Approve {photosToApprove} Photos
                    </CButton>
                  )}
                </div>
              </CCol>
            </CRow>
          </CTabPane>
          <CTabPane>{`Auto Rejected View`}</CTabPane>
          <CTabPane>{`Manually Rejected View`}</CTabPane>
        </CTabContent>
      </CTabs>
    </>
  );
};

export default PhotoQueuesView;
