import React from 'react';
import { CFormGroup, CLabel, CTextarea } from '@coreui/react';
import PropTypes from 'prop-types';

const FormGroupComponent = props => {
  return (
    <CFormGroup>
      <CLabel>{props.label}</CLabel>
      <CTextarea
        className="bg-white"
        id={props.id}
        value={props.placeholder}
        disabled
        style={{ textOverflow: 'ellipsis' }}
      />
    </CFormGroup>
  );
};

FormGroupComponent.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default FormGroupComponent;
