import React from 'react';

export const RoomTypePreview = props => {
  const roomType = props.roomType;
  return (
    <div>
      <div>{roomType.name}</div>
      <ul>
        {roomType.fields.map(field => (
          <li>
            <FieldPreview {...field} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const FieldPreview = ({ type, name, description, options, profileFieldId }) => {
  return (
    <div>
      <div>
        <strong>{name}</strong> ({type})
      </div>
      <ul>
        {options.map(o => (
          <li>{o.name}</li>
        ))}
      </ul>
    </div>
  );
};

// TODO: tag preview, list preview, chips
