import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CLabel,
  CRow,
  CSpinner,
} from '@coreui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BanUnbanPopUp from '../../components/BanUnbanPopUp';
import ClearPopUp from '../../components/ClearPopUp';
import DeleteUserPopUp from '../../components/DeleteUserPopUp';
import UserProfileAboutPanel from '../../components/panels/UserProfileAboutPanel';
import UserProfileInfoPanel from '../../components/panels/UserProfileInfoPanel';
import UserProfileInformationPanel from '../../components/panels/UserProfileInformationPanel';
import UserProfilePhotoPanel from '../../components/panels/UserProfilePhotoPanel';
import ProfileNotFoundComponent from '../../components/ProfileNotFoundComponent';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as profileActions from '../../store/actions/profileActions';
import { updateUserStatus } from '../../store/actions/profileActions';
import { BackButton, TopShortCuts } from '../../styles/views/UserProfileView.module.css';
import statusEnum from '../../utils/statusEnum';
import { ImageStatus } from '../../models/Images';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault.js';
import { UserBatchSuggestions } from '../../views/batch/UserBatchSuggestions';

const MIN_PHOTOS_APPROVED = 3;

const UserProfileView = props => {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.profile);
  const [isFetching, SetIsFetching] = useState(true);
  // eslint-disable-next-line
  const [imagesAreChanging, setImagesAreChanging] = useState(false);
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const [clearParameters, setClearParameters] = useState(initialClearState);
  const [isBanUnbanModalOpen, setIsBanUnbanModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const [banUnbanParameters, setBanUnbanParameters] = useState(initialBanUnbanState);
  const [deleteUserParameters, setDeleteUserParameters] = useState({ userId: null });

  const fetchUserProfileHandler = async () => {
    try {
      await dispatch(profileActions.getProfile(props.match.params.userId));
    } catch (err) {
      dispatch(errorModalActions.showModal(err.message));
    }
    SetIsFetching(false);
  };

  useEffect(() => {
    fetchUserProfileHandler();
    // eslint-disable-next-line
  }, []);

  const disabledApproveOption = useMemo(() => {
    let sum = 0;
    if (!profile?.photos || profile?.photos?.length < 3) {
      return true;
    }
    profile.photos.forEach(photo => {
      if (photo.status === ImageStatus.approved) sum++;
    });
    return sum < MIN_PHOTOS_APPROVED;
  }, [profile?.photos]);

  const disabledStatusId = statusId => {
    return statusId == 0 && disabledApproveOption;
  };

  const onUserStatusChange = statusId => {
    const newStatus = statusEnum[statusId];
    //avoid trigger update if status was not changed
    if (disabledStatusId(statusId)) {
      return toast.error('At least 3 photos must be approved', {
        ...toastDefaultConfig,
        pauseOnHover: false,
      });
    }
    if (profile.status !== newStatus) {
      if (newStatus === statusEnum[1] || profile.status === statusEnum[1]) {
        openBanUnbanModal(profile.userId, profile.isBanned);
      } else {
        const moderator = getModeratorEmail();
        dispatch(updateUserStatus(statusId, profile.status, statusEnum[statusId], moderator));
      }
    }
  };

  function getModeratorEmail() {
    let currentModeratorData = localStorage.getItem('USER_DATA');
    if (currentModeratorData) {
      currentModeratorData = JSON.parse(currentModeratorData);
      return currentModeratorData.email;
    }
    return '';
  }

  const onRejectPhoto = async (photo, reasons) => {
    await dispatch(profileActions.rejectPhoto(photo, reasons));
  };
  const onApprovePhoto = async photo => {
    await dispatch(profileActions?.approvePhoto(photo));
  };

  const onRejectSelfie = async (photoId, reasons) => {
    await dispatch(profileActions.rejectSelfie(photoId, reasons));
  };
  const onApproveSelfie = async photo => {
    await dispatch(profileActions.approveSelfie(photo));
  };

  const openClearModal = (field, userId) => {
    setImagesAreChanging(true);
    setClearParameters({
      field: field,
      userId: userId,
    });
    setIsClearModalOpen(true);
  };

  const closeClearModal = () => {
    setIsClearModalOpen(false);
    setImagesAreChanging(false);
  };

  const openBanUnbanModal = (userId, isBanned) => {
    setBanUnbanParameters({
      userId,
      isBanned,
    });
    setIsBanUnbanModalOpen(true);
  };

  const closeBanUnbanModal = () => {
    setIsBanUnbanModalOpen(false);
  };

  const openDeleteUserModal = userId => {
    setIsDeleteUserModalOpen(true);
    setDeleteUserParameters({
      userId: userId,
    });
  };

  const closeDeleteUserModal = () => {
    setIsDeleteUserModalOpen(false);
  };

  const onClearText = async () => {
    const { field, userId } = clearParameters;
    try {
      switch (field) {
        case 'name':
          await dispatch(profileActions.clearName(userId));
          break;
        case 'about':
          await dispatch(profileActions.clearAboutMe(userId));
          break;
        case 'instagram':
          await dispatch(profileActions.clearInstagram(userId));
          break;
        case 'twitter':
          await dispatch(profileActions.clearTwitter(userId));
          break;
        case 'snapChat':
          await dispatch(profileActions.clearSnapChat(userId));
          break;
        case 'tikTok':
          await dispatch(profileActions.clearTikTok(userId));
          break;
        default:
          break;
      }
      setClearParameters(initialClearState);
      setIsClearModalOpen(!isClearModalOpen);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const toggleBan = async () => {
    try {
      const { userId, isBanned } = banUnbanParameters;
      if (userId) {
        if (isBanned) {
          await dispatch(profileActions.unbanUser(userId));
        } else {
          await dispatch(profileActions.banUser(userId));
        }
      }
      setBanUnbanParameters(initialBanUnbanState);
      setIsBanUnbanModalOpen(!isBanUnbanModalOpen);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const deleteUser = async () => {
    try {
      const { userId } = deleteUserParameters;
      await dispatch(profileActions.deleteUser(userId));
      window.history.back();
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setIsDeleteUserModalOpen(false);
  };

  const onApproveAll = async () => {
    await dispatch(profileActions.approveAllPhotos());
  };

  const { ethnicityAdmin } = useSelector(state => state.tags);
  const ethnicity = profile?.ethnicityAdmin?.map(e => e.id) || [];

  const onEthnicityChange = ({ target }) => {
    const selection = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    const value = selection.map(s => parseInt(s));
    dispatch(profileActions.changeEthnicity(value));
  };

  const onScoreChange = ({ target }) => {
    let value = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value)[0];
    if (value === null || `${value}`.toLowerCase() === 'blank') {
      value = null;
    }
    dispatch(profileActions.changeScore(value));
    target.closest('.dropdown-menu').classList.remove('show');
  };

  const userEthnicity = profile ? profile.ethnicity.map(e => e.name).join(', ') : '';

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'space-around',
        }}
      >
        <div>
          {props.location.state && props.location.state.prevLocation ? (
            <Link
              className={BackButton}
              to={{
                pathname: props.location.state.prevLocation,
                state: { prevLocation: props.location.pathname },
              }}
            >
              <CIcon name="cil-arrow-left" /> Back to Listing
            </Link>
          ) : null}
        </div>

        {profile && !isFetching ? (
          <div className={TopShortCuts}>
            {/* score */}
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '12px' }}>
              <CLabel style={{ marginBottom: 0 }}>Score</CLabel>
              <CDropdown title="Change user's score ">
                <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)', width: '70px' }}>
                  {`${profile.score === null ? 'blank' : profile.score}`}
                </CDropdownToggle>
                <CDropdownMenu style={{ padding: 0 }}>
                  <select
                    aria-label="Score"
                    id="score-select"
                    defaultValue={profile.score}
                    size={7}
                    onChange={onScoreChange}
                    style={{ width: '100%' }}
                  >
                    {[null, 0, 1, 2, 3, 4, 5].map(value => (
                      <option key={`${value}`} value={value}>
                        {value === null ? 'blank' : value}
                      </option>
                    ))}
                  </select>
                </CDropdownMenu>
              </CDropdown>
              {profile.yesScore && (
                <div style={{ fontSize: '0.8em' }}>
                  <strong>Auto</strong>: {(parseFloat(profile.yesScore) * 100).toFixed(0)}%
                </div>
              )}
            </div>

            {/* ethnicity */}
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '12px' }}>
              <CLabel style={{ marginBottom: 0 }}>Ethnicity</CLabel>
              <CDropdown key={ethnicityAdmin.length} title="Change user ethnicity (multiple)">
                <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                  {`Selected (${ethnicity.length}) ethnicit${ethnicity.length === 1 ? 'y' : 'ies'}`}
                </CDropdownToggle>
                <CDropdownMenu style={{ padding: 0 }}>
                  <select
                    aria-label="Ethnicity"
                    id="ethnicity-select"
                    multiple
                    defaultValue={ethnicity}
                    size={ethnicityAdmin.length}
                    onChange={onEthnicityChange}
                    style={{ width: '100%' }}
                  >
                    {ethnicityAdmin?.map(e => (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                </CDropdownMenu>
              </CDropdown>
              {userEthnicity && (
                <div style={{ fontSize: '0.8em' }}>
                  <strong>User</strong>: {userEthnicity}
                </div>
              )}
            </div>

            {/* approve all photos button */}
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: '12px' }}>
              <CLabel style={{ marginBottom: 0 }}>Photos</CLabel>
              <CButton
                className="btn btn-success"
                style={{
                  marginRight: '12px',
                }}
                onClick={onApproveAll}
              >
                Approve all
              </CButton>
            </div>

            {/* change status dropdown */}
            <CDropdown style={{ display: 'flex', flexDirection: 'column' }}>
              <CLabel style={{ marginBottom: 0 }}>Status</CLabel>
              <CDropdownToggle className="btn btn-primary dropdown-toggle">
                {profile.status}
              </CDropdownToggle>
              <CDropdownMenu>
                {Object.keys(statusEnum).map(status => (
                  <CDropdownItem
                    disabled={status == 7}
                    key={status}
                    active={profile.status === statusEnum[status]}
                    onClick={() => onUserStatusChange(status)}
                    style={{ color: disabledStatusId(status) && '#8a93a2' }}
                  >
                    {statusEnum[status]}
                  </CDropdownItem>
                ))}
              </CDropdownMenu>
            </CDropdown>
          </div>
        ) : null}
      </div>

      {/* users detail body (photos, info, etc) */}
      {isFetching ? (
        <div className="d-flex flex-row justify-content-center w-100">
          <CSpinner color="info" />
        </div>
      ) : (
        <>
          {!profile && !isFetching ? (
            <ProfileNotFoundComponent />
          ) : (
            <CRow>
              <CCol md="3" className="py-2">
                <UserProfileInfoPanel
                  profile={profile}
                  onRejectPhoto={onRejectSelfie}
                  openBanUnbanModal={openBanUnbanModal}
                  openDeleteUserModal={openDeleteUserModal}
                  openClearModal={openClearModal}
                  onApprovePhoto={onApproveSelfie}
                />
              </CCol>
              <CCol md="9">
                <CRow>
                  <CCol md="12" className="py-2">
                    <UserProfilePhotoPanel
                      onRejectPhoto={onRejectPhoto}
                      onApprovePhoto={onApprovePhoto}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" className="py-2">
                    <UserProfileAboutPanel profile={profile} openClearModal={openClearModal} />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" className="py-2">
                    <UserProfileInformationPanel profile={profile} />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" className="py-2">
                    <UserBatchSuggestions profile={profile} />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          )}
        </>
      )}
      <ClearPopUp
        isClearModalOpen={isClearModalOpen}
        closeClearModal={closeClearModal}
        onClearText={onClearText}
      />
      <BanUnbanPopUp
        isBanned={banUnbanParameters.isBanned}
        isBanUnbanModalOpen={isBanUnbanModalOpen}
        closeBanUnbanModal={closeBanUnbanModal}
        toggleBan={toggleBan}
      />
      <DeleteUserPopUp
        isDeleteUserModalOpen={isDeleteUserModalOpen}
        closeDeleteUserModal={closeDeleteUserModal}
        onAction={deleteUser}
      />
    </>
  );
};

const initialClearState = {
  field: '',
  userId: '',
};

const initialBanUnbanState = {
  userId: null,
  isBanned: false,
};

export default UserProfileView;
