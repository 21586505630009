import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { ProfileActionButton } from '../../components/ProfileActionButton';
import { Card } from '../../styles/views/UserProfileView.module.css';

const UserProfileAboutPanel = props => {
  return (
    <CCard className={Card}>
      <CCardHeader className="d-flex justify-content-between align-items-center border-0">
        <h3 className="m-0">About</h3>
        <div className="card-header-actions">
          <ProfileActionButton
            label="Clear"
            onClick={() => props.openClearModal('about', props.profile.userId)}
          />
        </div>
      </CCardHeader>
      <CCardBody>{props.profile.about}</CCardBody>
    </CCard>
  );
};

UserProfileAboutPanel.propTypes = {
  profile: PropTypes.object.isRequired,
  openClearModal: PropTypes.func.isRequired,
};

export default UserProfileAboutPanel;
