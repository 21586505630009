import {
  GET_PENDING_TEXT,
  BAN_PENDING_TEXT,
  UNBAN_PENDING_TEXT,
  REJECT_TEXT,
  UNREJECT_TEXT,
} from '../actions/textActions';
import statusEnum from '../../utils/statusEnum';

const initialState = {
  pendingText: [],
  moreUsersLeft: false,
  currentPage: 0,
};

export default (state = initialState, action) => {
  let updatedText;
  let index;

  switch (action.type) {
    case GET_PENDING_TEXT:
      return {
        ...state,
        ...{
          pendingText: action.pendingText,
          //   currentPage: action.currentPage,
          moreUsersLeft: action.moreUsersLeft,
        },
      };
    case BAN_PENDING_TEXT:
      updatedText = state.pendingText;
      index = findUserIndexById(updatedText, action.userId);
      updatedText[index].status = statusEnum[1];
      updatedText[index].priorBans = updatedText[index].priorBans + 1;
      return { ...state, ...{ pendingText: updatedText } };
    case UNBAN_PENDING_TEXT:
      updatedText = state.pendingText;
      index = findUserIndexById(updatedText, action.userId);
      updatedText[index].status = updatedText[index].previousState;
      return { ...state, ...{ pendingText: updatedText } };
    case REJECT_TEXT:
      updatedText = state.pendingText;
      index = updatedText.findIndex(p => p.id === action.id);
      updatedText[index].rejected = true;
      updatedText[index].rejectReasons = action.reasons;
      return {
        ...state,
        ...{
          pendingText: updatedText,
        },
      };
    case UNREJECT_TEXT:
      updatedText = state.pendingText;
      index = updatedText.findIndex(p => p.id === action.id);
      updatedText[index].rejected = false;
      updatedText[index].rejectReasons = [];
      return {
        ...state,
        ...{
          pendingText: updatedText,
        },
      };
    default:
      return state;
  }
};

const findUserIndexById = (users, id) => {
  return users.findIndex(user => user.userId === id);
};
