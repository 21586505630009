import React from 'react';
import ImageComponent from './ImageComponent';
import PropTypes from 'prop-types';

const ImageRow = props => {
  return (
    <div className="d-flex flex-row justify-content-start">
      {props.photos.map(photo => (
        <ImageComponent
          key={photo.id}
          photo={photo}
          onRejectPhoto={props.onRejectPhoto}
          onUnrejectPhoto={props.onUnrejectPhoto}
        />
      ))}
    </div>
  );
};

ImageRow.propTypes = {
  photos: PropTypes.array.isRequired,
  onRejectPhoto: PropTypes.func.isRequired,
  onUnrejectPhoto: PropTypes.func.isRequired,
};

export default ImageRow;
