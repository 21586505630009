import { AUTHENTICATE, LOGOUT, TOGGLE_SIDE_BAR } from '../actions/authActions';

const initialState = {
  user: {
    id: null,
    displayName: null,
    email: null,
    profilePhoto: null,
  },
  session: {
    active: false,
  },
  sidebarShow: {
    minimized: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDE_BAR:
      return {
        ...state,
        ...{
          sidebarShow: {
            minimized: !state.sidebarShow.minimized,
          },
        },
      };
    case AUTHENTICATE:
      return {
        ...state,
        ...{ session: { active: action.isActive }, ...{ user: action.user } },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
