import { GET_USERS } from '../actions/usersActions';

const initialState = {
  users: [],
  moreUsersLeft: true,
  currentPage: 0,
  status: '',
  country: '',
  state: '',
  city: '',
  homeCountry: '',
  homeState: '',
  homeCity: '',
  search: '',
  referred: '',
  orderBy: '',
  orderDirection: '',
  total: 0,
  limit: 25,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        ...{
          users: action.users,
          currentPage: action.currentPage,
          moreUsersLeft: action.moreUsersLeft,
          status: action.status,
          activePlaces: action.activePlaces,
          referred: action.referred,
          country: action.country,
          state: action.state,
          city: action.city,
          homeCountry: action.homeCountry,
          homeState: action.homeState,
          homeCity: action.homeCity,
          search: action.search,
          orderBy: action.orderBy,
          orderDirection: action.orderDirection,
          total: action.total,
          limit: action.limit,
        },
      };
    default:
      return state;
  }
};
