import { CCard, CCol, CDataTable, CSpinner, CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { CreatePreferenceOption } from './CreatePreferenceOption';

export const PreferenceOptions = props => {
  const dispatch = useDispatch();
  const roomPreferenceOptions = useSelector(state => state.rooms.roomPreferenceOptions);
  console.log({ roomPreferenceOptions });
  const [loading, setLoading] = useState(true);

  const getRoomPreferenceOptions = async () => {
    setLoading(true);
    try {
      await dispatch(roomsActions.getRoomPreferenceOptions());
    } catch (err) {
      console.error('err', err);
      dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    getRoomPreferenceOptions();
  }, []);

  const fields = [
    {
      key: 'id',
      label: 'ID',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'name',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'description',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    // {
    //   key: "actions",
    //   _style: { width: "0%", fontSize: "0.75rem", borderTop: "0px" },
    // },
  ];

  return (
    <CCol md="12">
      {loading && <CSpinner />}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Preference options</h2>
        <div>
          <CreatePreferenceOption />
        </div>
      </div>

      <CCard className={['pl-3'].join(' ')}>
        <CDataTable
          size="sm"
          items={roomPreferenceOptions || []}
          fields={fields}
          hover
          sorter
          scopedSlots={{
            id: preferenceOption => (
              <td>
                <Link
                  to={{
                    pathname: `/main/preferenceOptions/${preferenceOption.id}`,
                  }}
                  className="link"
                  style={{ color: '#4A90E2' }}
                >
                  {preferenceOption.id}
                </Link>
              </td>
            ),
            name: preferenceOption => <td>{preferenceOption.name}</td>,
            description: preferenceOption => <td>{preferenceOption.description}</td>,
            // actions: preferenceOption => <div>
            //   <CButton color="danger" style={{ marginLeft: 8, marginBottom: 15 }}
            //   onClick={()=>{alert('not impl')}} size="sm">
            //     delete
            //   </CButton>
            // </div>
          }}
        />
      </CCard>
    </CCol>
  );
};
