import React from 'react';
import { CHeader, CHeaderNav } from '@coreui/react';
import HeaderDropdownContainer from './HeaderDropdownContainer';
import TimezoneComponent from '../components/TimezoneComponent';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isRoomsView = location.pathname.includes('/main/rooms');

  return (
    <CHeader>
      {isRoomsView && <TimezoneComponent />}
      <CHeaderNav className="px-3 ml-auto">
        <HeaderDropdownContainer />
      </CHeaderNav>
    </CHeader>
  );
};

export default Header;
