import React, { useState } from 'react';
import CIcon from '@coreui/icons-react';
import {
  ImageStatusContainer,
  ImageStatus,
  ImageRejectedActive,
  ImageStatusInactive,
  ImageApprovedActive,
} from '../styles/components/PanelImageComponent.module.css';
import ImagePopUp from './ImagePopUp';
import PropTypes from 'prop-types';
import { PROFILE_IMAGE_STATUS, IMAGE_REJECTION_REASONS } from '../utils/statusEnum';

const REJECTION_REASONS_LIMIT = 2;

// Note: This is used in user profile
const PanelImageComponent = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);

  const switchShowModal = () => {
    if (props.photo == null) return;
    !isModalOpen ? setSelectedReasons(checkReasons(props.photo)) : setSelectedReasons([]);
    setIsModalOpen(!isModalOpen);
  };

  const onReasonSelected = reasonId => {
    const checkedReasons = selectedReasons?.filter(reason => reason?.isChecked === true);
    const newSelectedReasons = [...selectedReasons];
    const indexReason = newSelectedReasons.findIndex(p => p.id === reasonId);
    if (
      newSelectedReasons[indexReason].isChecked ||
      checkedReasons?.length < REJECTION_REASONS_LIMIT
    ) {
      newSelectedReasons[indexReason].isChecked = !newSelectedReasons[indexReason].isChecked;
      setSelectedReasons(newSelectedReasons);
    }
  };

  const onRejectPhoto = () => {
    const reasonsIds = selectedReasons.reduce(
      (acc, reason) => (reason.isChecked === true ? [...acc, reason.id] : acc),
      []
    );
    props.onRejectPhoto(props.photo, reasonsIds);
    setIsModalOpen(!isModalOpen);
    setSelectedReasons([]);
  };

  const onApprovePhoto = () => {
    props.onApprovePhoto(props?.photo);
    setIsModalOpen(!isModalOpen);
    setSelectedReasons([]);
  };

  const getStatusClass = iconType => {
    if (iconType === PROFILE_IMAGE_STATUS.REJECTED)
      return props.photo.status === PROFILE_IMAGE_STATUS.REJECTED
        ? ImageRejectedActive
        : ImageStatusInactive;

    return props.photo.status === PROFILE_IMAGE_STATUS.APPROVED
      ? ImageApprovedActive
      : ImageStatusInactive;
  };

  const getImageComponent = () => {
    if (props.photo == null) return;
    return (
      <>
        <img style={{ width: '100%' }} src={props.photo?.url} alt="Not Found" />
        {props.photo.albumId === 1 && (
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              color: 'white',
              zIndex: 5,
              color: 'white',
              background: 'rgba(0,0,0,0.5)',
            }}
          >
            {props.photo.takenAt}
          </div>
        )}
        <div className={[ImageStatusContainer, 'row'].join(' ')}>
          <div
            className={[
              ImageStatus,
              'col-xs-6',
              getStatusClass(PROFILE_IMAGE_STATUS.REJECTED),
            ].join(' ')}
          >
            <CIcon name="cil-x" className="h-75 w-75" />
          </div>
          <div
            className={[
              ImageStatus,
              'col-xs-6',
              getStatusClass(PROFILE_IMAGE_STATUS.APPROVED),
            ].join(' ')}
          >
            <CIcon name="cil-check-alt" className="h-75 w-75" />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => switchShowModal()}>
        {getImageComponent()}
      </div>
      {(props?.photo && props?.selfieVerification) !== null && (
        <ImagePopUp
          isModalOpen={isModalOpen}
          onReasonSelected={onReasonSelected}
          onRejectPhoto={onRejectPhoto}
          onApprovePhoto={onApprovePhoto}
          photo={props.photo}
          selfieVerification={props?.selfieVerification}
          reasons={selectedReasons}
          switchShowModal={switchShowModal}
        />
      )}
    </>
  );
};

const checkReasons = photo => {
  return IMAGE_REJECTION_REASONS.map(reason => {
    return {
      ...reason,
      isChecked: (photo.rejectReasons || []).includes(reason.id),
    };
  });
};

PanelImageComponent.propTypes = {
  onRejectPhoto: PropTypes.func.isRequired,
  photo: PropTypes.object,
};

export default PanelImageComponent;
