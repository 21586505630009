import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CPopover } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTimezone } from '../store/actions/timezoneActions';

var moment = require('moment-timezone');

const TimezoneComponent = props => {
  const [timezone, setTimezoneLocal] = useState(false);
  const dispatch = useDispatch();
  const handleTimezoneSelect = selectedTimezone => {
    setTimezoneLocal(selectedTimezone);
    dispatch(setTimezone(selectedTimezone));
    localStorage.setItem('selected-tz', selectedTimezone);
  };

  useEffect(() => {
    let selectedTz = localStorage.getItem('selected-tz');
    if (selectedTz) {
      setTimezoneLocal(selectedTz);
      dispatch(setTimezone(selectedTz));
    } else {
      setTimezoneLocal(moment.tz.guess());
      dispatch(setTimezone(moment.tz.guess()));
    }
  }, []);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '2%' }}
    >
      <CPopover content={'Third option is browser based location'}>
        <b>Timezone:</b>
      </CPopover>
      <CDropdown>
        <CDropdownToggle caret>{timezone ? timezone : 'Select Timezone'}</CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              handleTimezoneSelect('Etc/UTC');
            }}
          >
            UTC (GMT-0)
          </CDropdownItem>
          <CDropdownItem
            onClick={() => {
              handleTimezoneSelect('America/New_York');
            }}
          >
            EST (GMT-5)
          </CDropdownItem>
          <CDropdownItem
            onClick={() => {
              handleTimezoneSelect(moment.tz.guess());
            }}
          >
            {moment.tz.guess()} (GMT
            {moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset())})
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </div>
  );
};

export default TimezoneComponent;
