import ApiService from '../../services/ApiService';
import { replace } from 'connected-react-router';
import {
  storeUserSession,
  getUserSession,
  removeUserSession,
  storeUserData,
  getUserData,
  removeUserData,
} from '../../services/localStorage.service';

export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';

export const toggleSideBar = () => {
  return { type: TOGGLE_SIDE_BAR };
};

export const checkAuthenticationOnLogin = () => {
  return dispatch => {
    const isActive = getUserSession();
    if (!isActive) {
      removeUserData();
      dispatch(replace('/'));
    } else {
      const user = JSON.parse(getUserData());
      dispatch(authenticate(true, user));
      dispatch(replace('/main'));
    }
  };
};

export const checkAuthentication = () => {
  return dispatch => {
    const isActive = getUserSession();
    if (!isActive) {
      removeUserData();
      dispatch(replace('/'));
    } else {
      const user = JSON.parse(getUserData());
      dispatch(authenticate(true, user));
    }
  };
};

export const authenticate = (isActive, user) => {
  return dispatch => {
    dispatch({ type: AUTHENTICATE, isActive: isActive, user: user });
  };
};

export const login = url => {
  return async dispatch => {
    const response = await ApiService.get(url, { withCredentials: true });

    const user = {
      id: response.data.user.id,
      displayName: response.data.user.displayName,
      email: response.data.user.email,
      profilePhoto: response.data.user.profilePhoto,
    };

    storeUserSession(true);
    storeUserData(user);
    dispatch(authenticate(true, user));
  };
};

export const logout = () => {
  return dispatch => {
    removeUserSession();
    removeUserData();
    dispatch({ type: LOGOUT });
    dispatch(replace('/'));
  };
};
