// import ApiService from "../../services/ApiService";
// import User from "../../models/user";

import Text from '../../models/text';

export const GET_PENDING_TEXT = 'GET_PENDING_TEXT';
export const BAN_PENDING_TEXT = 'BAN_PENDING_TEXT';
export const UNBAN_PENDING_TEXT = 'UNBAN_PENDING_TEXT';
export const REJECT_TEXT = 'REJECT_TEXT';
export const UNREJECT_TEXT = 'UNREJECT_TEXT';

export const rejectText = (text, reasons) => {
  return async dispatch => {
    dispatch({
      type: REJECT_TEXT,
      id: text.id,
      reasons: reasons,
    });
  };
};

export const unrejectText = text => {
  return async dispatch => {
    dispatch({
      type: UNREJECT_TEXT,
      id: text.id,
    });
  };
};

export const banUser = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    // const response = await ApiService.post(
    //   "ban",
    //   { userId: userId },
    //   { withCredentials: true }
    // );
    dispatch({ type: BAN_PENDING_TEXT, userId: userId });
  };
};

export const unbanUser = userId => {
  return async dispatch => {
    // eslint-disable-next-line
    // const response = await ApiService.delete("ban", {
    //   data: { userId: userId },
    //   withCredentials: true,
    // });
    dispatch({ type: UNBAN_PENDING_TEXT, userId: userId });
  };
};

export const getPendingText = () => {
  //   const limit = 10;
  return async (dispatch, getState) => {
    // const currentState = getState();
    // const page = currentState.flags.currentPage;

    // const response = await ApiService.get(
    //   `profiles/reportedprofiles?&limit=${limit}&page=${page}`,
    //   {
    //     withCredentials: true,
    //   }
    // );

    // const { profiles, more } = response.data;

    // const users = MapProfilesFromResponse(profiles);

    let dummyUsers = [
      {
        id: 1,
        userId: 'T9jWhsfmFEflFQFcmRzOdq0BVgi1',
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto: '',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 2,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 3,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 4,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 5,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 6,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 7,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 8,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 9,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
      {
        id: 10,
        userId: Makeid(28),
        name: 'manuel vergara',
        type: 'text',
        text: 'This is a text.',
        profilePhoto:
          'https://theritesofpassage.biz/wp-content/uploads/2016/03/headshot-round-jodi-kaye-2.png',
        warnings: Math.floor(Math.random() * 6) + 1,
        priorBans: 0,
        userStatus: 'Active',
        previousState: 'Active',
        comment:
          '{"yes_sex_toy":"0.000","yes_female_nudity":"0.000","yes_male_nudity":"0.000","animated_gun":"0.000","gun_in_hand":"0.000","knife_in_hand":"0.000","a_little_bloody":"0.000","other_blood":"0.000","very_bloody":"0.000","yes_pills":"0.000","yes_smoking":"0.031","yes_nazi":"0.000","yes_kkk":"0.000","yes_middle_finger":"0.001","yes_terrorist":"0.000"}',
      },
    ];

    const texts = dummyUsers.map(textData => {
      return new Text(textData);
    });

    dispatch({
      type: GET_PENDING_TEXT,
      pendingText: texts,
      moreUsersLeft: true,
      //   pendingText: users,
      //   moreUsersLeft: more,
    });
  };
};

// const MapProfilesFromResponse = (profiles) => {
//   return profiles.map((profile) => {
//     return new User(profile);
//   });
// };

const Makeid = length => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
